import React, {memo} from "react";

const RenderPasswordInputUI = ({classes, className, canSee, value, hasError, paddingTop = 7.5, ...rest}) => {
  return <div className={classes['Wrapper']}>
    <input type={canSee ? "text" : "password"}
           {...rest}
           className={`${classes['Input']} ${className} ${hasError ? classes['Input_error'] : ''}`}/>
  </div>
}

export default memo(RenderPasswordInputUI);
