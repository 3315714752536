import React from "react";

import {useSelector} from "react-redux";

import AppUI from "../ui/AppUI";

import {getAuthSelector} from "../../../redux/user/userSelectors";

import './App.css'
import 'react-virtualized/styles.css';

const AppContainer = () => {
    const isAuth = useSelector(getAuthSelector);

    const token = localStorage.getItem('token');

    return <AppUI isAuth={isAuth} token={token}/>;
}

export default AppContainer;
