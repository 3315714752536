import React from "react";

import SelectViewUI from "../ui/SelectViewUI";

import styles from "./SelectView.module.css";

const SelectViewContainer = (props) => {
  return <SelectViewUI {...props} classes={styles}/>
}

export default SelectViewContainer;
