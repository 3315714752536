import React, {memo} from "react";

import InputWithCloseIconContainer
  from "../../../../../../common/InputWithCloseIcon/functional/InputWithCloseIconContainer";
import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import DiagramSliderContainer from "../../../../../../common/DiagramSlider/functional/DiagramSliderContainer";

const PaymentOfInsuranceUI = ({
                                classes, handleSubmit, balanceDividedWdxPrice,
                                insurancePrice, priceInWdxPrice, disabled,
                                toFixed, price, handlePriceChange
                              }) => {
  return <>
    <div className={classes['PaymentOfInsurance']}>
      <span className={classes['PaymentOfInsurance-Title']}>Оплата стоимости страховки</span>
      <span className={classes['PaymentOfInsurance-Description']}>Остался всего один шаг!</span>
      <form className={classes['Form']} onSubmit={handleSubmit}>
        <label htmlFor="sum" className={classes['Form-Label']}>
          <span className={classes['Form-Label-Text']}>Введите сумму в рублях</span>
          <InputWithCloseIconContainer
            value={price}
            onChange={handlePriceChange}
            inputClassName={classes['Form-Label-Input']}/>
        </label>
        <label htmlFor="sumInWI" className={classes['Form-Label']}>
          <span className={classes['Form-Label-Text']}>Ваша сумма в WI</span>
          <InputWithCloseIconContainer
            value={`= ${toFixed(priceInWdxPrice)}`}
            readOnly inputClassName={classes['Form-Label-Input']}/>
        </label>
        <ButtonContainer disabled={disabled} text="Оплатить" className={classes['Form-Button_submit']}/>
      </form>
    </div>
    <DiagramSliderContainer needCollect={insurancePrice} collected={balanceDividedWdxPrice}/>
  </>;
}

export default memo(PaymentOfInsuranceUI);
