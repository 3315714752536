import React from "react";

import ButtonContainer from "../../../../../../../../common/Button/functional/ButtonContainer";
import RenderErrorMessageContainer
  from "../../../../../../../../common/RenderErrorMessage/functional/RenderErrorMessageContainer";
import PasswordInputWithEyeIconContainer
  from "../../../../../../../../common/PasswordInputWithEyeIcon/functional/PasswordInputWithEyeIconContainer";

const ChangePasswordFormUI = ({classes, handleOnChange, handleSubmit, errors}) => {
  return <div className={classes['ChangePasswordForm']}>
    <span className={classes['ChangePasswordForm-Title']}>Сменить пароль</span>
    <form className={classes['ChangePasswordForm-Form']} onSubmit={handleSubmit}>
      <label className={classes['Form-Label']}>
        <span className={classes['Form-Label-Text']}>Старый пароль</span>
        <PasswordInputWithEyeIconContainer className={classes['Form-Label-Input']}
                                           eyeClassName={classes['Form-Label-Input-EyeIcon']}
                                           onChange={handleOnChange}
                                           name="password"/>
      </label>
      <label className={classes['Form-Label']}>
        <span className={classes['Form-Label-Text']}>Новый пароль</span>
        <PasswordInputWithEyeIconContainer className={classes['Form-Label-Input']}
                                           eyeClassName={classes['Form-Label-Input-EyeIcon']}
                                           onChange={handleOnChange}
                                           hasError={errors.new_password}
                                           name="new_password"/>
      </label>
      {errors.new_password && <RenderErrorMessageContainer message={errors.new_password}/>}
      <label className={classes['Form-Label']}>
        <span className={classes['Form-Label-Text']}>Повторите пароль</span>
        <PasswordInputWithEyeIconContainer className={classes['Form-Label-Input']}
                                           eyeClassName={classes['Form-Label-Input-EyeIcon']}
                                           onChange={handleOnChange}
                                           hasError={errors.repeat_password}
                                           name="repeat_password"/>
      </label>
      {errors.repeat_password && <RenderErrorMessageContainer message={errors.repeat_password}/>}
      {errors.global && <RenderErrorMessageContainer message={errors.global}/>}
      <ButtonContainer text="Сменить" className={classes['Form-Submit']}/>
    </form>
  </div>
}

export default ChangePasswordFormUI;
