import React, {useCallback, useRef, useState} from "react";
 
import {useDispatch, useSelector} from "react-redux";
 
import WithdrawUI from "../ui/WithdrawUI";
 
import { withdrawStaking } from "../../../../../../../redux/user/userActions";
import { getStakingWithdrawViewSelector } from "../../../../../../../redux/user/userSelectors";
 
import styles from "./Withdraw.module.css";
 
const WithdrawContainer = () => {
  const [open, setOpen] = useState(false);
 
  const staking = useSelector(getStakingWithdrawViewSelector);
 
  const inputRef = useRef();
 
  const [startSubmission, setStartSubmission] = useState(false);
 
  const dispatch = useDispatch();
  
  const handleMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open])
  // const handleClose = useCallback(() => {
  //   setOpen(false);
  // }, []);
  // const handleSubmit = useCallback((e) => {
  //   e.preventDefault();
  //   dispatch(withdrawStaking(inputRef.current.textContent));
  // }, [inputRef, dispatch]);
 
  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);

  const handleSent= useCallback((e) => {
    e.preventDefault();
    dispatch(withdrawStaking(inputRef.current.textContent, handleClose));
  }, [inputRef, handleClose, dispatch]);
 
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if(inputRef.current.textContent !== ''){
      return setStartSubmission(true);
    }
  }, []);
 
  return <WithdrawUI classes={styles}
                     open={open}
                     inputRef={inputRef}
                     staking={staking}
                     handleMouseEnter={handleMouseEnter}
                     handleClose={handleClose}
                     handleSubmit={handleSubmit}
                     handleClick={handleClick}
                     handleSent={handleSent}
                     startSubmission={startSubmission}
  />;
}
 
export default WithdrawContainer;