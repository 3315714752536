import React, {useCallback, useState} from "react";

import InputWithCloseIconUI from "../ui/InputWithCloseIconUI";

import styles from "./InputWithCloseIcon.module.css";

const InputWithCloseIconContainer = (props) => {
  const [focused, setFocused] = useState(false);
  const { onChange } = props;

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleCloseIconClick = useCallback(() => {
    onChange({
      target: {
        value: '',
      }
    });
  }, [onChange]);

  return <InputWithCloseIconUI focused={focused}
                               handleBlur={handleBlur}
                               handleFocus={handleFocus}
                               handleCloseIconClick={handleCloseIconClick}
                               classes={styles}
                               {...props}
  />;
}

export default InputWithCloseIconContainer;
