import React, {useMemo, useState} from "react";

import WalletUI from "../ui/WalletUI";
import AddBalanceContainer from "./AddBalance/functional/AddBalanceContainer";
import WithdrawBalanceContainer from "./WithdrawBalance/functional/WithdrawBalanceContainer";
// import TransferBalanceContainer from "./TransferBalance/functional/TransferBalanceContainer";

import styles from "./Wallet.module.css";


const WalletContainer = () => {

  const [actionType, setActionType] = useState('ADD_BALANCE');

  const renderComponent = useMemo(() => {
    switch (actionType) {
      case "ADD_BALANCE":
        return AddBalanceContainer;
      case "WITHDRAW_BALANCE":
        return WithdrawBalanceContainer;
      // case "TRANSFER_BALANCE":
      //   return TransferBalanceContainer;
      default:
        return null;
    }
  }, [actionType])

  return <WalletUI classes={styles}
                   RenderComponent={renderComponent}
                   actionType={actionType}
                   setActionType={setActionType}/>
}

export default WalletContainer;
