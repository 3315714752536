import React, {useCallback} from "react";

import ActionTypeSelectUI from "../ui/ActionTypeSelectUI";

import styles from "./ActionTypeSelect.module.css";

const ActionTypeSelectContainer = (props) => {
  const {setActionType} = props;

  const handleAddBalance = useCallback(() => {
    setActionType('ADD_BALANCE');
  }, [setActionType]);

  const handleWithdrawBalance = useCallback(() => {
    setActionType('WITHDRAW_BALANCE');
  }, [setActionType]);

  // const handleTransferBalance = useCallback(() => {
  //   setActionType('TRANSFER_BALANCE');
  // }, [setActionType]);

  return <ActionTypeSelectUI classes={styles}
                             handleAddBalance={handleAddBalance}
                             handleWithdrawBalance={handleWithdrawBalance}
                             // handleTransferBalance={handleTransferBalance}
                             {...props}/>
}

export default ActionTypeSelectContainer
