import React, {memo} from "react";

const BalanceInformationItemUI = ({item, classes, backgroundStyle}) => {
  return <div className={classes.BalanceInformationItem} style={backgroundStyle}>
    { item.additionalInfo ?  <div className={classes["BalanceInformationItem-Wrapper"]}>
        <span className={classes['BalanceInformationItem-SectionName']}>{item.sectionName}</span>
        <span className={classes['BalanceInformationItem-Additional']}>{item.additionalInfo}</span>
      </div> :
      <span className={classes['BalanceInformationItem-SectionName']}>{item.sectionName}</span>
    }
    <span className={classes['BalanceInformationItem-Value']} dangerouslySetInnerHTML={{__html: item.value}}/>
  </div>
}

export default memo(BalanceInformationItemUI);
