import React from "react";

import FooterUI from "../ui/FooterUI";

import styles from "./Footer.module.css";

const FooterContainer = () => {
  return <FooterUI classes={styles}/>;
}

export default FooterContainer;
