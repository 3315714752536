import React from "react";

import TwoFAUI from "../ui/TwoFAUI";

const TwoFAContainer = ({handleEnable, activated}) => {

  return <TwoFAUI handleTwoFAEnable={handleEnable} activated={activated}/>;
}

export default TwoFAContainer;
