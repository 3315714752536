import API from "../../API";
import globalSettingsConstants from "./globalSettingsConstants";
import {handle401} from "../helpers/handle401";

export const setSetting = (setting) => ({ type: globalSettingsConstants.GLOBAL_SETTINGS_SET_SETTING, payload: setting });

export const getPrepaymentAmount = () => (dispatch, getState, getHeaders) => {
  API.get(`settings`, getHeaders())
    .then(({data}) => {
      data.forEach(setting => {
        dispatch(setSetting({ [setting.key]: setting.value }));
      });
    })
    .catch(err => {
      dispatch(handle401(err));
    });
}
