import {modalConstants} from "./modalConstants";

const initialState = {
  open: false,
  type: 'success',
  content: {
    title: null,
    description: null,
    additionalComponent: null,
  }
}

const modalReducer = (state = initialState, action) => {
  switch(action.type) {
    case modalConstants.OPEN_MODAL:
      return {
        open: true,
        ...action.payload.modal
      };
    case modalConstants.CLOSE_MODAL:
      return {
        open: false,
        type: 'success',
        content: {
          title: null,
          description: null,
        }
      }
    default:
      return state;
  }
}

export default modalReducer;
