import React, { memo } from 'react';



const BalanceInformationUI = ({ classes, balance, wdxPrice, usdtPrice }) => {
  return <div className={classes.Balance}>
    <span className={classes['Balance-Item']}>Баланс: <b className={classes['Belance-Item_bold']}>{balance} WI</b></span>
    <span className={classes['Balance-Item_black']}>1 WI = {wdxPrice} &#8381;</span>
    <span className={classes['Balance-Item_black']}>1 WI = {usdtPrice} USDT</span>
  </div>
}

export default memo(BalanceInformationUI);
