import React from "react";

const StakingIconUI = (props) => {
  return <div {...props}>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 20.4316C15.9669 20.4316 15.5332 19.9979 15.5332 19.4648C15.5332 19.0754 15.7649 18.7257 16.1236 18.5738C16.5565 18.3905 17.0746 18.5469 17.5453 19.003C17.9288 19.3745 18.5409 19.3648 18.9124 18.9814C19.2839 18.5979 19.2742 17.9858 18.8908 17.6143C18.4543 17.1915 17.9691 16.8926 17.4668 16.7239V15.5977C17.4668 15.0637 17.034 14.6309 16.5 14.6309C15.9661 14.6309 15.5332 15.0637 15.5332 15.5977V16.7302C15.4784 16.7497 15.4237 16.7703 15.3695 16.7933C14.2944 17.2486 13.5996 18.2973 13.5996 19.4648C13.5996 21.0641 14.9008 22.3652 16.5 22.3652C17.0331 22.3652 17.4668 22.7989 17.4668 23.332C17.4668 23.7372 17.2113 24.1025 16.8309 24.241C16.3343 24.4218 15.7562 24.1766 15.245 23.5678C14.9016 23.1589 14.2918 23.1059 13.8829 23.4493C13.474 23.7927 13.421 24.4025 13.7644 24.8114C14.287 25.4337 14.8954 25.8604 15.5333 26.0752V27.1992C15.5333 27.7331 15.9662 28.166 16.5001 28.166C17.034 28.166 17.4669 27.7331 17.4669 27.1992V26.0666C17.4755 26.0636 17.4842 26.0609 17.4927 26.0578C18.6337 25.6422 19.4004 24.5468 19.4004 23.332C19.4004 21.7328 18.0993 20.4316 16.5 20.4316Z" fill="#4E5566"/>
      <path d="M25.4538 14.5186C24.3047 13.105 22.9911 11.9787 21.5492 11.1688C21.7853 10.8894 21.9717 10.5618 22.092 10.1967C22.5642 8.75879 21.8745 7.05079 20.1141 6.49436L22.5819 2.19115C22.8933 1.64807 22.6136 0.956355 22.0132 0.781816C20.2289 0.263033 18.374 0 16.5 0C14.626 0 12.7711 0.263033 10.9868 0.781816C10.3857 0.956549 10.1071 1.64871 10.4181 2.19115L12.8859 6.49443C11.1325 7.04801 10.4341 8.75351 10.9082 10.1975C11.0282 10.5616 11.2145 10.889 11.4513 11.1686C10.0092 11.9785 8.6955 13.1049 7.5463 14.5186C5.24913 17.3447 3.93164 20.9094 3.93164 24.2988C3.93164 31.3301 9.54209 33 16.5 33C23.4628 33 29.0684 31.3272 29.0684 24.2988C29.0684 20.9094 27.7509 17.3447 25.4538 14.5186ZM12.7292 2.33436C13.9627 2.06811 15.2263 1.93359 16.5 1.93359C17.7737 1.93359 19.0373 2.06811 20.2708 2.33436L18.1417 6.04699C17.0512 5.89475 15.9491 5.89482 14.8584 6.04706L12.7292 2.33436ZM13.3609 8.3722C15.0247 7.82641 16.7655 7.72297 18.4578 8.06128C18.4598 8.06167 18.4619 8.06205 18.4639 8.06244C18.8583 8.14159 19.2502 8.24459 19.6378 8.37175C20.1444 8.53875 20.4213 9.08679 20.2552 9.59256C20.1345 9.95885 19.8158 10.2111 19.4409 10.252C18.4769 9.95014 17.4937 9.79688 16.5 9.79688C15.5083 9.79688 14.527 9.9495 13.5649 10.2501C13.1842 10.2109 12.8647 9.95685 12.745 9.59346C12.5787 9.08679 12.8556 8.53868 13.3609 8.3722ZM16.5 31.0793C8.94635 31.0793 5.86523 29.1057 5.86523 24.2988C5.86523 21.3893 7.05459 18.1891 9.04664 15.7383C11.1475 13.1538 13.7945 11.7305 16.5 11.7305C19.4781 11.7305 22.083 13.4373 23.9534 15.7383C25.9454 18.1891 27.1348 21.3893 27.1348 24.2988C27.1348 29.1057 24.0536 31.0793 16.5 31.0793Z" fill="#4E5566"/>
    </svg>
  </div>
}

export default StakingIconUI;
