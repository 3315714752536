import React, {memo} from "react";

import NegativeButtonUI from "../ui/NegativeButtonUI";

import styles from "./NegativeButton.module.css";

const NegativeButtonContainer = (props) => {
  return <NegativeButtonUI {...props} classes={styles}/>;
}

export default memo(NegativeButtonContainer);
