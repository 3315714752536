import React, {useCallback} from "react";

import {useDispatch} from "react-redux";

import AllDoneUI from "../ui/AllDoneUI";

import styles from "./AllDone.module.css";

const AllDoneContainer = () => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch({type: 'ALL_DONE'})
  }, [dispatch]);

  return <AllDoneUI classes={styles} handleSubmit={handleSubmit}/>;
}

export default AllDoneContainer;
