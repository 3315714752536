import { applyMiddleware, combineReducers,  compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import userReducer from "./user/userReducer";
import referralReducer from "./referral/referralReducer";

import globalSettingsReducer from "./global-settings/globalSettingsReducer";

import formsReducer from "./forms/formsReducer";
import globalReducer from "./global/globalReducer";

const reducers = combineReducers({
  user: userReducer,
  referral: referralReducer,

  forms: formsReducer,
  global: globalReducer,

  globalSettings: globalSettingsReducer,
});

export const rootReducer = (state, action) => {
  if(action.type === 'LOGOUT') {
    state = undefined;
  }

  return reducers(state, action);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const getHeaders = () => {
  return {
    headers: {
      Authorization: 'JWT ' + localStorage.getItem('token'),
    }
  }
}

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunkMiddleware.withExtraArgument(getHeaders)),
));

export default store;
