import React, {useCallback, useMemo, useState} from "react";


import {useDispatch, useSelector} from "react-redux";

import PaymentOfInsuranceUI from "../ui/PaymentOfInsuranceUI";

import {
  getCarOrderBalanceSelector,
  getInsurancePriceSelector,
  getWdxPriceSelector
} from "../../../../../../../redux/user/userSelectors";
import {createCarOrderPayment} from "../../../../../../../redux/user/userActions";
import withToFixed from "../../../../../../../helpers/hoc/withToFixed";
import withWdxCalculation from "../../../../../../../helpers/hoc/withWdxCalculation";
import useWdxBalance from "../../../../../../common/hooks/useWdxBalance";

import styles from "./PaymentOfInsurance.module.css";

const PaymentOfInsuranceContainer = ({toFixed, currencyInWdx}) => {
  const [price, setPrice] = useState('');
  const [disabled, setDisabled] = useState(false);

  const wdxPrice = useSelector(getWdxPriceSelector);
  const balance = useSelector(getCarOrderBalanceSelector);
  const insurancePrice = useSelector(getInsurancePriceSelector);

  const balanceInWdx = useWdxBalance();

  const dispatch = useDispatch();

  const sumInWdxPrice = useMemo(() => {
    const sum = insurancePrice * wdxPrice;
    if (isNaN(sum)) {
      return 0;
    }
    return sum;
  }, [wdxPrice, insurancePrice]);

  const priceInWdxPrice = useMemo(() => currencyInWdx(price, wdxPrice), [price, wdxPrice, currencyInWdx])

  const handleSubmit = useCallback((e) => {
    setDisabled(true);
    e.preventDefault();
    dispatch(createCarOrderPayment(priceInWdxPrice))
      .then(() => setDisabled(false), () => setDisabled(false));
  }, [priceInWdxPrice, dispatch]);

  const handlePriceChange = useCallback((e) => {
    setPrice(e.target.value);
  }, []);


  if (insurancePrice === null || wdxPrice == null) {
    return null;
  }

  return <PaymentOfInsuranceUI
    handleSubmit={handleSubmit}
    balanceDividedWdxPrice={balanceInWdx}
    handlePriceChange={handlePriceChange}
    price={price}
    priceInWdxPrice={priceInWdxPrice}
    sumInWdxPrice={sumInWdxPrice}
    balance={balance}
    insurancePrice={insurancePrice}
    toFixed={toFixed}
    disabled={disabled}
    classes={styles}/>;
}

export default withWdxCalculation(withToFixed(PaymentOfInsuranceContainer)(6));
