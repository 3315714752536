import React, {memo} from "react";
import LeftArrowIconContainer from "../../LeftArrowIcon/functional/LeftArrowIconContainer";
import RightArrowIconContainer from "../../RightArrowIcon/functional/RightArrowIconContainer";

const PaginationUI = ({classes, currentPage, className = '', nextArrowFillColor,
                        getListByPage, style, previousArrowFillColor}) => {
  return <div className={`${classes.Pagination} ${className}`}>
    <LeftArrowIconContainer
      className={classes['Arrow-Icon']}
      style={style}
      fillColor={previousArrowFillColor}
      onClick={() => getListByPage(currentPage - 1, 'DOWN')}/>
    <span className={classes['Pagination-Page']}>{currentPage}</span>
    <RightArrowIconContainer
      className={classes['Arrow-Icon']}
      style={style}
      fillColor={nextArrowFillColor}
      onClick={() => getListByPage(currentPage + 1, 'UP')}
    />
  </div>;
}

export default memo(PaginationUI);
