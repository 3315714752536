import React from "react";

import RenderInputUI from "../ui/RenderInputUI";

import styles from "./RenderInput.module.css";

const RenderInputContainer = (props) => {
  return <RenderInputUI {...props} classes={styles}/>;
}

export default RenderInputContainer;
