import React, {memo} from "react";
 
import RenderTextsSpaceBetweenContainer
  from "../../RenderTextsSpaceBetween/functional/RenderTextsSpaceBetweenContainer";
import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import CurrentRateInfoContainer from "../functional/CurrentRateInfo/functional/CurrentRateInfoContainer";
import QuestionMarkContainer from "../../QuestionMark/functional/QuestionMarkContainer";
import RenderInputLikeContainer from "../../../../../../common/RenderInputLike/functional/RenderInputLikeContainer";
import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";
import {modalDescription} from '../../../../../../InfoModal/constans/modalDescription';
 
const StakingInfoUI = ({
                         classes, open, handleMouseEnter, handleSubmit,
                         handleClose, inputRef, staking, handleClick,
                         startSubmission, handleSent
                       }) => {
  return <div className={classes.StakingInfo}>
    <span className={classes['StakingInfo-Title']}>Стейкинг</span>
    <RenderTextsSpaceBetweenContainer leftValue="Объем Стейкинга"
                                      rightValue={`${staking.volume.wdx} WI`}
                                      className={classes['StakingInfo-Texts']}
                                      additionalComponent={<span>&nbsp;(${staking.volume.usd})</span>}
    />
    <RenderTextsSpaceBetweenContainer leftValue="срок стейкинга (дней)"
                                      rightValue={staking['duration']}/>
    <div className={classes['Wrapper']}>
      <RenderTextsSpaceBetweenContainer leftValue="Текущая ставка (%)"
                                        rightValue={staking['current_staking_rate']}
                                        additionalComponent={<QuestionMarkContainer
                                          onClick={handleClick}
                                          onMouseEnter={handleMouseEnter}/>}
      />
      {open && <CurrentRateInfoContainer handleClose={handleClick}/>}
    </div>
    <RenderTextsSpaceBetweenContainer leftValue="Прибыль за все время"
                                      rightValue={`${staking['total_profit'].wdx}  WI`}
                                      additionalComponent={<span>&nbsp;(${staking['total_profit'].usd})</span>}
    />
    <RenderTextsSpaceBetweenContainer
      leftValue="Следующее начисление"
      rightValue={staking['time_to_next_enrollment']}
      className={classes['StakingInfo-Texts-Last']}
    />
    <form className={classes['Form']} 
    // onSubmit={handleSubmit}
    >
      <RenderInputLikeContainer inputRef={inputRef}/>
      <ButtonContainer onClick={handleSubmit} text="Отправить на стейкинг" className={classes['Form-Submit']}/>
    </form>
    {startSubmission && <InfoModalContainer handleClose={handleClose}
                                            selected={inputRef.current.innerText}
                                            title='Стейкинг'
                                            btnText='Отправить'
                                            descFirst={modalDescription.stackingSend}
                                            currency='WI'
                                            handleSubmit={handleSent}
                                            />}
  </div>
}
 
export default memo(StakingInfoUI);
