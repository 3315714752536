import React, { memo } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import StaticCryptoContainer from "../functional/StaticCrypto/functional/StaticCryptoContainer";
import MainRouter from "../functional/MainRouter/MainRouter";

const ContentUI = ({ classes }) => {
  return <div className={classes.Content}>
    <StaticCryptoContainer />
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/wallet" />} />
      <Route path="/" component={MainRouter} />
    </Switch>
  </div>
}

export default memo(ContentUI);
