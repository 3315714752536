import React from "react";

import StatusInfoUI from "../ui/StatusInfoUI";

import styles from "./StatusInfo.module.css";

const StatusInfoContainer = (props) => {
  return <StatusInfoUI classes={styles} {...props}/>;
}

export default StatusInfoContainer;
