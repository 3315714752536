import React, {useCallback, useState} from "react";
import WithdrawProfitUI from "../ui/WithdrawProfitUI";

const WithdrawProfit = ({classes, refProfit,percent, handleSent, title}) => {

  const [amount, setAmount] = useState('');

  const [startSubmission, setStartSubmission] = useState(false);
  const [startValueSubmission, setStartValueSubmission] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const handleAmountChange = useCallback((e) => {
    e.target.value === '' ? setDisabled(true) : setDisabled(false)
    setAmount(e.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setStartSubmission(true);
    setStartValueSubmission(false);
  }, []);

  const handleValueInput = useCallback((e) => {
    e.preventDefault();
    setStartValueSubmission(true);
  }, [])

  const handleValueInputClose = useCallback((e) => {
    setStartValueSubmission(false);
  }, [])

  const send = useCallback((e) => {
    handleSent(e, amount, handleClose)
  }, [handleSent, amount, handleClose])


  return <WithdrawProfitUI
         classes={classes}
         refProfit={refProfit}
         handleValueInput={handleValueInput}
         percent={percent}
         startValueSubmission={startValueSubmission}
         handleValueInputClose={handleValueInputClose}
         amount={amount}
         disabled={disabled}
         handleAmountChange={handleAmountChange}
         handleSubmit={handleSubmit}
         startSubmission={startSubmission}
         handleClose={handleClose}
         handleSent={handleSent}
         send={send}
         title={title}
        />
}

export default WithdrawProfit