import React, {memo} from "react";

import DiagramSliderContainer from "../../../../../../common/DiagramSlider/functional/DiagramSliderContainer";
import InputWithCloseIconContainer
  from "../../../../../../common/InputWithCloseIcon/functional/InputWithCloseIconContainer";
import RenderInputContainer from "../../../../../../common/RenderInput/functional/RenderInputContainer";
import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";

const AdvancePaymentUI = ({classes, price, handlePriceChange, balanceInWdx, priceInWdxPrice, handleSubmit, maxPrePaymentAmount}) => {
  return <div className={classes['Advance-Payment']}>
    <div className={classes['Advance-Payment-Wrapper']}>
      <span className={classes['Advance-Payment-Title']}>Оплата сервисного сбора</span>
      <form className={classes['Form']} onSubmit={handleSubmit}>
        <label className={classes['Form-Label']}>
          <span className={classes['Form-Label-Text']}>Введите суму</span>
          <InputWithCloseIconContainer className={classes['Form-Input']} onChange={handlePriceChange} value={price}/>
        </label>
        <label className={classes['Form-Label']}>
          <span className={classes['Form-Label-Text']}>Ваша сумма в WI</span>
          <RenderInputContainer className={classes['Form-Input']}
                                readOnly
                                value={`= ${priceInWdxPrice}`}
          />
        </label>
        <ButtonContainer text="Оплатить" className={classes['Form-Submit']}/>
      </form>
    </div>
    <DiagramSliderContainer needCollect={maxPrePaymentAmount} collected={balanceInWdx}/>
  </div>
}

export default memo(AdvancePaymentUI);
