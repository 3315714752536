import React from "react";

import {batch} from "react-redux";

import userConstants from "./userConstants";

import API from "../../API";
import {openModal} from "../modal/modalActions";

import TransactionCheckContainer
  from "../../components/Content/functional/CarPayment/functional/PaymentOfInsurance/functional/TransactionCheck/functional/TransactionCheckContainer";
import {handle401} from "../helpers/handle401";
import {validatePassword, validateRepeatPassword} from "../helpers/validators";

export const isAuthorizationProblem = err => {
  return err.response.status === 401;
}
export const setUser = (user) => ({type: userConstants.SET_USER, payload: {user}});

export const setCarOrder = (carOrder) => ({type: userConstants.USER_SET_CAR_ORDER, payload: {carOrder}});

export const setWdxPrice = (wdxPrice) => ({type: userConstants.SET_WI_PRICE, payload: {wdxPrice}});

export const setEthPrice = (ethPrice) => ({type: userConstants.SET_ETH_PRICE, payload: {ethPrice}});

export const setIcoPriceEth = (icoPriceEth) => ({type: userConstants.SET_ICO_PRICE_ETH, payload: {icoPriceEth}});

export const setWdxPriceUSDT = (usdtPrice) =>
  ({type: userConstants.SET_WI_PRICE_USDT, payload: {usdtPrice}});

export const setAuth = (auth) => ({type: userConstants.USER_SET_AUTH, payload: {auth}});

export const setInLastPage = (inLastPage) =>
  ({type: userConstants.USER_SET_IN_LAST_PAGE, payload: {inLastPage}});

export const handleTransactionSuccess = (txHash, link = 'https://etherscan.io/tx/') => (dispatch) => {
  dispatch(openModal({
    type: 'success',
    content: {
      title: `Cредства отправлены,
          идет проверка транзакций`,
      additionalComponent: <TransactionCheckContainer link={link} txHash={txHash}/>
    },
  }));
}

export const handleTransactionError = (errorMessage) => (dispatch) => {
  dispatch(openModal({
    type: 'error',
    content: {
      title: `Ошибка транзакции`,
      description: errorMessage,
    },
  }))
}

export const disableMovingNextPage = (result) => (dispatch) => {
  if (result.length === 0) {
    dispatch(setInLastPage(true));
  } else {
    dispatch(setInLastPage(false));
  }
}

export const getUser = () => (dispatch, getState, getHeaders) => {
  return API.get(`user/current`, getHeaders())
    .then(({data}) => {
      dispatch(setUser(data));
      dispatch(getWdxPrice());
      dispatch(setAuth(true));
      dispatch(getTokenExchange());
    })
    .catch(err => {
      dispatch(handle401(err));
    })
}

export const getOrRedirectUser = (history) => (dispatch, getState) => {
  const user = getState().user;
  if (!user && !localStorage.getItem('token')) {
    dispatch(setAuth(false));
    history.push('/login');
  } else {
    // //dispatch(getUser())
    //   .catch(() => {
    //     dispatch(setAuth(false));
    //     history.push('/login');
    //   })
  }
}

export const getWdxPrice = () => (dispatch, getState, getHeaders) => {
  API.get(`wdx_price`, getHeaders())
    .then(({data}) => {
      dispatch(setWdxPrice(data.price));
      dispatch(setEthPrice(data.price_eth));
      dispatch(setIcoPriceEth(data.wdx_ico_price_eth));
      dispatch(setWdxPriceUSDT(data.wdx_price_usdt));
    })
    .catch(err => {
      dispatch(handle401(err));
    });

}

export const createCar = (price) => (dispatch, getState, getHeaders) => {
  return API.post(`car/order`, {price: +price}, getHeaders())
    .then(() => {
      API.get(`car/order`, getHeaders())
        .then(({data}) => {
          if (data.status === 'new') {
            dispatch(openModal({
              type: 'success',
              content: {
                title: 'Cумма проверяется менеджером',
                style: {
                  width: '24.167vw',
                }
              }
            }))
          }
        });
    })
    .catch(err => {
      if (isAuthorizationProblem(err)) {
        dispatch(handle401(err));
      } else {
        dispatch(handleTransactionError(err.response.data));
      }
    });
}

export const getCarOrder = (setCurrentStep) => (dispatch, getState, getHeaders) => {
  API.get(`car/order`, getHeaders())
    .then(({data}) => {
        if (data.status === 'prepayment') {
          setCurrentStep(1);
        } else if (data.status === 'new') {
          setCurrentStep(2);
        } else if (data.status === 'approved') {
          setCurrentStep(3);
        } else if (data.status === 'need_insurance') {
          setCurrentStep(4);
        } else if (data.status === 'done') {
          setCurrentStep(5);
        }

        dispatch(setCarOrder({
          id: data.id,
          balance: data.balance,
          status: data.status,
          price: data.price,
          insurance_price: data.insurance_price,
          prepayment_amount: data.prepayment_amount,
        }));
      }
    )
    .catch((err) => {
      if (err.response.status === 404) {
        setCurrentStep(1);
      }
      dispatch(handle401(err));
    })
}

export const createCarOrderPayment = (wdxPrice) => (dispatch, getState, getHeaders) => {
  const data = {
    amount: +wdxPrice,
  }

  return API.post(`car/order/payment`, data, getHeaders())
    .then(({data}) => {
      dispatch(handleTransactionSuccess(data.tx_hash));
    }).catch(err => {
      if (isAuthorizationProblem(err)) {
        dispatch(handle401(err));
      } else {
        dispatch(handleTransactionError(err.response.data));
      }
    })
}

export const withdrawBalance = (formData) => (dispatch, getState, getHeaders) => {
  const data = {
    amount: formData.sum,
    to_address: formData.wallet,
    currency: formData.currency,
  }
  formData.handleClose();
  return API.post(`withdraw`, data, getHeaders())
    .then(({data}) => {
      dispatch(handleTransactionSuccess(data.tx_hash))
    })
    .catch(err => {
      if (isAuthorizationProblem(err)) {
        dispatch(handle401(err));
      } else {
        dispatch(handleTransactionError(err.response.data));
      }
    });
}

export const getOperationList = () => (dispatch, getState, getHeaders) => {
  const {operationList} = getState().user;
  const {limit, pageNumber} = operationList;
  API.get(`user/transaction?page=${pageNumber}&limit=${limit}`, getHeaders())
    .then(({data}) => {
      const {result} = data;
      if (!operationList.list[data.extra.page]) {
        dispatch(addOperationList(result, data.extra.page));
      }
      batch(() => {
        dispatch(setOperationView(result));
        dispatch(disableMovingNextPage(result));
      });
    })
    .catch(err => {
      dispatch(handle401(err));
    });
}

export const setOperationListPageNumber = (page) =>
  ({type: userConstants.USER_SET_OPERATION_LIST_PAGE_NUMBER, payload: {page}})

export const getOperationListByPage = (page) => (dispatch, getState, getHeaders) => {
  const operationList = getState().user.operationList;

  if (operationList.list[page]) {
    dispatch(setOperationListPageNumber(page));
  }

  if (operationList.list[page] !== undefined) {
    dispatch(setOperationView(operationList.list[page]));
    if (operationList.pageNumber >= page && !operationList.inLastPage)
      dispatch(disableMovingNextPage(false));
    else
      dispatch(disableMovingNextPage(true));
    return Promise.resolve();
  }

  if (operationList.pageNumber < page && operationList.inLastPage) {
    return Promise.resolve();
  }

  return API.get(`user/transaction?page=${page}&limit=${operationList.limit}`, getHeaders())
    .then(({data}) => {
      if (!data.result.length && page > 1) {
        dispatch(disableMovingNextPage(data.result))
        return null;
      } else {
        batch(() => {
          dispatch(setOperationListPageNumber(page));
          dispatch(addOperationList(data.result, data.extra.page));
          dispatch(setOperationView(data.result));
        })
      }
    }).catch(err => {
      dispatch(handle401(err));
    });
}

export const addOperationList = (operationList, pageNumber) =>
  ({type: userConstants.USER_ADD_OPERATION_LIST, payload: {operationList, pageNumber}});

export const setOperationView = (operationList) =>
  ({type: userConstants.USER_VIEW_OPERATION_LIST, payload: {operationList}});

export const logoutAction = () => ({type: userConstants.USER_LOGOUT});

export const logoutUser = () => (dispatch) => {
  dispatch(logoutAction());
}

export const resetPassword = (email) => (dispatch) => {

  let generatedEmail = '';

  for (let i = 0; i < email.length; i++) {
    if (email[i] === '+') {
      generatedEmail += '%2B';
    } else {
      generatedEmail += email[i];
    }
  }

  return API.get(`user/recover?email=${generatedEmail}`,)
    .then(() => 'success')
    .catch(console.log)
}

export const recoverPassword = (formData) => (dispatch) => {
  return API.post(`user/recover`, formData)
    .catch((err) => {
      return err.response.data;
    });
}

export const confirmEmail = (token) => (dispatch) => {
  return API.get(`user/confirm/${token}`)
    .then(() => {
      return 'success';
    }).catch(() => {
      return 'error';
    });
}

export const set2FA = (fa) => ({type: userConstants.USER_SET_2FA, payload: {fa}});

export const get2FA = () => (dispatch, getState, getHeaders) => {
  return API.get(`/user/2fa`, getHeaders())
    .then(({data}) => {
      dispatch(set2FA(data));
    }).catch(handle401);
}

export const set2FAState = (fa) => ({type: userConstants.USER_SET_2FA_STATE, payload: {'2fa': fa}});

export const enable2FA = (token, authCode, setError) => (dispatch, getState, getHeaders) => {
  return API.post(`user/2fa`, {
    '2fa_code': authCode,
    token,
  }, getHeaders())
    .then(() => {
      dispatch(set2FAState(true));
      return 'success';
    })
    .catch(err => {
      dispatch(handle401(err));
      setError(err.response.data);
    });
}

export const disable2FA = (authCode, setError) => (dispatch, getState, getHeaders) => {
  return API.delete(`user/2fa`, {
    data: {
      '2fa_code': authCode
    },
    ...getHeaders(),
  })
    .then(() => {
      dispatch(set2FAState(false));
      return 'success';
    })
    .catch(err => {
      dispatch(handle401(err));
      if (err.response.status === 400) {
        setError(err.response.data);
      }
    });
}

const validateChangePassword = (fields, setError) => {
  const newPasswordErrorMessage = validatePassword(fields.new_password);
  const repeatPasswordErrorMessage = validateRepeatPassword(fields.new_password, fields.repeat_password);
  setError({
    new_password: newPasswordErrorMessage,
    repeat_password: repeatPasswordErrorMessage,
  });

  if (newPasswordErrorMessage || repeatPasswordErrorMessage) {
    return null;
  }
}

export const changePassword = (fields, setError, setFinished, setNeedTwoFA) => (dispatch, getState, getHeaders) => {
  if (!fields['2fa_code']) {
    if (validateChangePassword(fields, setError) === null) {
      return null;
    }
  }
  API.post(`user/change_password`, fields, getHeaders())
    .then(() => {
      setFinished(true);
    })
    .catch(err => {
      dispatch(handle401(err));
      if (err.response.status === 400) {
        setError({
          global: err.response.data,
        });
      } else if (err.response.status === 406) {
        setNeedTwoFA(true);
      }
    });
}

export const setStaking = (staking) => ({type: userConstants.USER_SET_STAKING, payload: {staking}});

export const getStakingInfo = () => (dispatch, getState, getHeaders) => {
  return API.get(`staking`, getHeaders())
    .then(({data}) => {
      dispatch(setStaking(data));
    })
    .catch((err) => {
      dispatch(handle401(err));
    });
}

export const setStakingStatuses = (statuses) => ({type: userConstants.USER_SET_STAKING_STATUSES, payload: {statuses}});

export const getStakingStatuses = () => (dispatch, getState, getHeaders) => {
  return API.get(`status`, getHeaders())
    .then(({data}) => {
      dispatch(setStakingStatuses(data.map(status => ({name: status.name, price: status.price}))));
    })
    .catch(err => {
      dispatch(handle401(err));
    });
}

export const buyStakingStatus = (status, handleClose) => (dispatch, getState, getHeaders) => {
  const data = {
    status,
  }
  handleClose();
  return API.post(`status`, data, getHeaders())
    .then(({data}) => {
      // handleClose();
      dispatch(handleTransactionSuccess(data.tx_hash, 'https://etherscan.io/address/'))
    })
    .catch(err => {
      // handleClose();
      if (err.response.status !== 401) {
        dispatch(handleTransactionError(err.response.data));
      } else {
        dispatch(handle401(err));
      }
    });
}

export const sendToStaking = (stakingAmount, handleClose) => (dispatch, getState, getHeaders) => {
  const data = {
    amount: stakingAmount,
  }
  handleClose();
  return API.post(`staking`, data, getHeaders())
    .then(({data}) => {
      // handleClose();
      dispatch(handleTransactionSuccess(data.tx_hash, 'https://etherscan.io/address/'));
    })
    .catch(err => {
      // handleClose();
      if (err.response.status !== 401) {
        dispatch(handleTransactionError(err.response.data))
      } else {
        dispatch(handle401(err));
      }
    });
}

export const withdrawStaking = (stakingAmount, handleClose) => (dispatch, getState, getHeaders) => {
  const data = {
    amount: stakingAmount,
  }
  handleClose();
  return API.delete(`staking`, {
    data,
    ...getHeaders()
  })
    .then(({data}) => {
      // handleClose();
      dispatch(handleTransactionSuccess(data.tx_hash));
    })
    .catch((err) => {
      // handleClose();
      if (err.response.status !== 401) {
        dispatch(handleTransactionError(err.response.data));
      } else {
        dispatch(handle401(err));
      }
    });
}

export const withdrawProfit = (amount, handleClose, auto = false) => (dispatch, getState, getHeaders) => {
  const data = {
    amount,
    auto
  }
  handleClose();
  return API.post(`withdraw_ref_profit`, data, getHeaders())
    .then(({data}) => {
      // handleClose();
      dispatch(handleTransactionSuccess(data.tx_hash))
    })
    .catch(err => {
      // handleClose();
      if (isAuthorizationProblem(err)) {
        dispatch(handle401(err));
      } else {
        dispatch(handleTransactionError(err.response.data));
      }
    });
}

export const addBalance = (amount, handleClose) => (dispatch, getState, getHeaders) => {
  const data = {
    amount
  }
  handleClose();
  return API.post(`buy_wdx`, data, getHeaders())
    .then(({data}) => {
      // handleClose();
      dispatch(handleTransactionSuccess(data.tx_hash))
    })
    .catch(err => {
      // handleClose();
      if (isAuthorizationProblem(err)) {
        dispatch(handle401(err));
      } else {
        dispatch(handleTransactionError(err.response.data));
      }
    });
}

export const setTokenExchange = (tokenExchange) => ({type: userConstants.USER_TOKEN_EXCHANGE, payload: {tokenExchange}});

export const getTokenExchange = () => (dispatch, getState, getHeaders) => {
  return API.get(`user/token_exchange`, getHeaders())
    .then(({data}) => {
      dispatch(setTokenExchange(data.token))
    })
    .catch(err => {
      dispatch(handle401(err));
    })
}

