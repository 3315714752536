import React, {memo} from "react";

const SuccessIconUI = ({className = ''}) => {
  return <div className={className}>
    <svg width="calc(60px + 3.333vw)" height="calc(60px + 3.333vw)" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="62" cy="62" r="62" fill="#F8DD82"/>
      <path d="M33.8066 63.3655C33.2321 62.8011 33.228 61.8765 33.7975 61.3069L38.0289 57.0756C38.5938 56.5107 39.5094 56.5095 40.0758 57.073L62.7234 79.6061L56.5849 85.7446L33.8066 63.3655Z" fill="white"/>
      <path d="M85.5398 42.4885C86.1034 41.9093 87.0319 41.9029 87.6033 42.4744L92.9571 47.8282C93.5264 48.3975 93.5226 49.3218 92.9484 49.8863L62.7234 79.6061L55.9821 72.8649L85.5398 42.4885Z" fill="white"/>
    </svg>
  </div>
}

export default memo(SuccessIconUI);
