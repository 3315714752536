import {createSelector} from "reselect";

export const getUserSelector = (state) => state.user;

export const getHeaderViewUserSelector = createSelector(getUserSelector, user => {
  if (!user || !user.username || !user.wallet ||
    !user.lang || !user.wdxPrice || !user.usdtPrice) {
    return null;
  }
  return {
    username: user.username,
    balance_wdx: user.wallet.balance_wdx,
    lang: user.lang,
    wdxPrice: user?.wdxPrice,
    usdtPrice: user?.usdtPrice,
  }
});

export const getAddressUserSelector = createSelector(getUserSelector, user => {
  if (!user) {
    return null;
  }
  return user.address;
});

export const getBalanceInformationViewUserSelector = createSelector(getUserSelector, user => {
  if (!user) {
    return null;
  }
  return {
    ...user.wallet,
  }
})

export const getCarOrderSelector = createSelector(getUserSelector, user => {
  if (!user) {
    return null;
  }

  return {
    ...user.carOrder,
  }
})

export const getCarOrderPriceSelector = createSelector(getCarOrderSelector, carOrder => {
  if (!carOrder)
    return null;
  return +carOrder.price;
})

export const getWdxPriceSelector = createSelector(getUserSelector, user => {
  if (!user) {
    return null;
  }

  return user.wdxPrice;
})

export const getBalanceInWdxFormatSelector = createSelector(getBalanceInformationViewUserSelector, balance => {
  if (!balance) {
    return null;
  }

  return balance.balance_wdx;
})

export const getInsurancePriceSelector = createSelector(getCarOrderSelector, carOrder => {
  if (!carOrder) {
    return null;
  }

  return +carOrder.insurance_price;
});


export const getCarOrderBalanceSelector = createSelector(getCarOrderSelector, carOrder => {
  if (!carOrder || isNaN(+carOrder.balance))
    return 0;
  return +carOrder.balance;
})

export const getAuthSelector = createSelector(getUserSelector, user => {
  return user.isAuth;
})

export const getEthereumPrice = createSelector(getUserSelector, user => {
  if (!user) {
    return null;
  }

  return user.ethPrice;
})

export const getOperationListSelector = createSelector(getUserSelector, user => {
  return user.operationList;
})

export const getOperationListViewSelector = createSelector(getOperationListSelector, operationList => {
  return operationList.view;
})

export const getOperationListCurrentPageSelector = createSelector(getOperationListSelector, operationList => {
  return operationList.pageNumber;
})

export const getOperationListPageInfoSelector = createSelector(getOperationListSelector, operationList => {
  return {
    currentPage: operationList.pageNumber,
    inLastPage: operationList.inLastPage,
  }
})

export const getIcoPriceEthSelector = createSelector(getUserSelector, user => {
  return user.icoPriceEth;
})

export const getLastOperationSelector = createSelector(getUserSelector, user => {
  const defaultResult = {
    type: '',
    value: 0,
    currency: 'WI',
  };

  const {address} = user;
  const lastItem = user.operationList.list[1];
  if (!address || !lastItem) {
    return defaultResult;
  }
  const firstItemOfList = lastItem[0];

  if (!firstItemOfList) {
    return defaultResult;
  }

  const {tokenSymbol: currency} = firstItemOfList;
  const value = firstItemOfList.value / 10 ** firstItemOfList.tokenDecimal;
  const type = address.toLowerCase() === firstItemOfList.from.toLowerCase() ? '-' : '+';
  return {
    type,
    value,
    currency,
  }
});

export const getUserIdSelector = createSelector(getUserSelector, user => {
  if (!user.id) {
    return 0;
  }

  return user.id;
})

export const getPrepaymentAmountSelector = createSelector(getCarOrderSelector, carOrder => {
  const prepaymentAmount = +carOrder.prepayment_amount;
  if (isNaN(prepaymentAmount)) {
    return 0;
  }

  return prepaymentAmount;
})

export const getFASelector = createSelector(getUserSelector, user => {
  return user.fa;
})

export const get2FAActivated = createSelector(getUserSelector, user => {
  return user['2fa'];
});

const findCorrectNumber = (number) => {
  return Boolean(number) && number !== '0E-8' ? number : 0;
}

export const getStakingInfoViewSelector = createSelector(getUserSelector, user => {
  const { staking } = user;
  return {
    total_profit: {
      wdx: findCorrectNumber(staking?.total_profit.wdx),
      usd: findCorrectNumber(staking?.total_profit.usd),
    },
    volume: {
      wdx: findCorrectNumber(staking?.volume.wdx),
      usd: findCorrectNumber(staking?.volume.usd),
    },
    current_staking_rate: findCorrectNumber(staking?.current_staking_rate),
    time_to_next_enrollment: findCorrectNumber(staking?.time_to_next_enrollment),
    duration: findCorrectNumber(staking?.duration),
  };
});

export const getStakingWithdrawViewSelector = createSelector(getUserSelector, user => {
  const {staking} = user;

  return {
    withdrawal_limit: {
      usd: findCorrectNumber(staking?.withdrawal_limit.usd),
      wdx: findCorrectNumber(staking?.withdrawal_limit.wdx),
    },
    time_to_period_update: findCorrectNumber(staking?.time_to_period_update),
    withdrawn: {
      usd: findCorrectNumber(staking?.withdrawn.usd),
      wdx: findCorrectNumber(staking?.withdrawn.wdx),
    }
  };
});

export const getStakingStatusesSelector = createSelector(getUserSelector, user => {
  const {stakingStatuses} = user;

  if (!stakingStatuses) {
    return [];
  }

  return stakingStatuses;
});

export const getStakingSelector = createSelector(getUserSelector, user => {
  return user.staking;
});

export const getCurrentStakingStatusSelector = createSelector(getStakingSelector, staking => {
  if (!staking) {
    return -1;
  }

  return staking.status;
});

export const getStakingPriceSelector = createSelector(getStakingSelector, staking => {
    if (!staking) {
        return 0.00;
    }

    return findCorrectNumber(staking.volume.wdx);
});

export const getTokenExchangeSelector = createSelector(getUserSelector, user => {
  return user.tokenExchange;
});