import React, {memo, useMemo} from "react";

import DiagramSliderUI from "../ui/DiagramSliderUI";

import withToFixed from "../../../../helpers/hoc/withToFixed";

import styles from "./DiagramSlider.module.css";

const DiagramSliderContainer = ({needCollect, collected, toFixed}) => {
  const style = useMemo(() => {
    if (needCollect === 0) {
      return {
        width: '100%',
        backgroundColor: '#84C27A'
      }
    }

    const percentage = collected * 100 / needCollect;
    const colorStyle = {};

    if (collected - needCollect >= 0) {
      colorStyle.backgroundColor = '#84C27A';
    }

    return {
      width: `${percentage > 100 ? 100 : percentage}%`,
      ...colorStyle,
    }
  }, [needCollect, collected]);

  const fixedCollected = useMemo(() => {
    return toFixed(collected);
  }, [collected, toFixed]);

  return <DiagramSliderUI classes={styles}
                          style={style}
                          needCollect={needCollect}
                          collected={fixedCollected}
  />;
}

export default memo(withToFixed(DiagramSliderContainer)(6));
