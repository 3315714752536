import React, {memo} from "react";
 
import RenderInputContainer from "../../../../../../common/RenderInput/functional/RenderInputContainer";
import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import InputWithDropDownContainer
  from "../../../../../../common/InputWithDropDown/functional/InputWithDropDownContainer";
import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";
import {modalDescription} from "../../../../../../InfoModal/constans/modalDescription";

const WithdrawBalanceUI = ({classes, options, customStyle, 
                            //  disabled,
                             wallet, handleWalletChange, handleSubmit,
                             sum, handleSumChange, handleSelectChange, 
                             currency, handleClose, startSubmission, handleSent}) => {
  return <div className={classes['WithdrawBalance']}>
    <span className={classes['WithdrawBalance-Title']}>Вывод средств:</span>
    <form className={classes['WithdrawBalance-Form']}>
      <label htmlFor="sum" className={classes['WithdrawBalance-Label']}>
        <span className={classes['WithdrawBalance-Label_text']}>сумма:</span>
        <InputWithDropDownContainer
                                    selectOnChange={handleSelectChange}
                                    value={sum}
                                    onChange={handleSumChange}
                                    customStyle={customStyle}
                                    inputClassName={`${classes['WithdrawBalance-Input']} 
                                    ${classes['WithdrawBalance-Input_withDropDown']}`}
                                    selectWidth="calc(10% + 50px)"
                                    options={options}/>
      </label>
      <label htmlFor="email" className={classes['WithdrawBalance-Label']}>
        <span className={classes['WithdrawBalance-Label_text']}>Кошелек:</span>
        <RenderInputContainer name="email"
                              onChange={handleWalletChange}
                              value={wallet}
                              className={classes['WithdrawBalance-Input']}
        />
      </label>
    </form>
    <ButtonContainer
      onClick={handleSubmit}
      disabled={(sum.length !== 0 && wallet.length !== 0) ? false : true}
      className={classes['WithdrawBalance-Submit']}
      text="Вывести"/>
 
    {startSubmission && <InfoModalContainer handleClose={handleClose}
                                            selected={sum}
                                            title='Вывод средств'
                                            btnText='Вывести'
                                            descFirst={modalDescription.walletOutput[0]}
                                            currency={currency.toUpperCase()}
                                            handleSubmit={handleSent}
                                            descSecond={modalDescription.walletOutput[1]}
                                            convert={wallet}
                                            />}  
  </div>
}
 
export default memo(WithdrawBalanceUI);