import React from "react";

import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import RenderLineContainer from "../../../../../../common/RenderLine/functional/RenderLineContainer";
import RenderInputLikeContainer from "../../../../../../common/RenderInputLike/functional/RenderInputLikeContainer";
import {modalDescription} from "../../../../../../InfoModal/constans/modalDescription";
import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";

const BuyStakingUI = ({classes, inputRef, handleSubmit, startSubmission, handleClose, handleSent}) => {
  return <div className={classes['BuyStaking']}>
    <span className={classes['BuyStaking-Title']}>Стейкинг</span>
    <span className={classes['BuyStaking-TextLine']}>У вас еще нет стейкинга </span>
    <span className={classes['BuyStaking-TextLine']}>Для того, чтобы открыть стейкинг, нужно отправить токены на смарт-контракт</span>
    <RenderLineContainer className={classes['BuyStaking-Line']}/>
    <form className={classes['Form']}>
      <RenderInputLikeContainer className={classes['Form-Input']}
                                inputRef={inputRef}/>
      <ButtonContainer onClick={handleSubmit} text="Отправить на стейкинг" className={classes['Form-Button']}/>
      {startSubmission && <InfoModalContainer handleClose={handleClose}
                                              selected={inputRef.current.textContent}
                                              title='Стейкинг'
                                              btnText='Отправить'
                                              descFirst={modalDescription.stackingSend}
                                              currency='WI'
                                              handleSubmit={handleSent}/>}
    </form>
  </div>
};

export default BuyStakingUI;
