import React, {memo} from "react";

import CycledCloseIconContainer from "../../common/icons/CycledCloseIcon/functional/CycledCloseIconContainer";

const ModalUI = ({classes, Icon, content, style = {}, handleCloseModal}) => {
  return <div className={classes['Modal']}>
    <div className={classes['Modal-Background']}/>
    <div className={classes['Modal-Body']} style={style}>
       <CycledCloseIconContainer className={classes['Modal-Close-Icon']} onClick={handleCloseModal}/>
       <Icon />
       <span className={classes['Modal-Title']}>{content.title}</span>
      {content.description &&
      <span className={classes['Modal-Description']}>{content.description}</span>}
      {content.additionalComponent}
    </div>
  </div>
}

export default memo(ModalUI);
