import React from "react";

import EnableFAContainer from "../functional/TwoFA/functional/EnableFA/functional/EnableFAContainer";
import ModelContainer from "../functional/Model/functional/ModelContainer";

const SettingsUI = ({classes, handleEnable, startOperation, activated}) => {
  return <div className={classes['Settings']}>
    {
      startOperation ? <EnableFAContainer handleEnable={handleEnable} activated={activated}/> : <>

       <ModelContainer title="Безопасность" handleEnable={handleEnable} activated={activated}/>
      </>
    }
  </div>;
}

export default SettingsUI;
