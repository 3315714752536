import React from "react";
import InfoModalUI from "../ui/InfoModalUI";
 
import styles from "./InfoModal.module.css";
 
const InfoModalContainer = ({ handleClose, selected, handleSubmit, title, btnText, descFirst, descSecond, currency, convert, convertCurrency, }) => {
  
  return <InfoModalUI handleSubmit={handleSubmit}
                      classes={styles}
                      handleClose={handleClose}
                      selected={selected}
                      title={title}
                      btnText={btnText}
                      descFirst={descFirst}
                      descSecond={descSecond}
                      currency={currency}
                      convert={convert}
                      convertCurrency={convertCurrency}
                      />;
}
 
export default InfoModalContainer;