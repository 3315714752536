import React, {useCallback, useMemo} from "react";

import {useDispatch, useSelector} from "react-redux";

import ModalUI from "../ui/ModalUI";
import SuccessIconContainer from "../../common/icons/SuccessIcon/functional/SuccessIconContainer";
import BigErrorIconContainer from "../../common/icons/BigErrorIcon/functional/BigErrorIconContainer";

import {getModalSelector} from "../../../redux/modal/modalSelectors";
import {closeModal} from "../../../redux/modal/modalActions";

import styles from "./Modal.module.css";

const ModalContainer = () => {
  const {type, open, content} = useSelector(getModalSelector)
  const dispatch = useDispatch();

  const Icon = useMemo(() => {
    switch (type) {
      case 'success':
        return SuccessIconContainer;
      case 'error':
        return BigErrorIconContainer;
      default:
        return () => null;
    }
  }, [type]);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  if (!open) {
    return null;
  }

  return <ModalUI classes={styles}
                  Icon={Icon}
                  style={content.style}
                  handleCloseModal={handleCloseModal}
                  content={content}/>;
}

export default ModalContainer;
