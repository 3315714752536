import React, {useCallback} from "react";

import {useSelector} from "react-redux";

import PartnersProgramSectionUI from "../ui/PartnersProgramSectionUI";

import {getUserIdSelector} from "../../../../../../../redux/user/userSelectors";
import {getReferralLinkSelector} from "../../../../../../../redux/referral/referralSelectors";

import styles from "./PartnersProgramSection.module.css";

const PartnersProgramSectionContainer = () => {
  const id = useSelector(getUserIdSelector);
  const referralLink = useSelector(getReferralLinkSelector);

  const handleCopy = useCallback(() => {
    document.getElementById('PartnersProgramSection-Input').select();
    document.execCommand('copy');
  }, []);

  return <PartnersProgramSectionUI
                                   classes={styles}
                                   handleCopy={handleCopy}
                                   referralLink={referralLink}
                                   id={id}/>;
}

export default PartnersProgramSectionContainer;
