import React, {memo} from "react";

const CloseIconContainer = ({className, ...rest}) => {
  return <div className={className} {...rest}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6.5" stroke="#4E5566"/>
      <path
        d="M3.90769 4.34923C3.78834 4.22991 3.78834 4.03133 3.90769 3.90785C4.0312 3.78853 4.22566 3.78853 4.34918 3.90785L6.99843 6.56088L9.65213 3.90785C9.77148 3.78853 9.97011 3.78853 10.0892 3.90785C10.2127 4.03133 10.2127 4.23019 10.0892 4.34923L7.43993 6.99809L10.0892 9.65112C10.2127 9.77044 10.2127 9.96902 10.0892 10.0925C9.96983 10.2118 9.7712 10.2118 9.65213 10.0925L6.99843 7.43948L4.34918 10.0925C4.22566 10.2118 4.0312 10.2118 3.90769 10.0925C3.78834 9.96902 3.78834 9.77016 3.90769 9.65112L6.55693 6.99809L3.90769 4.34923Z"
        fill="#4E5566"/>
    </svg>
  </div>
}

export default memo(CloseIconContainer);
