import React, {useMemo} from "react";

import RenderSelectUI from "../ui/RenderSelectUI";

const RenderSelectContainer = (props) => {
  const { style = {} } = props;

  const customStyles = useMemo(() => {
    const {control = {}, container = {}, indicatorSeparator = {},
    dropdownIndicator = {}, singleValue = {}, menu = {}, valueContainer = {},
      menuList = {}, option = {}, indicatorsContainer = {}, placeholder = {} } = style;

    return {
      control: (provided) => ({
        ...provided,
        height: 'calc(35px + 1.563vw)',
        borderRadius: 12,
        border: '1px solid #D9D9D9',
        boxShadow: 'none',
        ...control,
      }),
      container: (provided) => ({
        ...provided,
        width: '21.354vw',
        ...container,
      }),
      indicatorSeparator: () => ({
        display: 'none',
        ...indicatorSeparator,
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        ...indicatorsContainer,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
        color: '#4E5566',
        marginRight: '1.094vw',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        transition: 'all .2s ease',
        ...dropdownIndicator,
      }),
      valueContainer: (provided) => ({
        ...provided,
        ...valueContainer
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: 'calc(8px + 0.417vw)',
        lineHeight: '150%',
        letterSpacing: '0.03em',
        fontWeight: 600,
        fontFamily: `'Montserrat', sans-serif`,
        marginLeft: '0',
        width: 'fit-content',
        ...singleValue,
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: 15,
        background: '#F2F2F2',
        transform: 'translateY(-72px)',
        ...menu,
      }),
      menuList: (provided) => ({
        ...provided,
        padding: '20px 0',
        backgroundColor: '#fff',
        borderRadius: 15,
        ...menuList,
      }),
      placeholder: (provided) => ({
        ...provided,
        ...placeholder,
      }),
      option: (provided, state) => {
        const { isSelected, isDisabled } = state;

        return {
          ...provided,
          height: 70,
          display: isSelected ? 'none' : 'flex',
          alignItems: 'center',
          borderRadius: 0,
          backgroundColor: '#F2F2F2',
          color: '#303030',
          opacity: isDisabled ? 0.3 : 1,
          '&:first-of-type': {
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#E9E9E9'
          },
          '&:last-of-type': {
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15
          },
          ...(typeof option === 'function' ? option(provided, state) : option),
        }
      },
    };
  }, [style]);

  return <RenderSelectUI  customStyles={customStyles}
                          {...props}/>;
}

export default RenderSelectContainer;
