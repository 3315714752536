import React, {useCallback, useMemo, useState} from "react";

import PaginationUI from "../ui/PaginationUI";

import styles from "./Pagination.module.css";

const PaginationContainer = ({currentPage = 1, canReceiveNextPage,
                               inLastPage = false, getPage, ...rest}) => {
  const [disabled, setDisabled] = useState(false);

  const getListByPage = useCallback((page, type) => {
    if(page <= 0 || (type === 'UP' && !canReceiveNextPage)) {
      return null;
    }
    setDisabled(true);
    getPage(page)
      .then(() => setDisabled(false), () => setDisabled(false));
  }, [getPage, canReceiveNextPage]);

  const style = useMemo(() => ({
    pointerEvents: disabled ? 'none' : 'auto',
  }), [disabled]);

  const previousArrowFillColor = useMemo(() => {
    return currentPage === 1 ? '#E9E9E9' : '#F8DD82';
  }, [currentPage]);

  const nextArrowFillColor = useMemo(() => {
    return inLastPage ? '#E9E9E9' : '#F8DD82';
  }, [inLastPage]);

  return <PaginationUI classes={styles}
                       getListByPage={getListByPage}
                       style={style}
                       previousArrowFillColor={previousArrowFillColor}
                       nextArrowFillColor={nextArrowFillColor}
                       {...rest}
                       currentPage={currentPage}/>;
}

export default PaginationContainer;
