import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import StakingUI from "../ui/StakingUI";
import BuyStakingContainer from "./BuyStaking/functional/BuyStakingContainer";

import { getStakingInfo } from "../../../../../redux/user/userActions";
import {getStakingPriceSelector} from "../../../../../redux/user/userSelectors";

import styles from "./Staking.module.css";

const StakingContainer = () => {
  const dispatch = useDispatch();

  const stakingAmount = useSelector(getStakingPriceSelector);
  const stakingAmountStatus = stakingAmount <= 0

  useEffect(() => {
    dispatch(getStakingInfo());
  }, [dispatch])

  if (stakingAmountStatus) {
    return <BuyStakingContainer />;
  }

  return <StakingUI classes={styles}/>;
}

export default StakingContainer;
