import React from "react";

const LeftArrowIconUI = ({className = '', fillColor = '#E9E9E9', ...rest}) => {
  return <div className={className} {...rest}>
    <svg width="15"
         height="22"
         viewBox="0 0 15 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M0.884582 12.5251C-0.0906569 11.7249 -0.0906553 10.2328 0.884584 9.43267L11.5871 0.651874C12.8925 -0.419127 14.8556 0.509559 14.8556 2.19808L14.8556 19.7597C14.8556 21.4482 12.8925 22.3769 11.5871 21.3059L0.884582 12.5251Z"
            fill={fillColor}/>
    </svg>
  </div>
}

export default LeftArrowIconUI;
