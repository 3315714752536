import React from "react";
import styles from "./AutoDocuments.module.css";

import AutoDocumentsUI from "../ui/AutoDocumentsUI";

const AutoDocumentsContainer = () => {
  return <AutoDocumentsUI classes={styles} />;
}

export default AutoDocumentsContainer;
