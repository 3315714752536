import React, {memo} from "react";

import ButtonContainer from "../../common/Button/functional/ButtonContainer";
import PasswordInputWithEyeIconContainer
  from "../../common/PasswordInputWithEyeIcon/functional/PasswordInputWithEyeIconContainer";
import RenderErrorMessageContainer from "../../common/RenderErrorMessage/functional/RenderErrorMessageContainer";

const ChangePasswordUI = ({classes, handleOnChange, fields, disabled, handleOnSubmit, error}) => {
  return <div className={classes['Change-Password']}>
    <form className={classes['Form']} onSubmit={handleOnSubmit}>
      <span className={classes['Form-Title']}>Смена пароля</span>
      <label className={classes['Form-Label']}>
        <span className={classes['Form-Label-Text']}>Введите пароль</span>
        <PasswordInputWithEyeIconContainer
          name="password"
          onChange={handleOnChange}
          hasError={error}
          value={fields.password}
          className={classes['Form-Input']}/>
      </label>
      <label className={`${classes['Form-Label']} ${classes['Form-Label-top']}`}>
        <span className={classes['Form-Label-Text']}>Введите пароль повторно</span>
        <PasswordInputWithEyeIconContainer
          name="repeat_password"
          onChange={handleOnChange}
          hasError={error}
          value={fields.repeat_password}
          className={classes['Form-Input']}/>
      </label>
      {error && <RenderErrorMessageContainer message={error}/>}
      <ButtonContainer className={classes['Form-Submit']}
                       disabled={disabled}
                       type="submit"
                       text="Сменить пароль"/>
    </form>
  </div>
};

export default memo(ChangePasswordUI);
