import React, {useCallback, useState} from "react";

import {useDispatch} from "react-redux";

import PasswordRecoveryUI from "../ui/PasswordRecoveryUI";
import SuccessWindowContainer from "../../common/SuccessWindow/functional/SuccessWindowContainer";

import {validateEmail} from "../../../redux/helpers/validators";
import {resetPassword} from "../../../redux/user/userActions";

import styles from "./PasswordRecovery.module.css";

const PasswordRecoveryContainer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [finished, setFinished] = useState(false);

  const dispatch = useDispatch();

  const handleEmailChange = useCallback((e) => {
    setError(false);
    setEmail(e.target.value);
  }, []);

  const handleSubmit = useCallback((e) => {
      e.preventDefault();
      setDisabled(true);
      const error = validateEmail(email);
      setError(error);
      if (!error) {
        dispatch(resetPassword(email))
          .then((type) => {
            setDisabled(false);
            if(type === 'success') {
              setFinished(true);
            }
          })
      }
    }, [email, dispatch]);

  if (finished) {
    return <SuccessWindowContainer
      title="Письмо отправлено"
      description="На указанный email адрес была отправлена ссылка для восстановления пароля"
      redirectMessage="Войти в личный кабинет"
      redirectHref="/login"
    />
  }

  return <PasswordRecoveryUI
    email={email}
    handleEmailChange={handleEmailChange}
    handleSubmit={handleSubmit}
    disabled={disabled}
    error={error}
    classes={styles}/>;
}

export default PasswordRecoveryContainer;
