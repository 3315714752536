import React, {useEffect, useMemo, useState} from "react";

import BalanceInformationItemUI from "../ui/BalanceInformationItemUI";

import styles from "./BalanceInformationItem.module.css";

const BalanceInformationItemContainer = (props) => {
  const [src, setSrc] = useState(null);
  const {item} = props;
  const {icon} = item;

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = icon;
    imageLoader.onload = () => {
      setSrc(icon);
    }
  }, [icon]);

  const backgroundStyle = useMemo(
    () => (icon ? { backgroundImage: `url(${src})` } : {}), [src, icon]);

  return <BalanceInformationItemUI item={item} classes={styles} backgroundStyle={backgroundStyle} />;
}

export default BalanceInformationItemContainer;
