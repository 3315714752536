import { combineReducers } from "redux";
import registerFormReducer from "../register-form/registerFormReducer";
import loginFormReducer from "../login-form/loginFormReducer";

const formsReducer = combineReducers({
  registerForm: registerFormReducer,
  loginForm: loginFormReducer,
});


export default formsReducer;
