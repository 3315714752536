import React from "react";
import InputModalUI from "../ui/InputModalUI";
 
import styles from "./InputModal.module.css";
 
const InputModalContainer = ({ disabled, handleAmountChange, handleSubmit, handleClose, title, amount, }) => {
  
  return <InputModalUI handleSubmit={handleSubmit}
                       classes={styles}
                       handleClose={handleClose}
                       disabled={disabled}
                       title={title}
                       handleAmountChange={handleAmountChange}
                       amount={amount}
                      />;
}
 
export default InputModalContainer;