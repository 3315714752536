import React, {memo} from "react";

import PartnersProgramSectionContainer
  from "../functional/PartnersProgramSection/functional/PartnersProgramSectionContainer";
import PartnerInfoContainer from "../functional/PartnerInfo/functional/PartnerInfoContainer";
import GeneralInfoAboutPartnerProgramContainer
  from "../functional/GeneralInfoAboutPartnerProgram/functional/GeneralInfoAboutPartnerProgramContainer";
import SelectViewContainer from "../../../../common/SelectView/functional/SelectViewContainer";
import LevelDependentReferralInfoContainer
  from "../functional/LevelDependentReferralInfo/functional/LevelDependentReferralInfoContainer";

const PartnersUI = ({classes, levels, currentLevel, setCurrentLevel}) => {
  return <div className={classes.Partners}>
    <div className={classes['Partners-Wrapper']}>
      <div className={classes.PartnersProgram}>
        <PartnersProgramSectionContainer />
        <PartnerInfoContainer />
      </div>
      <GeneralInfoAboutPartnerProgramContainer />
    </div>
    <SelectViewContainer
      data={levels}
      handleActiveChange={setCurrentLevel}
      active={currentLevel}
      selectTitle="Уровень"
      className={classes['PartnersLevelSelect']}
    />
    <LevelDependentReferralInfoContainer currentLevel={currentLevel}/>
  </div>
}

export default memo(PartnersUI);
