import React, {memo} from "react";

import RenderInputContainer from "../../../../../../common/RenderInput/functional/RenderInputContainer";
import CopyIconContainer from "../../../../../../common/icons/CopyIcon/functional/CopyIconContainer";

const PartnersProgramSectionUI = ({classes, handleCopy, referralLink}) => {

  return <div className={classes['PartnersProgramSection']}>
    <span className={classes['PartnersProgramSection-Title']}>Партнерская программа</span>
    <div className={classes['PartnersProgramSection-Wrapper']}>
      <span className={classes['PartnersProgramSection-Text']}>ваша реферальная ссылка</span>
      <div className={classes['PartnersProgramSection-InnerWrapper']}>
        <RenderInputContainer value={referralLink}
                              id="PartnersProgramSection-Input"
                              onChange={() => {}}
                              className={classes['PartnersProgramSection-Input']}/>
        <button className={classes['PartnersProgramSection-Button']}
                onClick={handleCopy}>
          <CopyIconContainer />
          <span className={classes['PartnersProgramSection-Button-Title']}>Скопировать ссылку</span>
        </button>
      </div>
    </div>
  </div>;
}

export default memo(PartnersProgramSectionUI);
