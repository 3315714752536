import React, {memo} from "react";

import QuestionMarkUI from "../ui/QuestionMarkUI";

import styles from "./QuestionMark.module.css";

const QuestionMarkContainer = (props) => {
  return <QuestionMarkUI classes={styles} {...props}/>;
}

export default memo(QuestionMarkContainer);
