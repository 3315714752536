import React, {useCallback, useState} from "react";

import PasswordInputWithEyeIconUI from "../ui/PasswordInputWithEyeIconUI";

import styles from "./PasswordInputWithEyeIcon.module.css";

const PasswordInputWithEyeIconContainer = (props) => {
  const [active, setActive] = useState(false);

  const handleEyeClick = useCallback(() => {
    setActive(!active);
  }, [active]);

  return <PasswordInputWithEyeIconUI classes={styles}
                                     handleEyeClick={handleEyeClick}
                                     active={active}
                                     {...props}/>;
}

export default PasswordInputWithEyeIconContainer;
