import React, {useCallback} from "react";

const withWdxCalculation = (Component) => {
  return (props) => {
    const currencyInWdx = useCallback((currency, wdxPrice) => {
      const sum = currency / wdxPrice;
      if (isNaN(sum)) {
        return 0;
      }
      return sum;
    }, []);

    return <Component {...props} currencyInWdx={currencyInWdx}/>
  }
}

export default withWdxCalculation;
