import React, {memo} from "react";

import RenderSelectContainer from "../../../../../../common/RenderSelect/functional/RenderSelectContainer";
import NegativeButtonContainer from "../../../../../../common/NegativeButton/functional/NegativeButtonContainer";
// import StatusModalContainer from "../functional/StatusModal/functional/StatusModalContainer";
import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";
import {modalDescription} from "../../../../../../InfoModal/constans/modalDescription";

const StatusUI = ({
                    classes, style, options = [], isLoading, handleClose, handleSent,
                    handleChange, selectedStatus, startSubmission, handleSubmit
                  }) => {

  return <>
    {startSubmission && <InfoModalContainer handleClose={handleClose}
                                            selected={selectedStatus.label}
                                            title='Статус'
                                            btnText='Приобрести'
                                            descFirst={modalDescription.stakingStatus}
                                            handleSubmit={handleSent}
                                            />}
    <div className={classes['Status']}>
      <span className={classes['Status-Title']}>Статус</span>
      <div className={classes['Status-Wrapper']}>
        <RenderSelectContainer options={options}
                               value={selectedStatus}
                               style={style}
                               isLoading={isLoading}
                               placeholder={'Loading Statuses...'}
                               isDisabled={startSubmission}
                               onChange={handleChange}
          />
        <NegativeButtonContainer text="Обновить"
                                 className={classes['Status-Action']}
                                 disabled={Boolean(startSubmission || !options.length)}
                                 onClick={handleSubmit}/>
      </div>
    </div>
  </>
}

export default memo(StatusUI);
