import referralConstants from "./referralConstants";

const initialState = {

}

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case referralConstants.REFERRAL_SET_REFERRAL:
      return action.payload.referral;
    default:
      return state;
  }
}

export default referralReducer;

