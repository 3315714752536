import React, {useCallback, useEffect, useState} from "react";

import CloseIconRoundedUI from "../ui/CloseIconRoundedUI";

const CloseIconRoundedContainer = (props) => {
  const {open, onClick} = props;
  const [clicked, setClicked] = useState(false);
  const [strokeColor, setStrokeColor] = useState('D9D9D9');


  const handleMouseEnter = useCallback(() => {
    setStrokeColor('ABADB4');
    setClicked(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setStrokeColor('D9D9D9');
  }, []);

  const handleClick = useCallback(() => {
    onClick();
    setClicked(true);
  }, [onClick]);

  useEffect(() => {
    if (open === false) {
      if(clicked === false) {
        setClicked(null);
      }
    }
  }, [open, clicked]);

  if (!open && clicked === null) {
    return null;
  }


  return <CloseIconRoundedUI
    {...props}
    strokeColor={strokeColor}
    onClick={handleClick}
    handleMouseEnter={handleMouseEnter}
    handleMouseLeave={handleMouseLeave}
    />;
}

export default CloseIconRoundedContainer;
