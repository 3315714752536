import {useMemo} from "react";
import {useSelector} from "react-redux";

import {getCarOrderBalanceSelector, getWdxPriceSelector} from "../../../redux/user/userSelectors";
import useToFixed from "./useToFixed";

const useWdxBalance = () => {
  const wdxPrice = useSelector(getWdxPriceSelector);
  const balance = useSelector(getCarOrderBalanceSelector);

  const toFixed = useToFixed(6);

  return useMemo(() => {
    const balanceInWdx = balance * wdxPrice;

    if (isNaN(balanceInWdx))
      return 0;

    return toFixed(balanceInWdx);
  }, [toFixed, balance, wdxPrice]);

}

export default useWdxBalance;
