import React from "react";

import RenderErrorMessageUI from "../ui/RenderErrorMessageUI";

import styles from "./RenderErrorMessage.module.css";

const RenderErrorMessageContainer = (props) => {
  return <RenderErrorMessageUI classes={styles} {...props}/>;
}

export default RenderErrorMessageContainer;
