import API from "../../API";

import referralConstants from "./referralConstants";

export const setReferralsInfo = (referral) => ({ type: referralConstants.REFERRAL_SET_REFERRAL, payload: { referral } });

export const getReferralsInfo = () => (dispatch, getState, getHeaders) => {
  return API.get(`referrals`, getHeaders())
    .then(({data}) => {
      dispatch(setReferralsInfo(data));
    });
}
