import React from "react";

const RenderInputLikeUI = ({
                             classes, focused, handleBlur,
                             handleFocus, handleLabelClick, inputRef, currency = 'WI',
                             placeholder = '0.00', handleKeyPress,
                           }) => {
  return <div className={classes['Form-InputWrapper']}>
    <label contentEditable
           role="textbox"
           ref={inputRef}
           className={classes['Input']}
           data-placeholder={placeholder}
           onFocus={handleFocus}
           onBlur={handleBlur}
           onKeyPress={handleKeyPress}
    />
    <label htmlFor="staking" onClick={handleLabelClick} className={`${classes['Form-Input-Ending']} 
        ${focused ? classes['Form-Input-Ending-Focused'] : ''}`}>{currency}</label>
  </div>
};

export default RenderInputLikeUI;
