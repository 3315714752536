import React, {useCallback,   useRef, useState} from "react";
 
import {useDispatch, useSelector} from "react-redux";
 
import StakingInfoUI from "../ui/StakingInfoUI";
 
import {sendToStaking} from "../../../../../../../redux/user/userActions";
import {getStakingInfoViewSelector} from "../../../../../../../redux/user/userSelectors";
 
import styles from "./StakingInfo.module.css";
 
const StakingInfoContainer = () => {
  const [open, setOpen] = useState(false);
 
  const staking = useSelector(getStakingInfoViewSelector);
 
  const dispatch = useDispatch();
 
  const inputRef = useRef();
  const [startSubmission, setStartSubmission] = useState(false);
 
  const handleMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open])

  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);
 
  const handleSent= useCallback((e) => {
    e.preventDefault();
    dispatch(sendToStaking(inputRef.current.textContent, handleClose));
  }, [inputRef, handleClose, dispatch]);
 
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if(inputRef.current.textContent !== ''){
      return setStartSubmission(true);
    }
  }, []);

  return <StakingInfoUI classes={styles}
                        open={open}
                        inputRef={inputRef}
                        staking={staking}
                        handleMouseEnter={handleMouseEnter}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit}
                        handleClick={handleClick}
                        handleSent={handleSent}
                        startSubmission={startSubmission}
  />;
}
 
export default StakingInfoContainer;