import React, {useMemo} from "react";

import {useSelector} from "react-redux";

import LevelDependentReferralInfoUI from "../ui/LevelDependentReferralInfoUI";

import {getReferralsByLevelSelector} from "../../../../../../../redux/referral/referralSelectors";

import styles from "./LevelDependentReferralInfo.module.css";

import './static.css';

const LevelDependentReferralInfoContainer = ({currentLevel}) => {
  const tableData = useSelector(getReferralsByLevelSelector(currentLevel));

  const tableDataStyle = useMemo(() => ({
    fontSize: 'calc(8px + 0.313vw)',
    lineHeight: '150%',
    textTransform: 'capitalize',
    color: '#6D757D',
  }), []);
  const tableHeadStyle = useMemo(() => ({
    fontSize: 'calc(8px + 0.313vw)',
    lineHeight: '150%',
    color: '#6D757D',
    fontWeight: 'bold',
    gridTemplateColumns: 'repeat(4, 1fr) 120px',
    margin: 0,
  }), []);
  const tableRowStyle = useMemo(() => ({
    display: 'grid',
    gridTemplateColumns: '10.25% 24.3% 25.25% 18.5% 21.7%',
    width: '100%',
    margin: '0 1.563vw',
    borderBottom: '1px solid #E9E9E9',
  }), [])
  const tableStyle = useMemo(() => ({
    padding: '1.250vw 0',
    backgroundColor: '#F2F2F2',
    boxShadow: '0 0 4px #D3D3D3',
    borderRadius: 15,
    width: '100%',
    display: 'grid',
    marginTop: '1.563vw',
    overflowX: 'auto',
  }), []);

  return <LevelDependentReferralInfoUI
    tableData={tableData}
    tableDataStyle={tableDataStyle}
    tableRowStyle={tableRowStyle}
    tableHeadStyle={tableHeadStyle}
    classes={styles}
    tableStyle={tableStyle}/>;
}

export default LevelDependentReferralInfoContainer;
