import React, {memo} from "react";
 
import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import RenderInputContainer from "../../../../../../common/RenderInput/functional/RenderInputContainer";
import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";
import {modalDescription} from "../../../../../../InfoModal/constans/modalDescription";
 
const AddBalanceUI = ({classes, amount, handleAmountChange, calculateWdx, handleSubmit, startSubmission, handleSent, handleClose, disabled, }) => {
  return <div className={classes.AddBalance}>
    <span className={classes['AddBalance-Title']}>Купить WI</span>
    <div className={classes['AddBalance-Form']}>
      <label className={classes['AddBalance-Label']}>
        <span className={classes['AddBalance-LabelName']}>ETH</span>
        <RenderInputContainer name="usdt"
                              value={amount}
                              onChange={handleAmountChange}
                              className={classes['AddBalance-Input']}/>
      </label>
      <label className={classes['AddBalance-Label']}>
        <span className={classes['AddBalance-LabelName']}>WI</span>
        <RenderInputContainer name="wdx"
                              readOnly
                              value={`= ${calculateWdx}  `}
                              disabled
                              className={classes['AddBalance-Input']}/>
      </label>
    </div>
    <ButtonContainer text="Пополнить"
                     onClick={handleSubmit}
                     disabled={disabled}
                     className={classes['AddBalance-Submit']}/>
   {startSubmission && <InfoModalContainer  handleClose={handleClose}
                                            selected={calculateWdx}
                                            title='Купить WI'
                                            btnText='Пополнить'
                                            descFirst={modalDescription.walletSend[0]}
                                            currency='WI'
                                            handleSubmit={handleSent}
                                            descSecond={modalDescription.walletSend[1]}
                                            convert={amount}
                                            convertCurrency='ETH'
                                            />}
  </div>;
}
 
export default memo(AddBalanceUI);
