import React from "react";

const DiagramSliderUI = ({classes, style, needCollect, collected}) => {
  return <div className={classes['Diagram']}>
    <div className={classes['Diagram-View']}>
      <span className={classes['View-Filled']} style={style}/>
      <span className={`${classes['View-Text']}
        ${parseInt(style.width) <= 55 ? classes['View-Text-Black'] : ''}`}>
          {collected}/{needCollect} &#8381;</span>
    </div>
  </div>;
}

export default DiagramSliderUI;
