import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";

import BalanceInformationListUI from "../ui/BalanceInformationListUI";

import {
  getBalanceInformationViewUserSelector,
  getLastOperationSelector
} from "../../../../../../../redux/user/userSelectors";
import withToFixed from "../../../../../../../helpers/hoc/withToFixed";

import styles from "./BalanceInformationList.module.css";

import walletIcon from "../../../../../../../assets/icons/wallet.svg";
import moneyIcon from "../../../../../../../assets/icons/money.svg";
import ethereumIcon from "../../../../../../../assets/icons/ethereum.svg";

const BalanceInformationListContainer = ({toFixed}) => {
  const [data, setData] = useState(null);
  const balance = useSelector(getBalanceInformationViewUserSelector);

  const lastOperation = useSelector(getLastOperationSelector);

  useEffect(() => {
    const balanceWI = balance ? toFixed(balance.balance_wdx) : 0,
      balanceUSDT = balance ? toFixed(balance.balance_usdt) : 0,
      balanceETH = balance ? toFixed(balance.balance_eth) : 0;
    const items = [
      {id: 0, sectionName: 'Баланс кошелька:', value: `${balanceWI}<br /> WI`, icon: walletIcon},
      {id: 1, sectionName: 'Баланс в USDT:', value: `${balanceUSDT}<br /> USDT`, icon: moneyIcon},
      {id: 2, sectionName: 'Баланс в ETH:', value: `${balanceETH}<br /> ETH`, icon: ethereumIcon},
      {
        id: 3, sectionName: 'Последняя операция:',
        value: `${lastOperation.type} ${toFixed(lastOperation.value)}<br /> ${lastOperation.currency}`,
        icon: null
      },
    ];
    setData(items);
  }, [balance, toFixed, lastOperation]);

  if (!data) {
    return null;
  }

  return <BalanceInformationListUI data={data} classes={styles}/>
}

export default withToFixed(BalanceInformationListContainer)(8);
