import React, {useCallback, useState} from "react";

import EyeIconUI from "../ui/EyeIconUI";

const EyeIconContainer = (props) => {
  const [strokeColor, setStrokeColor] = useState('#D9D9D9');

  const handleMouseEnter = useCallback(() => {
    setStrokeColor('#ABADB4');
  }, []);

  const handleMouseLeave = useCallback(() => {
    setStrokeColor('#D9D9D9');
  }, []);

  return <EyeIconUI
    strokeColor={strokeColor}
    handleMouseEnter={handleMouseEnter}
    handleMouseLeave={handleMouseLeave}
    {...props}/>;
}

export default EyeIconContainer;
