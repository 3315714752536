import React, {memo} from "react";

import Select from "react-select";

const RenderSelectUI = ({options, customStyles, ...rest}) => {
  return <Select options={options}
                 isSearchable={false}
                 isClearable={false}
                 dropdownVisible={false}
                 styles={customStyles}
                 defaultValue={options[0]}
                 isLoading={false}
                 {...rest}
        />
}

export default memo(RenderSelectUI);
