import React, {
  //useEffect,
  useState
} from "react";

import {
  Link, Redirect,
  //useHistory
} from "react-router-dom"

// import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";

// import { handleLoginExchange } from "../../../redux/login-form/loginFormActions";
import SuccessWindowContainer from "../../common/SuccessWindow/functional/SuccessWindowContainer";
import styles from "../../LoginExchange/functional/LoginExchange.module.css";

const LoginExchangeContainer = () => {

  const [error] = useState(false);

  // const { token } = useParams();

  // const dispatch = useDispatch();
  // const history = useHistory();

  // useEffect(() => {
  //   dispatch(handleLoginExchange(token, history, setError))
  // }, [token, dispatch, history]);

  if (error) {
    return <Redirect to={'/login'} />
  }

  return <SuccessWindowContainer
    title="Вход успешно выполнен"
    CustomComponent={() => <div className={styles['Wrapper']}>
      <span className={styles['Success-Description']}>Если вы автоматически не перешли в кабинет, нажмите на сслыку</span>
      <Link to="/main" className={styles['Success-Link']}>Войти в личный кабинет</Link>
    </div>}
  />
}

export default LoginExchangeContainer;
