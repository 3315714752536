import React from "react";

const CarPaymentUI = ({RenderComponent, classes, currentStep}) => {
  const arr = [1, 2, 3, 4, 5];
  return <>
    {(currentStep === 2 && currentStep === 3) && <div className={classes['CarPayment_big']}/>}
    <div
      className={`${classes.CarPayment}`}>
      <div className={classes['CarPayment-Wrapper']}>
        {currentStep >= 0 && arr.map((value, index) => <React.Fragment key={value}>
          <div className={classes.Step}>
        <span className={`${classes['Step-Text']} 
        ${value <= currentStep ? classes['Step_Text_active'] : ''}`}>{value}</span>
          </div>
          {index !== arr.length - 1 ?
            <span className={`${classes['Step-Line']} ${value <= currentStep ? classes['Step-Line_active'] : ''}`}/>
            : null}
        </React.Fragment>)}
      </div>
      <RenderComponent currentStep={currentStep} />
    </div>
  </>
}

export default CarPaymentUI;
