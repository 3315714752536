import React from "react";

import ContentUI from "../ui/ContentUI";

import styles from "./Content.module.css";

const ContentContainer = () => {
    return <ContentUI classes={styles}/>;
}

export default ContentContainer;