import React, {memo} from "react";

import BalanceInformationItemContainer
  from "../functional/BalanceInformationItem/functional/BalanceInformationItemContainer";

const BalanceInformationListUI = ({data, classes}) => {
  return <div className={classes.BalanceInformation}>
    {data.map(item => <BalanceInformationItemContainer item={item} key={item.id}/>)}
  </div>
}

export default memo(BalanceInformationListUI);
