import React, {useEffect} from "react";

import {Switch, Route} from "react-router-dom";

import {useDispatch} from "react-redux";

import WalletContainer from "../Wallet/functional/WalletContainer";
import CarPaymentContainer from "../CarPayment/functional/CarPaymentContainer";
import AutoDocumentsContainer from "../AutoDocuments/functional/AutoDocumentsContainer";
import PartnersContainer from "../Partners/functional/PartnersContainer";
import SettingsContainer from "../Settings/functional/SettingsContainer";
import StakingContainer from "../Staking/functional/StakingContainer";

import {getOrRedirectUser} from "../../../../redux/user/userActions";

const MainRouter = (props) => {
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrRedirectUser(history));
  }, [history, dispatch]);

  return <Switch>
    <Route exact path="/main" component={CarPaymentContainer} />
    <Route exact path="/wallet" component={WalletContainer} />
    <Route exact path="/car-documents" component={AutoDocumentsContainer} />
    <Route exact path="/partners" component={PartnersContainer} />
    <Route path="/settings" component={SettingsContainer} />
    <Route exact path="/staking" component={StakingContainer} />
  </Switch>
}

export default MainRouter;
