import React, {useCallback, useState} from "react";

import {useSelector} from "react-redux";

import SettingsUI from "../ui/SettingsUI";

import {get2FAActivated} from "../../../../../redux/user/userSelectors";

import styles from './Settings.module.css';
import {Route, Switch} from "react-router-dom";
import ChangePasswordFormContainer
  from "./ChangePassword/functional/ChangePasswordForm/functional/ChangePasswordFormContainer";

const SettingsContainer = () => {
  const [startOperation, setStartOperation] = useState(false);

  const activated = useSelector(get2FAActivated);

  const handleEnable = useCallback(() => {
    setStartOperation(!startOperation);
  }, [startOperation]);

  return <>
    <Switch>
      <Route exact path="/settings" render={() => <SettingsUI classes={styles}
                                                                   activated={activated}
                                                                   handleEnable={handleEnable}
                                                                   startOperation={startOperation}
      />}/>
      <Route exact path="/settings/change-password" component={ChangePasswordFormContainer}/>
    </Switch>
  </>;
}

export default SettingsContainer;
