import React from "react";

import ModelRowContainer from "../../Model/ModelRow/functional/ModelRowContainer";

const ChangePasswordUI = ({handleSubmit}) => {
  return <ModelRowContainer
  actionLeftTitle="Смена пароля"
  actionLeftValue="Рекомендуем менять пароль раз в 3-6 месяцев"
  buttonName="Сменить"
  submitFn={handleSubmit}
  />
}

export default ChangePasswordUI;
