export const validateEmail = (email) => {
  const emailRegex = new RegExp(/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/);
  if (email && emailRegex.test(email)) {
    return null;
  } else {
    return 'Пожалуйста, введите правильный адрес электронной почты';
  }
}

export const validatePassword = (password) => {
  if (password) {
    const containsUpperCase = /[a-z]/.test(password);
    const containsLowerCase = /[A-Z]/.test(password);
    if (password.length >= 8 && containsLowerCase && containsUpperCase) {
      return null;
    } else {
      return 'Пароль должен содержать не менее 8 символов, включая заглавные буквы и цифры.';
    }
  }
  return 'Пароль должен содержать не менее 8 символов, включая заглавные буквы и цифры.';
}

export const validateRepeatPassword = (password, repeatPassword) => {
  if (password !== repeatPassword) {
     return 'Пароли не совподают.'
  }
  return null;
}

export const validateSecurity = (security) => {
  if (security) {
    return null;
  } else {
    return '';
  }
}
