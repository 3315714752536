import globalSettingsConstants from "./globalSettingsConstants";

const initialState = {};

const globalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalSettingsConstants.GLOBAL_SETTINGS_SET_SETTING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default globalSettingsReducer;
