import React, {useCallback, useMemo, useState} from "react";

import ChangePasswordFormUI from "../ui/ChangePasswordFormUI";

import styles from "./ChangePasswordForm.module.css";
import {useDispatch} from "react-redux";
import {changePassword} from "../../../../../../../../../redux/user/userActions";
import SuccessWindowContainer from "../../../../../../../../common/SuccessWindow/functional/SuccessWindowContainer";
import TwoFAVerificationContainer from "./TwoFAVerification/functional/TwoFAVerificationContainer";

const ChangePasswordFormContainer = () => {
  const [fields, setFields] = useState({
    password: '',
    new_password: '',
    repeat_password: '',
  });
  const [errors, setErrors] = useState({});
  const [finished, setFinished] = useState(false);
  const [needTwoFA, setNeedTwoFA] = useState(false);

  const dispatch = useDispatch();

  const handleOnChange = useCallback((e) => {
    setErrors({});
    const {name, value} = e.target;
    setFields(fields => ({
      ...fields,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    dispatch(changePassword(fields, setErrors, setFinished, setNeedTwoFA));
  }, [fields, dispatch]);

  const successWindowStyle = useMemo(() => ({margin: '5.208vw auto 0'}), []);


  if (finished) {
    return <SuccessWindowContainer
      title="Пароль изменен"
      redirectMessage="Вернуться в настройки"
      redirectHref="/settings"
      className={styles['Success-Window']}
      style={successWindowStyle}
    />;
  }

  if (needTwoFA) {
    return <TwoFAVerificationContainer
      fields={fields}
      setFinished={setFinished}
    />;
  }

  return <ChangePasswordFormUI classes={styles}
                               errors={errors}
                               handleSubmit={handleSubmit}
                               handleOnChange={handleOnChange}/>;
}

export default ChangePasswordFormContainer;
