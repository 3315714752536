import React from "react";

import SuccessWindowUI from "../ui/SuccessWindowUI";

import styles from "./SuccessWindow.module.css";

const SuccessWindowContainer = (props) => {
  return <SuccessWindowUI classes={styles} {...props}/>;
}

export default SuccessWindowContainer;
