import React, {memo} from "react";

import LogoContainer from "../../../../common/Logo/functional/LogoContainer";

const HeaderUI = ({classes}) => {
  return <header className={classes.Header}>
    <LogoContainer className={classes.Logo} href="/"/>
    <div />
  </header>
}

export default memo(HeaderUI);
