export const registerFormConstants = {
  REGISTER_FORM_SET_ERROR: 'REGISTER_FORM_SET_ERROR',
  REGISTER_FORM_RESET_ERROR: 'REGISTER_FORM_RESET_ERROR',
  REGISTER_FORM_SET_FOCUS: 'REGISTER_FORM_SET_FOCUS',
  REGISTER_FORM_SET_BLUR: 'REGISTER_FORM_SET_BLUR',
  REGISTER_FORM_SET_PASSWORD: 'REGISTER_FORM_SET_PASSWORD',
  REGISTER_FORM_VIEW_PASSWORD: 'REGISTER_FORM_VIEW_PASSWORD',
  REGISTER_FORM_SET_EMAIL: 'REGISTER_FORM_SET_EMAIL',
  REGISTER_FORM_SET_REPEAT_PASSWORD: 'REGISTER_FORM_SET_REPEAT_PASSWORD',
  REGISTER_FORM_SET_SECURITY: 'REGISTER_FORM_SET_SECURITY',
  REGISTER_FORM_SET_REFERRAL_ID: 'REGISTER_FORM_SET_REFERRAL_ID',
  REGISTER_FORM_OPEN_DEFAULT_REGISTER: 'REGISTER_FORM_OPEN_DEFAULT_REGISTER',
  REGISTER_FORM_SET_CURRENT_STEP: 'REGISTER_FORM_SET_CURRENT_STEP',
  REGISTER_FORM_VIEW_REPEAT_PASSWORD: 'REGISTER_FORM_VIEW_REPEAT_PASSWORD',
  REGISTER_FORM_DISABLE_SUBMIT: 'REGISTER_FORM_DISABLE_SUBMIT',
  REGISTER_FORM_ENABLE_SUBMIT: 'REGISTER_FORM_ENABLE_SUBMIT',
  REGISTER_FORM_SET_ADDRESS: 'REGISTER_FORM_SET_ADDRESS',
  REGISTER_FORM_SET_REFERRAL_ID_FROM_QUERY: 'REGISTER_FORM_SET_REFERRAL_ID_FROM_QUERY',
};
