import React, {memo} from "react";

import FailureWindowUI from "../ui/FailureWindowUI";

import styles from "./FailureWindow.module.css";

const FailureWindowContainer = (props) => {
  return <FailureWindowUI classes={styles} {...props}/>;
}

export default memo(FailureWindowContainer);
