import React, {memo} from "react";

const CycledCloseIconUI = ({className, ...rest}) => {
  return <div className={className} {...rest}>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle cx="27" cy="23" r="23" fill="#F2F2F2"/>
      </g>
      <path d="M37.2681 13.1428L17.1431 33.2678M37.2681 33.2678L17.1431 13.1428" stroke="#4E5566"/>
      <defs>
        <filter id="filter0_d" x="0" y="0"
                width="54" height="54" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  </div>
}

export default memo(CycledCloseIconUI);
