import React, { memo } from "react";

const HeaderUI = ({ headerOptions, classes }) => {
    return headerOptions && <header className={classes.Header}>
        {
            headerOptions.map(option =>
                <option.component key={option.id} {...option.props} />)
        }
    </header>
}

export default memo(HeaderUI);
