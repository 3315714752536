import React, {useCallback, useState} from "react";

import { useParams } from "react-router-dom";
import {useDispatch} from "react-redux";

import ChangePasswordUI from "../ui/ChangePasswordUI";
import SuccessWindowContainer from "../../common/SuccessWindow/functional/SuccessWindowContainer";

import {validatePassword} from "../../../redux/helpers/validators";
import {recoverPassword} from "../../../redux/user/userActions";

import styles from "./ChangePassword.module.css";

const ChangePasswordContainer = () => {
  const [disabled, setDisabled] = useState(false);
  const [fields, setFields] = useState({
    password: '',
    repeat_password: '',
  });
  const [error, setError] = useState(null);
  const [finished, setFinished] = useState(false);

  const {token} = useParams();

  const dispatch = useDispatch();

  const handleOnChange = useCallback((e) => {
    setError(null);
    setFields({...fields, [e.target.name]: e.target.value});
  }, [fields]);

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault();
    setDisabled(true);
    const errorMessage = validatePassword(fields.password);
    if (fields.password !== fields.repeat_password) {
      setError('Пароли не совпадают.');
      return;
    } else if (errorMessage) {
      setError(errorMessage);
      return;
    }

    dispatch(recoverPassword({
      ...fields,
      token,
    })).then(data => {
      if(data.status === 200) {
        setFinished(true);
      } else if (data) {
        setError(data);
      }
    });
  }, [fields, token, dispatch]);

  if(finished) {
    return <SuccessWindowContainer
      title="Смена пароля"
      description="Смена пароля прошла успешно"
      redirectMessage="Войти в личный кабинет"
      redirectHref="/login"
    />
  }

  return <ChangePasswordUI classes={styles}
                           fields={fields}
                           error={error}
                           disabled={disabled}
                           handleOnSubmit={handleOnSubmit}
                           handleOnChange={handleOnChange}/>;
}

export default ChangePasswordContainer;
