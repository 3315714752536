import React, { useState, useEffect, useMemo } from "react";

import { NavLink, useLocation } from "react-router-dom";

import NavigationUI from "../ui/NavigationUI";
import PaymentContainer from "../../common/icons/Payment/functional/PaymentContainer";
import PartnersContainer from "../../common/icons/Partners/functional/PartnersContainer";
// import CarIconContainer from "../../common/icons/CarIcon/functional/CarIconContainer";
// import DocumentsIconContainer from "../../common/icons/DocumentsIcon/functional/DocumentsIconContainer";
//import SettingsIconContainer from "../../common/icons/SettingsIcon/functional/SettingsIconContainer";
import StakingIconContainer from "../../common/icons/StakingIcon/functional/StakingIconContainer";

import { useMediaQuery } from "../../common/hooks/useMediaQuery";

import styles from "./Navigation.module.css";


const NavigationContainer = () => {
  const [navigation, setNavigation] = useState(null);

  const matches1300 = useMediaQuery('(min-width: 1300px)');

  const { pathname } = useLocation();
  useEffect(() => {
    let items;

    items = [
      { id: 0, component: PaymentContainer, title: 'Кошелек', link: '/wallet', props: { className: styles.Icon } },
      {
        id: 1,
        component: PartnersContainer,
        title: 'Партнеры',
        link: '/partners',
        props: { className: styles.Icon }
      },
      {
        id: 2,
        component: StakingIconContainer,
        title: 'Стейкинг',
        link: '/staking',
        props: { className: styles.Icon }
      },
      // {
      //   id: 3,
      //   component: SettingsIconContainer,
      //   title: 'Настройки',
      //   link: '/settings',
      //   props: { className: styles.Icon }
      // },
      /*{
        id: 4,
        component: DocumentsIconContainer,
        title: 'Документы',
        link: '/car-documents',
        props: {className: styles.Icon}
      },
      {
        id: 5,
        component: CarIconContainer,
        title: 'оплата авто',
        link: '/main',
        props: {className: styles.Icon}
      },*/
    ];
    setNavigation(items);
  }, [pathname]);

  const navigationElement = useMemo(() => navigation && navigation.map(item =>
    <NavLink to={item.link} key={item.id}
      activeClassName={styles['Link_active']}
      className={`${styles['Link_undecorated']} ${matches1300 ? '' : styles['Link_centered']}`}>
      <item.component {...item.props} />
      {matches1300 ? <li className={styles['Navbar-Item']}>
        {item.title}
      </li> : null}
    </NavLink>), [navigation, matches1300]);

  return <NavigationUI classes={styles}
    navigationElement={navigationElement}
  />;
}

export default NavigationContainer;
