import React, { memo } from "react";
import { useState } from "react";
import CopyIconContainer from "../../../../common/icons/CopyIcon/functional/CopyIconContainer";
import { useEffect } from "react";

const AccountUI = ({ classes, fullName, select }) => {
  
  const data = JSON.parse(localStorage.getItem("metamask-wallet"));
  const [account, setAccount] = useState(data ? data[0] : "Подключить кошелек");

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.request({ method: "eth_accounts" }).then((res) => {
        if (!res.length) {
          localStorage.removeItem("metamask-wallet");
          setAccount("Подключить кошелек");
        }
      });
    }
  }, []);

  const getAccount = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      localStorage.setItem("metamask-wallet", JSON.stringify(accounts));

      const account = accounts[0];
      setAccount(account);
    } else {
      window.open(
        "https://metamask.io/download.html",
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className={classes.Account}>
      <span className={classes["Account-FullName"]} onClick={getAccount}>
        {account}
      </span>
      <div onClick={() => navigator.clipboard.writeText(account)}>
        <CopyIconContainer />
      </div>
      {/* {select} */}
    </div>
  );
};

export default memo(AccountUI);
