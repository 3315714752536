import React, {memo} from "react";
import PaginationContainer from "../../../../common/Pagination/functional/PaginationContainer";

const OperationListUI = ({classes, tableData, toFixed, tableHeads, inLastPage,
                           currentPage, address, getPage, getNormalDate}) => {
  return <div className={classes.OperationList}>
    <div className={classes['OperationList-Header']}>
      <span className={classes['OperationList-Title']}>Cписок операций:</span>
      {/*<div className={classes['OperationList-Action']}>*/}
      {/*  <DownloadIconContainer className={classes['OperationList-Icon']}/>*/}
      {/*  <span className={classes['OperationList-ActionTitle']}>Скачать</span>*/}
      {/*</div>*/}
    </div>
    {(tableData && tableHeads && address) && <div className={classes['Table']}>
      <div className={classes['Table-Row']}>
        {tableHeads.map(tableHead =>
          <span key={tableHead.id} className={classes['Table-Head']}>{tableHead.value}</span>)}
      </div>
      {!tableData.length && <span className={classes['Table-Data']}>Список операций пока пуст.</span>}
      {tableData.map(tData => <div key={tData.blockNumber} className={classes['Table-Row']}>
        <span className={classes['Table-Data']}>{getNormalDate(tData.timeStamp)}</span>
        <span className={`${classes['Table-Data']} 
        ${tData.from.toLowerCase() === address.toLowerCase() ? classes['Table-Data_red'] : classes['Table-Data_green']}`}>
          {tData.from.toLowerCase() === address.toLowerCase() ? 'Вывод' : 'Пополнение'}</span>
        <span className={classes['Table-Data']}>{tData.tokenSymbol}</span>
        <span className={classes['Table-Data']}>{toFixed(tData.value / 10 ** tData.tokenDecimal)}</span>
      </div>)}
    </div>}
    {currentPage === 1 && !tableData.length ? null : <PaginationContainer getPage={getPage}
                                                                   currentPage={currentPage}
                                                                   inLastPage={inLastPage}
                                                                   canReceiveNextPage={Boolean(tableData.length)}
                                                                   className={classes.Pagination}/> }
  </div>;
}

export default memo(OperationListUI);
