import React, { memo } from "react";

import BalanceInformationListContainer
    from "../functional/BalanceInformationList/functional/BalanceInformationListContainer";
//import HeaderContainer from "../functional/Header/functional/HeaderContainer";

const StaticCryptoUI = ({ classes }) => {
    return <div className={classes.StaticCrypto}>
        {/* <HeaderContainer /> */}
        <BalanceInformationListContainer />
    </div>
}

export default memo(StaticCryptoUI);
