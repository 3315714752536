import React, {useCallback, useEffect, useMemo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import CarPriceFormUI from "../ui/CarPriceFormUI";

import {createCar, createCarOrderPayment} from "../../../../../../../redux/user/userActions";
import {
  getCarOrderPriceSelector,
  getWdxPriceSelector
} from "../../../../../../../redux/user/userSelectors";
import withWdxCalculation from "../../../../../../../helpers/hoc/withWdxCalculation";
import withToFixed from "../../../../../../../helpers/hoc/withToFixed";
import useWdxBalance from "../../../../../../common/hooks/useWdxBalance";

import styles from "./CarPriceForm.module.css";

const CarPriceFormContainer = ({currentStep, currencyInWdx, toFixed}) => {
  const [price, setPrice] = useState('');
  const [disabled, setDisabled] = useState(false);

  const carOrderPrice = useSelector(getCarOrderPriceSelector);
  const wdxPrice = useSelector(getWdxPriceSelector);

  const balanceInWdx = useWdxBalance();

  const dispatch = useDispatch();

  const priceInWdx = useMemo(() => currencyInWdx(price, wdxPrice), [price, wdxPrice, currencyInWdx]);

  const style = useMemo(() => {
    if (balanceInWdx === 0) {
      return {
        width: '100%',
        backgroundColor: '#84C27A'
      }
    }

    let percentage = balanceInWdx * 100 / carOrderPrice;
    let colorStyle = {};

    if (balanceInWdx - carOrderPrice >= 0) {
      colorStyle.backgroundColor = '#84C27A';
    }

    return {
      width: `${percentage > 100 ? 100 : percentage}%`,
      ...colorStyle,
    }
  }, [balanceInWdx, carOrderPrice]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setDisabled(true);
    if (currentStep === 3) {
      dispatch(createCarOrderPayment(price / wdxPrice))
        .then(() => setDisabled(false), () => setDisabled(false));
    } else if (currentStep === 2) {
      dispatch(createCar(price))
        .then(() => setDisabled(false), () => setDisabled(false));
    }
  }, [price, dispatch, currentStep, wdxPrice]);

  const handlePriceChange = useCallback((e) => {
    setPrice(e.target.value);
  }, []);

  useEffect(() => {
    if (carOrderPrice) {
      setPrice(toFixed((+carOrderPrice).toFixed(6)));
    }
  }, [carOrderPrice, toFixed]);


  if (!wdxPrice)
    return null;

  return <CarPriceFormUI
    currentStep={currentStep}
    handleSubmit={handleSubmit}
    handlePriceChange={handlePriceChange}
    price={price}
    style={style}
    balanceDividedWdxPrice={balanceInWdx}
    carOrderPrice={carOrderPrice}
    priceInWdx={priceInWdx}
    disabled={disabled}
    toFixed={toFixed}
    classes={styles}/>;
}

export default withWdxCalculation(withToFixed(CarPriceFormContainer)(6));
