import React, {useCallback, useMemo, useState} from "react";
 
import {useDispatch} from "react-redux";
 
import WithdrawBalanceUI from "../ui/WithdrawBalanceUI";
 
import {withdrawBalance} from "../../../../../../../redux/user/userActions";
 
import styles from "./WithdrawBalance.module.css";
 
const WithdrawBalanceContainer = () => {
  const [sum, setSum] = useState('');
  const [currency, setCurrency] = useState('eth');
  const [wallet, setWallet] = useState('');
  const [startSubmission, setStartSubmission] = useState(false);
  // const [disabledSum, setDisabledSum] = useState(true);
  // const [disabledWallet, setDisabledWallet] = useState(true);
  // const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
 
  const options = useMemo(() => [
    {value: 'eth', label: 'ETH',},
    {value: 'wdx', label: 'WI',},
    {value: 'usdt', label: 'USDT',},
  ], []);
 
  const customStyle = useMemo(() => ({
    menu: {
      marginTop: 'calc(51px + 1.563vw)',
    },
    menuList: {
      padding: 0,
    }
  }), []);
 
  const handleSumChange = useCallback((e) => {
    const {value} = e.target;
    const last = value[value.length - 1];
    if (last === '.') {
      if (value.indexOf('.') === value.length - 1) {
        setSum(value);
      } else {
        return null;
      }
    }
    // value === '' ? setDisabledSum(true) : setDisabledSum(false);
    // (disabledSum === false && disabledWallet === false) ? setDisabled(true) : setDisabled(false);
    
    const numberRegexp = new RegExp(`^[-+]?\\d+(\\.\\d+)?$`);
    if (numberRegexp.test(e.target.value) || e.target.value === '') {
      setSum(e.target.value);
    }
  }, []);
 
  const handleSelectChange = useCallback((e) => {
    setCurrency(e.value);
  }, []);
 
  const handleWalletChange = useCallback((e) => {
    setWallet(e.target.value);
    // e.target.value === '' ? setDisabledWallet(true) : setDisabledWallet(false);
    // (disabledSum === false && disabledWallet === false) ? setDisabled(true) : setDisabled(false);
  }, []);
 
  // const handleSubmit = useCallback(() => {
  //   setDisabled(true);
  //   if (sum === '' || sum === '0' || wallet === '') {
  //     return null;
  //   }
 
  //   dispatch(withdrawBalance({
  //     sum, currency, wallet
  //   })).then(() => setDisabled(false), () => setDisabled(false));
  // }, [sum, currency, wallet, dispatch]);
  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);
 
  const handleSent= useCallback((e) => {
    e.preventDefault();
    if (sum === '' || sum === '0' || wallet === '') {
          return null;
        }
        dispatch(withdrawBalance({sum, currency, wallet, handleClose:handleClose}))
  }, [sum, currency, wallet, handleClose, dispatch]);
 
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    // if(disabledSum == true && disabledWallet == true){
    //   setDisabled(false);
    //   setStartSubmission(true);
    // }
    setStartSubmission(true);
  }, []);

  return <WithdrawBalanceUI
    sum={sum}
    handleWalletChange={handleWalletChange}
    handleSumChange={handleSumChange}
    customStyle={customStyle}
    wallet={wallet}
    handleSelectChange={handleSelectChange}
    classes={styles}
    handleSubmit={handleSubmit}
    options={options}
    currency={currency}
    handleClose={handleClose}
    handleSent={handleSent}
    startSubmission={startSubmission}
    // disabled= {disabled}
    />;
}
 
export default WithdrawBalanceContainer;