import React from "react";

import TransactionCheckUI from "../ui/TransactionCheckUI";

import styles from "./TransactionCheck.module.css";

const TransactionCheckContainer = (props) => {
  return <TransactionCheckUI classes={styles} {...props}/>;
}

export default TransactionCheckContainer;
