import React, { memo } from "react";

import ButtonContainer from "../../common/Button/functional/ButtonContainer";
import NegativeButtonContainer from "../../common/NegativeButton/functional/NegativeButtonContainer";

const InfoModalUI = ({ classes, handleClose, selected, handleSubmit, title, btnText, descFirst, descSecond, currency, convert, convertCurrency, }) => {
  return <div className={classes['Status-Modal--Container']}>
    <div className={classes['Status-Modal']}>
      <span className={classes['Status-Title']}>{title}</span>
      <span className={classes['Status-Description']}>{descFirst} {selected} {currency} {descSecond} {convert} {convertCurrency}?</span>
      <div className={classes['Status-Actions']}>
        <ButtonContainer text={btnText}
          onClick={handleSubmit}
          className={classes['Status-Action']} />
        <NegativeButtonContainer text="Отмена"
          onClick={handleClose}
          className={classes['Status-Action']} />
      </div>
    </div>
  </div>
}

export default memo(InfoModalUI);