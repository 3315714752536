import React, {useMemo} from "react";

import BalanceInformationUI from "../ui/BalanceInformationUI";

import withToFixed from "../../../../../helpers/hoc/withToFixed";

import styles from './BalanceInformation.module.css';

const BalanceInformationContainer = ({balance_wdx: balance, wdxPrice, usdtPrice, toFixed}) => {

  const fixedBalance = useMemo(() => toFixed(balance), [toFixed, balance]);

  const fixedWdxPrice = useMemo(() => toFixed(wdxPrice), [toFixed, wdxPrice]);

  const fixedUSDTPrice = useMemo(() => toFixed(usdtPrice), [toFixed, usdtPrice])

  return <BalanceInformationUI classes={styles}
                               balance={fixedBalance}
                               usdtPrice={fixedUSDTPrice}
                               wdxPrice={fixedWdxPrice}/>;
}

export default withToFixed(BalanceInformationContainer)(4);
