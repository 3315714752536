import React from "react";

import { Switch, Route } from "react-router-dom";

import HeaderContainer from "../../../Register/functional/Header/functional/HeaderContainer";
//import RegisterContainer from "../../../Register/functional/RegisterContainer";
//import LoginContainer from "../../../Login/functional/LoginContainer";
import PasswordRecoveryContainer from "../../../PasswordRecovery/functional/PasswordRecoveryContainer";
import ChangePasswordContainer from "../../../ChangePassword/functional/ChangePasswordContainer";
import ConfirmEmailContainer from "../../../ConfirmEmail/functional/ConfirmEmailContainer";
import LoginExchangeContainer from "../../../LoginExchange/functional/LoginExchange";

import styles from "./AuthenticationRouter.module.css";
const AuthenticationRouter = () => {
  return <div className={styles.AuthenticationRouter}>
    <HeaderContainer />
    <Switch>
      {/* <Route path="/register" component={RegisterContainer} /> */}
      {/* <Route exact path="/login" component={LoginContainer} /> */}
      <Route exact path="/password-recovery" component={PasswordRecoveryContainer} />
      <Route exact path="/password-recovery/:token" component={ChangePasswordContainer} />
      <Route exact path="/user/confirm/:token" component={ConfirmEmailContainer} />
      <Route exact path="/login-exchange/:token" component={LoginExchangeContainer} />
    </Switch>
  </div>;
}

export default AuthenticationRouter;
