import {useCallback} from "react";

const useToFixed = (precision) => {
  return useCallback((number = 0) => {
    let copy = Number(number)?.toFixed(precision);
    if (copy.includes('.')) {
      for (let i = copy.length - 1; i >= 0; i--) {
        if (copy[i] === '.') {
          return copy.slice(0, copy.length - 1);
        }
        if (copy[i] !== '0') {
          return copy;
        } else {
          copy = copy.slice(0, i) + copy.slice(i + 1);
        }
      }
    } else {
      return copy;
    }
  }, [precision]);
}


export default useToFixed;
