import {loginFormConstants} from "./loginFormConstants";

const initialState = {
  errors: {},
  fields: {
    email: {
      value: '',
    },
    password: {
      value: '',
      seePassword: false,
    }
  },
  button: {
    disabled: false,
  }
};

const loginFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginFormConstants.LOGIN_FORM_SET_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...error,
        }
      }
    }
    case loginFormConstants.LOGIN_FORM_RESET_ERROR: {
      const filteredErrors = { ...state.errors };
      delete filteredErrors[action.payload.field];
      return {
        ...state,
        errors: {
          ...filteredErrors,
        }
      }
    }
    case loginFormConstants.LOGIN_FORM_SET_EMAIL: {
      const { value } = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          email: {
            ...state.fields.email,
            value
          }
        }
      }
    }
    case loginFormConstants.LOGIN_FORM_SET_PASSWORD: {
      const { value } = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          password: {
            ...state.fields.password,
            value ,
          }
        }
      };
    }
    case loginFormConstants.LOGIN_FORM_VIEW_PASSWORD: {
      const canSee = !state.fields.password.seePassword;
      return {
        ...state,
        fields: {
          ...state.fields,
          password: {
            ...state.fields.password,
            seePassword: canSee,
          }
        }
      }
    }
    case loginFormConstants.LOGIN_FORM_DISABLE: {
      return {
        ...state,
        button: {
          disabled: true,
        }
      }
    }
    case loginFormConstants.LOGIN_FORM_ENABLE: {
      return {
        ...state,
        button: {
          disabled: false,
        }
      }
    }
    default:
      return state;
  }
}

export default loginFormReducer;
