import React from "react";
import {Column, Table} from 'react-virtualized';


const LevelDependentReferralInfoUI = ({tableRowStyle, tableData, tableDataStyle, tableHeadStyle, tableStyle, classes}) => {
  if (!tableData) {
    return null;
  }

  return <Table
    width={3200}
    headerStyle={tableHeadStyle}
    style={tableStyle}
    height={475}
    headerHeight={55}
    rowHeight={55}
    rowCount={tableData.length}
    noRowsRenderer={() => <span className={classes['No-Referral']}>Список партнеров пока пуст.</span>}
    rowStyle={tableRowStyle}
    rowClassName={classes['Row']}
    gridStyle={{width: '100%'}}
    rowGetter={({index}) => tableData[index]}
  >
    <Column dataKey="id"
            label='ID:'
            style={tableDataStyle}
            width={100}/>
    <Column dataKey="telegram_id"
            label="Телеграм:"
            style={tableDataStyle}
            width={100}/>
    <Column dataKey="username"
            label="Почта:"
            style={tableDataStyle}
            width={100}/>
    <Column dataKey="ref_profit"
            label="Профит:"
            style={tableDataStyle}
            width={100}/>
    <Column dataKey="ref_staking_amount"
            label="Сумма стейкинга:"
            style={tableDataStyle}
            width={100}/>
  </Table>;
}

export default LevelDependentReferralInfoUI;
