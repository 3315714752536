import React, {memo} from "react";

import StakingInfoContainer from "../functional/StakingInfo/functional/StakingInfoContainer";
import WithdrawContainer from "../functional/Withdraw/functional/WithdrawContainer";
import StatusContainer from "../functional/Status/functional/StatusContainer";

const StakingUI = ({classes}) => {
  return <div className={classes['Staking']}>
    <StakingInfoContainer />
    <div className={classes['Staking-Right']}>
      <WithdrawContainer />
      <StatusContainer />
    </div>
  </div>
}

export default memo(StakingUI);
