import React, {memo} from "react";

import QRCode from 'qrcode.react';

import RenderLineContainer from "../../../../../../../../common/RenderLine/functional/RenderLineContainer";
import RenderInputContainer from "../../../../../../../../common/RenderInput/functional/RenderInputContainer";
import ButtonContainer from "../../../../../../../../common/Button/functional/ButtonContainer";
import RenderErrorMessageContainer
  from "../../../../../../../../common/RenderErrorMessage/functional/RenderErrorMessageContainer";
import RenderExternalLinkContainer
  from "../../../../../../../../common/RenderExternalLink/functional/RenderExternalLinkContainer";

const EnableFAUI = ({classes, fa, activated, authCode, handleCodeChange, handleSubmit, error, size}) => {
  return <div className={classes['EnableFA']}>
    {!activated ? <><span className={classes['EnableFA-Title']}>Двухфакторная аутентификация</span>
      <span className={classes['EnableFA-Instructions']}>1. Установие аутентификацию Google
      (<RenderExternalLinkContainer
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          text="установить здесь"
          className={classes['Instruction-Link']}/>),
      или расширение <RenderExternalLinkContainer
          href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en-US"
          className={classes['Instruction-Link']}
          text="Chrome"/>,
      или дополнения <RenderExternalLinkContainer
          href="https://addons.mozilla.org/en-US/firefox/addon/auth-helper/"
          className={classes['Instruction-Link']}
          text="Firefox Add-ons"/>,
      или <RenderExternalLinkContainer text="Microsoft (Edge)"
                                       href="https://www.microsoft.com/en-bd/p/authenticator-extension/9p0fd39wffmk?ocid=badge&rtc=1#activetab=pivot:overviewtab"
                                       className={classes['Instruction-Link']}/>
        </span>
      <RenderLineContainer/>
      <div className={classes['QR']}>
        <QRCode value={fa.url} size={size}/>
        <div className={classes['QR-Instructions']}>
      <span
        className={classes['Instruction-Step']}>2. Отсканируйте этот QR-код в приложении Google Authenticator</span>
          <span className={classes['Instruction-Code']}>{fa['2fa_code']}</span>
        </div>
      </div>
    </> : null}
    <form className={classes['Form']} onSubmit={handleSubmit}>
      {error && <RenderErrorMessageContainer message={error}/>}
      <label className={classes['Form-Label']}>
        <span className={classes['Form-Label-Text']}>Auth Code</span>
        <RenderInputContainer placeholder="123 456"
                              className={classes['Form-Label-Input']}
                              value={authCode}
                              hasError={error}
                              onChange={handleCodeChange}
        />
      </label>
      <ButtonContainer text={activated ? 'Отключить' : 'включить'} className={classes['Form-Submit']}/>
    </form>
  </div>;
}

export default memo(EnableFAUI);
