import React from "react";

import RenderInputContainer from "../../../../../../../../../../common/RenderInput/functional/RenderInputContainer";
import ButtonContainer from "../../../../../../../../../../common/Button/functional/ButtonContainer";
import RenderErrorMessageContainer
  from "../../../../../../../../../../common/RenderErrorMessage/functional/RenderErrorMessageContainer";

const TwoFAVerificationUI = ({classes, handleSubmit, error, handleCodeChange, code}) => {
  return <form className={classes['TwoFAVerification-Form']} onSubmit={handleSubmit}>
    <label className={classes['Form-Label']}>
      <span className={classes['Form-Label-Text']}>Auth Code</span>
      <RenderInputContainer className={classes['Form-Label-Input']}
                            value={code}
                            onChange={handleCodeChange}/>
    </label>
    {error.global && <RenderErrorMessageContainer message={error.global}/>}
    <ButtonContainer text="Изменить" className={classes['Form-Submit']}/>
  </form>
}

export default TwoFAVerificationUI;
