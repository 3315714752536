import React, {memo, useCallback, useState} from "react";

import RenderInputLikeUI from "../ui/RenderInputLikeUI";

import styles from "./RenderInputLike.module.css";

const RenderInputLikeContainer = ({inputRef, ...rest}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  const handleLabelClick = useCallback(() => {
    inputRef.current.focus();
  }, [inputRef]);
  const handleKeyPress = useCallback((e) => {
    if (e.target.textContent.length >= 15) {
      e.preventDefault();
    }
  }, []);

  return <RenderInputLikeUI classes={styles}
                            focused={focused}
                            inputRef={inputRef}
                            handleFocus={handleFocus}
                            handleBlur={handleBlur}
                            handleLabelClick={handleLabelClick}
                            handleKeyPress={handleKeyPress}
                            {...rest}
  />;
};

export default memo(RenderInputLikeContainer);
