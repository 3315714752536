import React from "react";
import ModelRowUI from "../ui/ModelRowUI";

import styles from "./ModelRow.module.css";

const ModelRowContainer = (props) => {
  return <ModelRowUI classes={styles} {...props}/>;
}

export default ModelRowContainer;
