import React, {memo} from "react";

import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import InputWithCloseIconContainer
  from "../../../../../../common/InputWithCloseIcon/functional/InputWithCloseIconContainer";
import DiagramSliderContainer from "../../../../../../common/DiagramSlider/functional/DiagramSliderContainer";

const CarPriceFormUI = ({classes, handleSubmit, carOrderPrice, currentStep, price, priceInWdx,
                          toFixed, disabled,
                          balanceDividedWdxPrice, handlePriceChange}) => {
  return <div className={classes.PriceForm}>
    <span className={classes['PriceForm-Title']}>
      {currentStep === 2 ? 'Введите стоимость автомобиля' : 'Введите стоимость автомобиля'}
    </span>
    {currentStep === 2 &&
    <span className={classes['PriceForm-Description']}>Вы сможете оплатить авто после подтверждения менеджера</span>}
    <form className={classes['Form']} onSubmit={handleSubmit}>
      <label htmlFor="cost" className={classes['PriceForm-Label']}>
        <span className={classes['Label-Text']}>Введите сумму в рублях</span>
        <InputWithCloseIconContainer
          inputClassName={classes['PriceForm-Input']}
          value={price}
          onChange={handlePriceChange}/>
      </label>
      {currentStep === 3 && <label htmlFor="sumInWI" className={`${classes['PriceForm-Label']} 
        ${classes['PriceForm-Label_withPadding']}`}>
        <span className={classes['Label-Text']}>Ваша сумма в WI</span>
        <InputWithCloseIconContainer
          disabled
          readOnly
          value={` = ${toFixed(priceInWdx)}`}
          inputClassName={classes['PriceForm-Input']}/>
      </label>}
      <ButtonContainer disabled={disabled} className={classes['PriceForm-Submit']} text="отправить"/>
    </form>
    {currentStep === 3 && <DiagramSliderContainer needCollect={carOrderPrice} collected={balanceDividedWdxPrice}/>}
  </div>
}
export default memo(CarPriceFormUI);
