import React, {memo} from "react";

const CloseIconRoundedUI = ({strokeColor, handleMouseEnter,
                              handleMouseLeave, onClick = null, ...rest}) => {

  return <div {...rest} onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M20.5 11C20.5 16.2467 16.2467 20.5 11 20.5C5.75329 20.5 1.5 16.2467 1.5 11C1.5 5.7533 5.75329 1.5 11 1.5C16.2467 1.5 20.5 5.75329 20.5 11Z"
            stroke={`#${strokeColor}`}/>
      <path d="M7 7L15 15M15 7L7 15" stroke={`#${strokeColor}`}/>
    </svg>
  </div>
}

export default memo(CloseIconRoundedUI);
