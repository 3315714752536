import React, {memo} from "react";
 
import RenderTextsSpaceBetweenContainer
  from "../../RenderTextsSpaceBetween/functional/RenderTextsSpaceBetweenContainer";
import QuestionMarkContainer from "../../QuestionMark/functional/QuestionMarkContainer";
import StatusInfoContainer from "../functional/StatusInfo/functional/StatusInfoContainer";
import RenderInputLikeContainer from "../../../../../../common/RenderInputLike/functional/RenderInputLikeContainer";
import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";
import {modalDescription} from "../../../../../../InfoModal/constans/modalDescription";

const WithdrawUI = ({
                      classes, open, inputRef, handleMouseEnter, 
                      handleClick, handleClose, handleSubmit, staking, 
                      startSubmission, handleSent,
                    }) => {
  return <div className={classes['Withdraw']}>
    <span className={classes['Withdraw-Title']}>Вывод</span>
    <RenderTextsSpaceBetweenContainer
      leftValue="выведено в текущем периоде"
      rightValue={`${staking.withdrawn.wdx} WI`}
      additionalComponent={<span>&nbsp;(${staking.withdrawn.usd})</span>}
    />
    <RenderTextsSpaceBetweenContainer
      leftValue="Лимит на вывод"
      rightValue={`${staking.withdrawal_limit.wdx} WI`}
      additionalComponent={<span>&nbsp;(${staking.withdrawal_limit.usd})</span>}
    />
    <div className={classes['Wrapper']}>
      <RenderTextsSpaceBetweenContainer
        leftValue="Обновления периода"
        rightValue={`${staking.time_to_period_update}`}
        className={classes['Withdraw-Text']}
        additionalComponent={<QuestionMarkContainer onMouseEnter={handleMouseEnter}
                                                    onClick={handleClick  }/>}
      />
      {open && <StatusInfoContainer handleClose={handleClick}/>}
    </div>
    <form className={classes['Form']} 
    // onSubmit={handleSubmit}
    >
      <RenderInputLikeContainer inputRef={inputRef}/>
      <ButtonContainer onClick={handleSubmit} text="Вывести" className={classes['Form-Submit']}/>
    </form>
    {startSubmission && <InfoModalContainer handleClose={handleClose}
                                            selected={inputRef.current.innerText}
                                            title='Стейкинг'
                                            btnText='Вывести'
                                            descFirst={modalDescription.stackingOutput}
                                            currency='WI'
                                            handleSubmit={handleSent}
                                            />}
  </div>;
}
 
export default memo(WithdrawUI);