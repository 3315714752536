import React, {useCallback, useEffect, useMemo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import AdvancePaymentUI from "../ui/AdvancePaymentUI";

import useWdxBalance from "../../../../../../common/hooks/useWdxBalance";
import withWdxCalculation from "../../../../../../../helpers/hoc/withWdxCalculation";
import withToFixed from "../../../../../../../helpers/hoc/withToFixed";
import {getPrepaymentAmountSelector, getWdxPriceSelector} from "../../../../../../../redux/user/userSelectors";
import {createCarOrderPayment} from "../../../../../../../redux/user/userActions";
import {getPrepaymentAmount} from "../../../../../../../redux/global-settings/globalSettingsActions";
import {getMaxPrepaymentAmountSelector} from "../../../../../../../redux/global-settings/globalSettingsSelectors";

import styles from "./AdvancePayment.module.css";

const AdvancePaymentContainer = ({currencyInWdx, toFixed}) => {
  const [price, setPrice] = useState('');

  const balanceInWdx = useWdxBalance();

  const wdxPrice = useSelector(getWdxPriceSelector);
  const prepaymentAmount = useSelector(getPrepaymentAmountSelector);
  const maxPrePaymentAmount = useSelector(getMaxPrepaymentAmountSelector);

  const dispatch = useDispatch();

  const priceInWdxPrice = useMemo(() => toFixed(currencyInWdx(price, wdxPrice)), [price, wdxPrice, currencyInWdx, toFixed])

  const handlePriceChange = useCallback((e) => {
    setPrice(e.target.value);
  }, []);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    dispatch(createCarOrderPayment(priceInWdxPrice));
  }, [priceInWdxPrice, dispatch]);

  useEffect(() => {
    setPrice(prepaymentAmount);
  }, [prepaymentAmount]);
  useEffect(() => {
    dispatch(getPrepaymentAmount());
  }, [dispatch]);

  return <AdvancePaymentUI classes={styles}
                           price={price}
                           handleSubmit={handleSubmit}
                           balanceInWdx={balanceInWdx}
                           maxPrePaymentAmount={maxPrePaymentAmount}
                           priceInWdxPrice={priceInWdxPrice}
                           handlePriceChange={handlePriceChange}/>;
}

export default withToFixed(withWdxCalculation(AdvancePaymentContainer))(6);
