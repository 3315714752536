import React, {memo} from "react";

import CurrentRateInfoUI from "../ui/CurrentRateInfoUI";

import styles from "./CurrentRateInfo.module.css";

const CurrentRateInfoContainer = (props) => {
  return <CurrentRateInfoUI classes={styles} {...props}/>;
}

export default memo(CurrentRateInfoContainer);
