import React, {memo} from "react";

const PartnersUI = (props) => {
    return <div {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="21" viewBox="0 0 29 21" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.707 15.663C12.691 16.9858 12.3449 18.5997 12.3449 19.65C12.3449 20.2851 11.83 20.8 11.1949 20.8C10.5598 20.8 10.0449 20.2851 10.0449 19.65C10.0449 18.2003 10.4988 16.0642 11.8829 14.262C13.3082 12.4061 15.6504 11 19.1949 11C22.7394 11 25.0816 12.4061 26.507 14.262C27.891 16.0642 28.3449 18.2003 28.3449 19.65C28.3449 20.2851 27.83 20.8 27.1949 20.8C26.5598 20.8 26.0449 20.2851 26.0449 19.65C26.0449 18.5997 25.6988 16.9858 24.6829 15.663C23.7082 14.3939 22.0504 13.3 19.1949 13.3C16.3394 13.3 14.6816 14.3939 13.707 15.663Z" fill="#4E5566" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1949 2.3C17.6209 2.3 16.3449 3.57599 16.3449 5.15C16.3449 6.72401 17.6209 8 19.1949 8C20.7689 8 22.0449 6.72401 22.0449 5.15C22.0449 3.57599 20.7689 2.3 19.1949 2.3ZM14.0449 5.15C14.0449 2.30573 16.3507 0 19.1949 0C22.0392 0 24.3449 2.30573 24.3449 5.15C24.3449 7.99427 22.0392 10.3 19.1949 10.3C16.3507 10.3 14.0449 7.99427 14.0449 5.15Z" fill="#4E5566" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.35 7.85V3H7.65V7.85H12V10.15H7.65V15H5.35V10.15H0.5V7.85H5.35Z" fill="#4E5566" />
        </svg>
    </div>;
}

export default memo(PartnersUI);
