import React, {memo} from "react";

const EyeIconUI = ({strokeColor, handleMouseEnter, handleMouseLeave, ...rest}) => {
  return <div {...rest} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
      <path d="M18.368 13.4523C20.7068 11.365 21.9993 9 21.9993 9C21.9993 9 17.8743 1.4375 10.9993 1.4375C9.67878 1.44205 8.37316 1.71721 7.16309 2.246L8.22184 3.30613C9.11206 2.98184 10.0519 2.81481 10.9993 2.8125C13.9143 2.8125 16.333 4.4185 18.1053 6.19088C18.9583 7.04845 19.723 7.98955 20.3878 9C20.3081 9.11963 20.2201 9.25163 20.1197 9.396C19.6591 10.056 18.9785 10.936 18.1053 11.8091C17.8785 12.036 17.642 12.2601 17.3945 12.4774L18.368 13.4523Z"
            fill={strokeColor}/>
      <path d="M15.5336 10.617C15.8405 9.75878 15.8973 8.83108 15.6975 7.94183C15.4977 7.05258 15.0495 6.23835 14.405 5.59388C13.7605 4.9494 12.9463 4.50119 12.057 4.30139C11.1678 4.10159 10.2401 4.15841 9.38187 4.46525L10.5135 5.59687C11.0419 5.52124 11.5807 5.56971 12.0871 5.73845C12.5936 5.90719 13.0538 6.19155 13.4312 6.56901C13.8087 6.94648 14.0931 7.40666 14.2618 7.9131C14.4305 8.41954 14.479 8.95832 14.4034 9.48675L15.5336 10.617ZM11.487 12.4031L12.6172 13.5334C11.759 13.8402 10.8313 13.897 9.94207 13.6972C9.05282 13.4974 8.23859 13.0492 7.59412 12.4047C6.94965 11.7603 6.50144 10.946 6.30163 10.0568C6.10183 9.16755 6.15866 8.23984 6.46549 7.38162L7.59712 8.51325C7.52148 9.04168 7.56995 9.58046 7.73869 10.0869C7.90743 10.5933 8.1918 11.0535 8.56926 11.431C8.94672 11.8084 9.4069 12.0928 9.91335 12.2615C10.4198 12.4303 10.9586 12.4788 11.487 12.4031Z"
            fill={strokeColor}/>
      <path d="M4.60625 5.52111C4.35875 5.74111 4.12087 5.96386 3.894 6.19073C3.04101 7.04831 2.27634 7.98941 1.6115 8.99986L1.87962 9.39586C2.34025 10.0559 3.02087 10.9359 3.894 11.809C5.66638 13.5814 8.08638 15.1874 11 15.1874C11.9845 15.1874 12.9113 15.0045 13.7775 14.6924L14.8363 15.7539C13.6262 16.2826 12.3206 16.5578 11 16.5624C4.125 16.5624 0 8.99986 0 8.99986C0 8.99986 1.29113 6.63348 3.63138 4.54761L4.60488 5.52248L4.60625 5.52111Z"
            fill={strokeColor}/>
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7637 17.7367L2.26367 1.23668L3.23717 0.263184L19.7372 16.7632L18.7637 17.7367Z"
            fill={strokeColor}/>
    </svg>
  </div>
}

export default memo(EyeIconUI);
