import React, {memo} from "react";

import RenderInputContainer from "../../RenderInput/functional/RenderInputContainer";
import CloseIconRoundedContainer from "../../icons/CloseIconRounded/functional/CloseIconRoundedContainer";

const InputWithCloseIconUI = ({
                                classes,
                                focused,
                                containerClassName = '',
                                inputClassName = '',
                                iconClassName = '',
                                handleFocus,
                                handleBlur,
                                handleCloseIconClick,
                                ...rest
                              }) => {
  return <div className={`${classes['InputWrapper']} ${containerClassName}`}>
    <RenderInputContainer name="cost"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className={inputClassName}
                          {...rest}/>
    {focused ? <CloseIconRoundedContainer
      className={`${classes['CloseIcon']} ${iconClassName}`}
      onClick={handleCloseIconClick}
    /> : null}
  </div>
}

export default memo(InputWithCloseIconUI);
