import React from "react";

const RenderTextsSpaceBetweenUI = ({classes, leftValue, rightValue,
                                     className = '', additionalComponent = null}) => {
  return <div className={`${classes['TextContent']} ${className}`}>
    <span className={classes['TextContent-Left']}>{leftValue}</span>
    <span className={classes['TextContent-Right']}>
      {rightValue}
      {additionalComponent}
    </span>
  </div>
}

export default RenderTextsSpaceBetweenUI;
