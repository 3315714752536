import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";

import PartnerInfoUI from "../ui/PartnerInfoUI";

import {
  getReferrerForInfoViewSelector,
} from "../../../../../../../redux/referral/referralSelectors";

import styles from "./PartnerInfo.module.css";

const PartnerInfoContainer = () => {
  const [tableHeads, setTableHeads] = useState([]);

  const partnerInfo = useSelector(getReferrerForInfoViewSelector);

  useEffect(() => {
    const items = ['ID:', 'Почта:', 'Телеграм:'];
    setTableHeads(items);
  }, []);

  if (!partnerInfo) {
    return null;
  }

  return <PartnerInfoUI
    tableHeads={tableHeads}
    partnerInfo={partnerInfo}
    classes={styles}/>;
}

export default PartnerInfoContainer;
