import React, {memo} from "react";

import CloseIconContainer from "../../../../CloseIcon/CloseIconContainer";

const StatusInfoUI = ({classes, handleClose}) => {
  return  <div className={classes['StatusInfo']}>
    <CloseIconContainer className={classes['Close-Icon']} onClick={handleClose}/>
    <span className={classes['StatusInfo-Title']}>Статус</span>
    <span className={classes['StatusInfo-Content']}>Для увеличения лимита вывода следует купить статус выше</span>
  </div>
}

export default memo(StatusInfoUI);
