import React, { memo } from "react";

const LogoUI = (props) => {

  const svgElement = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="100%" viewBox="0 0 1257 360" enableBackground="new 0 0 1257 360" space="preserve">
    <path fill="#F2F2F2" opacity="1.000000" stroke="none"
      d="
M900.000000,361.000000 
	C600.000061,361.000000 300.500122,361.000000 1.000121,361.000000 
	C1.000080,241.000076 1.000080,121.000153 1.000040,1.000169 
	C419.999847,1.000113 838.999695,1.000113 1257.999634,1.000056 
	C1257.999756,120.999825 1257.999756,240.999649 1257.999878,360.999756 
	C1138.833374,361.000000 1019.666687,361.000000 900.000000,361.000000 
M368.500000,304.000000 
	C386.611511,304.000000 404.723053,304.000000 423.042603,304.000000 
	C422.953827,302.050171 422.889343,300.633392 422.815002,298.999939 
	C421.001831,298.999939 419.480835,299.229370 418.052246,298.963165 
	C407.792084,297.051300 401.583008,294.239288 398.467896,283.868927 
	C397.405670,280.332764 397.065948,276.462708 397.059204,272.743988 
	C396.970276,223.756561 396.968079,174.768845 397.043854,125.781357 
	C397.052490,120.202553 397.635803,114.622749 398.003662,109.046783 
	C398.842987,96.323723 406.683655,92.282303 416.470703,90.005936 
	C418.626526,89.504517 420.799408,89.076714 423.094849,88.587204 
	C422.957581,86.901649 422.844574,85.514214 422.744751,84.288658 
	C396.247192,84.288658 370.159210,84.288658 344.128296,84.288658 
	C344.128296,85.887466 344.128296,87.196907 344.128296,88.542572 
	C346.076904,89.045532 347.895660,89.688293 349.768768,89.970848 
	C363.911133,92.104256 369.859131,100.014931 369.911469,113.330048 
	C370.124268,167.482178 370.033813,221.635712 369.917297,275.788422 
	C369.910187,279.091156 369.123260,282.540527 367.990570,285.664795 
	C364.549927,295.155121 356.789978,298.369324 347.463928,299.001617 
	C346.356812,299.076691 345.251251,299.174042 343.957001,299.275879 
	C344.033936,300.987213 344.096954,302.388367 344.169403,304.000000 
	C352.210724,304.000000 359.855377,304.000000 368.500000,304.000000 
M192.000000,228.503510 
	C200.956894,255.353043 209.913788,282.202576 219.300827,310.341553 
	C224.510147,294.606323 229.271606,280.322937 233.905518,265.998291 
	C234.206818,265.066895 233.642365,263.780060 233.286835,262.723755 
	C230.885193,255.588730 228.397934,248.482498 225.999985,241.346237 
	C215.380325,209.742279 204.710709,178.154831 194.210205,146.511292 
	C190.074234,134.047409 186.243042,121.479698 182.448883,108.906219 
	C180.549866,102.613068 183.228424,95.312691 188.973343,92.746780 
	C193.266144,90.829430 198.169418,90.278854 203.029907,89.062332 
	C202.954346,87.615868 202.863754,85.881859 202.779968,84.278229 
	C179.265503,84.278229 156.174332,84.278229 133.108093,84.278229 
	C133.108093,86.210907 133.108093,87.862968 133.108093,89.597595 
	C140.967026,91.472847 146.118179,96.927979 148.807129,103.775780 
	C155.651581,121.206177 161.841934,138.901947 167.959610,156.607086 
	C176.126160,180.241913 183.976654,203.985947 192.000000,228.503510 
M73.002068,106.221222 
	C73.694511,102.625374 73.210793,97.867561 75.350594,95.701599 
	C78.695908,92.315399 83.817055,90.683594 88.185875,88.308548 
	C88.468346,88.668732 88.750816,89.028915 89.033287,89.389099 
	C88.953400,87.655746 88.873512,85.922386 88.796547,84.252289 
	C66.319359,84.252289 44.234104,84.252289 22.207047,84.252289 
	C22.207047,86.290665 22.207047,88.074448 22.207047,89.625008 
	C33.804890,92.474197 39.680904,101.380905 43.714901,110.842720 
	C49.347649,124.054436 53.411247,137.943390 57.987968,151.595627 
	C75.353569,203.396835 92.651100,255.220856 110.008568,307.024780 
	C110.399811,308.192444 111.200912,309.222778 112.243065,311.092743 
	C116.130600,298.769775 119.730232,287.641449 123.128136,276.451874 
	C124.518143,271.874451 126.409431,267.763702 124.294952,262.262115 
	C118.898460,248.221176 114.698982,233.721069 109.993042,219.413727 
	C97.679451,181.977036 85.351128,144.545166 73.002068,106.221222 
M571.029297,126.182724 
	C569.525208,121.147194 565.779785,125.400909 563.557068,124.005936 
	C559.068665,137.131927 556.852722,150.627197 550.937561,162.908340 
	C547.845703,152.081238 545.392151,141.449478 543.009094,130.801956 
	C541.338989,123.340019 541.381958,123.330399 532.938843,124.447273 
	C537.500671,143.300934 542.057068,162.132324 546.581970,180.833389 
	C549.411621,180.833389 552.047363,180.833389 554.499817,180.833389 
	C558.206787,168.201157 561.854492,155.770737 565.780823,142.390686 
	C566.917358,143.229065 568.599365,143.802460 568.898987,144.793716 
	C572.054321,155.231796 575.188293,165.686172 577.860291,176.254349 
	C578.640076,179.338531 579.070251,181.171143 582.874573,181.132767 
	C586.427124,181.096939 587.863525,179.970352 588.076904,176.476196 
	C588.212219,174.260345 589.025940,172.083374 589.559021,169.894714 
	C593.266235,154.673920 596.979614,139.454636 600.716431,124.130692 
	C597.729614,124.130692 595.091003,124.130692 592.241455,124.130692 
	C589.285706,137.139084 586.347107,150.071823 583.408508,163.004562 
	C582.879456,163.137054 582.350403,163.269547 581.821350,163.402039 
	C577.864685,151.414688 574.984863,139.075714 571.029297,126.182724 
M588.516785,244.016479 
	C583.995667,237.157318 579.175415,230.471909 575.055664,223.379623 
	C572.176208,218.422607 568.618469,216.761246 563.239502,218.491348 
	C563.239502,237.231216 563.239502,255.973389 563.239502,274.760986 
	C565.889526,274.760986 568.294006,274.760986 571.011597,274.760986 
	C571.011597,261.753784 571.011597,248.980438 571.011597,236.207092 
	C571.367432,235.974777 571.723206,235.742447 572.079041,235.510117 
	C573.252747,236.721024 574.654053,237.782379 575.563782,239.166779 
	C582.102661,249.117172 588.838562,258.957733 594.892151,269.199463 
	C598.103394,274.632416 602.045837,276.121094 607.746155,274.593140 
	C607.746155,255.743240 607.746155,237.002975 607.746155,218.254868 
	C604.748901,218.254868 602.011597,218.254868 598.709839,218.254868 
	C598.709839,231.642975 598.709839,244.784378 598.709839,257.925781 
	C598.382446,258.009308 598.055054,258.092865 597.727661,258.176392 
	C594.807007,253.656967 591.886414,249.137558 588.516785,244.016479 
M662.000000,133.533646 
	C662.000000,149.281342 662.000000,165.029022 662.000000,180.999649 
	C670.862244,180.999649 679.228516,181.608017 687.433899,180.723923 
	C691.124512,180.326248 694.957214,177.459610 697.897827,174.798660 
	C702.057190,171.034836 701.900085,165.268387 700.700439,160.559738 
	C699.755615,156.851639 696.195618,153.809845 693.650818,150.268326 
	C698.301331,147.995667 700.404663,143.191208 699.899109,137.534180 
	C699.295593,130.781250 695.778259,125.719711 688.616455,125.015236 
	C679.853821,124.153313 671.016296,124.051941 662.000000,123.611382 
	C662.000000,127.250198 662.000000,129.897125 662.000000,133.533646 
M672.000000,249.500000 
	C672.000000,257.930786 672.000000,266.361603 672.000000,274.724182 
	C675.293396,274.724182 678.030457,274.724182 681.145264,274.724182 
	C681.145264,267.044617 681.145264,259.636047 681.145264,252.000336 
	C684.507568,252.000336 687.507690,251.763397 690.439148,252.108932 
	C691.737183,252.261932 693.357544,253.314896 694.037537,254.439438 
	C696.780151,258.975555 699.313721,263.647156 701.737305,268.364960 
	C705.613037,275.909760 705.550049,275.942108 714.307922,274.335999 
	C710.046814,266.511292 705.853821,258.811737 701.612854,251.024109 
	C702.278076,250.530716 702.610901,250.242599 702.981018,250.015427 
	C709.680054,245.904373 712.979004,239.906876 711.899414,232.121750 
	C710.856812,224.602936 705.523438,218.870331 697.938049,218.197708 
	C689.417114,217.442139 680.777588,218.023972 672.000000,218.023972 
	C672.000000,228.560043 672.000000,238.530014 672.000000,249.500000 
M1193.988647,225.863251 
	C1191.070679,221.710815 1187.353271,218.345169 1182.171143,218.105423 
	C1173.915405,217.723465 1165.629150,218.003372 1157.191895,218.003372 
	C1157.191895,237.155563 1157.191895,255.880554 1157.191895,274.802673 
	C1159.784546,274.802673 1162.186646,274.802673 1165.151123,274.802673 
	C1165.151123,267.114044 1165.151123,259.573273 1165.151123,252.106781 
	C1169.698730,252.106781 1173.684448,252.106781 1177.469360,252.106781 
	C1181.549561,259.778687 1185.541382,267.284576 1189.595215,274.907104 
	C1192.476562,274.907104 1195.463257,274.907104 1199.099731,274.907104 
	C1194.594727,266.686646 1190.231934,258.725433 1185.864380,250.755722 
	C1196.346069,246.004623 1198.451904,236.147217 1193.988647,225.863251 
M905.669128,252.183365 
	C908.409119,257.284241 911.131287,262.394836 913.893311,267.483765 
	C918.272339,275.552094 918.283630,275.545959 927.261902,274.613708 
	C922.775024,266.513367 918.379089,258.577240 913.984253,250.643005 
	C923.033264,245.821182 925.578125,240.557983 923.999084,230.031586 
	C923.178894,224.563721 917.562561,218.700806 910.953308,218.189346 
	C902.421814,217.529160 893.800659,218.028503 885.242126,218.028503 
	C885.242126,237.284561 885.242126,256.003418 885.242126,274.762390 
	C887.893188,274.762390 890.294983,274.762390 893.071960,274.762390 
	C893.071960,267.127686 893.071960,259.720886 893.071960,252.011703 
	C897.239136,252.011703 901.029541,252.011703 905.669128,252.183365 
M803.266846,276.000000 
	C805.163574,275.666992 807.071411,275.386627 808.955017,274.991028 
	C814.491333,273.828339 819.416382,268.963776 819.997253,264.144897 
	C821.010071,255.743301 818.657471,249.966217 812.394348,247.174805 
	C807.010071,244.775131 801.344116,243.007507 795.803284,240.958969 
	C791.705872,239.444107 788.169739,234.934875 788.660461,231.853806 
	C789.447876,226.910156 793.025391,224.088470 798.618042,224.000015 
	C806.792847,223.870697 809.292236,225.799774 812.626953,234.679535 
	C815.119324,234.679535 817.531616,234.679535 820.526855,234.679535 
	C819.920349,232.061340 819.827026,229.668884 818.840271,227.731552 
	C813.635376,217.512863 804.028198,215.734314 794.192871,217.382797 
	C788.254028,218.378204 783.307556,221.339554 781.024353,227.358398 
	C777.356323,237.028076 782.918152,243.921066 792.122437,247.907059 
	C796.355957,249.740463 801.281067,250.139496 805.265991,252.329193 
	C808.059326,253.864105 810.394531,257.036469 811.836914,260.009888 
	C813.418518,263.270264 810.560852,265.969055 808.058105,267.234528 
	C799.993652,271.312256 787.211548,269.415222 786.498901,258.206787 
	C783.715332,258.206787 780.932434,258.206787 777.836792,258.206787 
	C778.596130,263.679688 780.270386,268.125916 784.826660,271.493744 
	C790.168823,275.442352 796.061340,276.235870 803.266846,276.000000 
M646.291016,130.999786 
	C651.794617,130.435577 647.672119,126.527374 649.263916,124.212555 
	C636.518005,124.212555 624.442932,124.212555 612.214600,124.212555 
	C612.214600,143.162384 612.214600,161.899246 612.214600,180.786606 
	C624.506531,180.786606 636.583313,180.786606 648.793884,180.786606 
	C648.793884,178.500671 648.793884,176.431625 648.793884,173.967712 
	C639.515503,173.967712 630.432617,173.967712 621.219116,173.967712 
	C621.219116,167.496078 621.219116,161.424759 621.219116,154.959564 
	C629.179565,154.959564 636.931702,154.959564 644.788330,154.959564 
	C644.788330,152.491730 644.788330,150.422440 644.788330,147.957855 
	C636.838074,147.957855 629.087341,147.957855 621.224304,147.957855 
	C621.224304,142.151733 621.224304,136.747025 621.224304,131.000000 
	C629.404724,131.000000 637.365845,131.000000 646.291016,130.999786 
M1118.760986,268.999939 
	C1111.220093,267.641449 1108.010864,263.951294 1108.003540,256.564209 
	C1107.990723,243.803360 1108.000000,231.042480 1108.000000,218.234756 
	C1105.114136,218.234756 1102.711792,218.234756 1100.000732,218.234756 
	C1100.000732,232.577988 1099.784546,246.694809 1100.178955,260.794586 
	C1100.260132,263.698303 1102.098755,266.963593 1104.027100,269.328674 
	C1110.048218,276.713562 1118.522095,277.243378 1126.877930,275.438019 
	C1134.188965,273.858368 1140.475220,269.396973 1140.833618,261.265350 
	C1141.463257,246.974518 1141.009521,232.635956 1141.009521,218.228195 
	C1138.795166,218.228195 1136.061157,218.228195 1133.000000,218.228195 
	C1133.000000,230.897400 1132.448608,243.374435 1133.191650,255.773880 
	C1133.695923,264.186676 1127.426880,270.001251 1118.760986,268.999939 
M762.961731,253.596909 
	C758.970520,243.145370 754.927185,232.713211 751.024048,222.228867 
	C750.029907,219.558411 748.996338,217.873367 745.568604,217.841644 
	C742.427063,217.812546 741.015747,219.181610 740.093689,221.622818 
	C733.438110,239.243881 726.829773,256.882812 720.208191,274.511383 
	C728.567627,275.817993 728.564026,275.816833 731.018188,268.203247 
	C731.312622,267.289703 731.788330,266.421112 731.980652,265.490448 
	C732.686340,262.075104 734.505432,260.637207 738.179504,260.918732 
	C742.644653,261.260895 747.185303,261.318329 751.639160,260.909576 
	C756.006226,260.508789 757.887878,262.130127 759.099609,266.297791 
	C761.816162,275.641144 762.050110,275.573120 770.879517,274.609161 
	C768.247986,267.826996 765.643127,261.113434 762.961731,253.596909 
M1242.364624,275.000000 
	C1243.814941,275.000000 1245.265259,275.000000 1246.769043,275.000000 
	C1246.769043,272.454803 1246.769043,270.387970 1246.769043,267.925171 
	C1237.133179,267.925171 1227.719849,267.925171 1218.239990,267.925171 
	C1218.239990,261.451660 1218.239990,255.380615 1218.239990,248.920685 
	C1226.556274,248.920685 1234.641235,248.920685 1242.767334,248.920685 
	C1242.767334,246.453217 1242.767334,244.383240 1242.767334,241.921768 
	C1234.463013,241.921768 1226.381592,241.921768 1218.241943,241.921768 
	C1218.241943,236.114044 1218.241943,230.709335 1218.241943,225.000000 
	C1225.944702,225.000000 1233.409546,225.002213 1240.874390,224.999329 
	C1247.429932,224.996811 1247.429932,224.994293 1246.524048,218.248779 
	C1234.449829,218.248779 1222.371460,218.248779 1210.248291,218.248779 
	C1210.248291,237.234039 1210.248291,255.969482 1210.248291,275.000000 
	C1220.792725,275.000000 1231.091431,275.000000 1242.364624,275.000000 
M945.000000,244.488785 
	C945.000000,235.717178 945.000000,226.945587 945.000000,218.289780 
	C942.018127,218.289780 939.610718,218.289780 937.000488,218.289780 
	C937.000488,231.889114 936.689392,245.210403 937.147522,258.505188 
	C937.341492,264.135345 939.504395,269.147369 944.927307,272.723877 
	C950.222351,276.215912 955.725647,276.300171 961.376038,275.922272 
	C971.233337,275.262909 978.134338,269.495880 978.753601,260.234070 
	C979.682617,246.339676 978.995239,232.337219 978.995239,218.192108 
	C976.203369,218.192108 973.799438,218.192108 971.000244,218.192108 
	C971.000244,219.162460 971.000244,219.957199 971.000244,220.751938 
	C971.000122,232.416931 971.083069,244.082718 970.970825,255.746643 
	C970.895569,263.567505 967.110352,268.495239 960.914001,268.972473 
	C953.032959,269.579468 946.861389,268.199677 945.962036,258.676544 
	C945.547485,254.287582 945.315002,249.881454 945.000000,244.488785 
M1026.125610,219.009903 
	C1024.317871,218.428970 1022.549133,217.598450 1020.695923,217.309357 
	C1011.606750,215.891464 1000.841919,218.165314 995.567383,228.646301 
	C991.470520,236.787231 991.532104,245.185257 992.201660,253.561157 
	C992.829529,261.415161 995.695190,268.770935 1003.264160,273.039124 
	C1014.860229,279.578156 1034.817749,277.067139 1036.620972,257.179047 
	C1033.819336,257.179047 1031.025879,257.179047 1028.232300,257.179047 
	C1028.564941,257.478271 1028.897583,257.777527 1029.230347,258.076752 
	C1027.074951,261.065338 1025.529541,264.991821 1022.648987,266.865082 
	C1014.301392,272.293610 1004.388062,268.896027 1002.375549,259.029266 
	C1000.720642,250.915710 1001.619446,242.148300 1002.357178,233.756058 
	C1002.596130,231.037918 1005.395447,227.806320 1007.908203,226.158813 
	C1017.128235,220.113678 1026.165771,224.950623 1028.510498,236.797180 
	C1031.295166,236.797180 1034.082886,236.797180 1037.054443,236.797180 
	C1035.845825,229.494675 1033.444702,223.194412 1026.125610,219.009903 
M281.500000,84.000000 
	C269.721283,84.000000 257.942535,84.000000 246.277145,84.000000 
	C246.277145,86.294998 246.277145,88.041359 246.277145,90.054787 
	C256.277893,89.432762 265.324707,91.598129 270.562897,100.363991 
	C275.711334,108.979675 272.656219,118.577499 270.899506,127.756638 
	C277.897583,128.158005 277.917938,128.164780 279.777405,122.582245 
	C280.891235,119.238403 281.663513,115.754967 283.050201,112.532608 
	C287.618103,101.917931 292.585388,91.580788 305.719543,89.614952 
	C305.719543,87.698029 305.719543,85.960846 305.719543,84.000000 
	C297.794495,84.000000 290.147247,84.000000 281.500000,84.000000 
M632.058044,242.269516 
	C632.058044,236.646561 632.058044,231.023605 632.058044,225.010208 
	C641.456848,225.010208 650.540588,225.010208 659.806519,225.010208 
	C659.806519,222.543701 659.806519,220.474762 659.806519,218.202698 
	C647.891113,218.202698 636.148071,218.202698 624.202454,218.202698 
	C624.202454,237.129852 624.202454,255.871216 624.202454,274.806335 
	C626.793762,274.806335 629.197754,274.806335 632.000305,274.806335 
	C632.000305,266.550385 632.000305,258.466614 632.000305,249.997345 
	C640.137329,249.997345 647.889282,249.997345 655.809570,249.997345 
	C655.809570,247.530609 655.809570,245.461685 655.809570,242.943863 
	C647.999451,242.943863 640.374146,242.943863 632.058044,242.269516 
M1063.000000,251.499985 
	C1063.000000,259.272888 1063.000000,267.045807 1063.000000,274.714417 
	C1065.974976,274.714417 1068.376831,274.714417 1071.173218,274.714417 
	C1071.173218,258.024384 1071.173218,241.616196 1071.173218,224.828033 
	C1077.323608,224.828033 1083.077759,224.828033 1088.714233,224.828033 
	C1088.714233,222.363297 1088.714233,220.293182 1088.714233,218.277496 
	C1073.679199,218.277496 1058.931641,218.277496 1044.282104,218.277496 
	C1044.282104,220.630371 1044.282104,222.700500 1044.282104,225.166779 
	C1050.636719,225.166779 1056.715698,225.166779 1063.000000,225.166779 
	C1063.000000,233.880096 1063.000000,242.190048 1063.000000,251.499985 
M872.999695,220.552994 
	C872.354248,219.702866 871.716370,218.117569 871.062256,218.110855 
	C857.145081,217.968063 843.226135,218.002625 829.214539,218.002625 
	C829.214539,220.520401 829.214539,222.586761 829.214539,225.033356 
	C835.157288,225.033356 840.899963,225.033356 847.149658,225.033356 
	C847.149658,241.921875 847.149658,258.432800 847.149658,274.656769 
	C850.084900,274.656769 852.490662,274.656769 855.238220,274.656769 
	C855.238220,257.936707 855.238220,241.558029 855.238220,224.999832 
	C860.407288,224.999832 865.226074,224.771576 870.010071,225.080948 
	C873.142273,225.283508 873.166138,223.656631 872.999695,220.552994 
M538.003357,219.740540 
	C538.003357,238.107330 538.003357,256.474121 538.003357,274.709961 
	C540.997009,274.709961 543.396179,274.709961 545.782227,274.709961 
	C545.782227,255.724930 545.782227,237.030731 545.782227,217.709366 
	C543.022644,218.092957 540.536316,218.438568 538.003357,219.740540 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M368.000000,304.000000 
	C359.855377,304.000000 352.210724,304.000000 344.169403,304.000000 
	C344.096954,302.388367 344.033936,300.987213 343.957001,299.275879 
	C345.251251,299.174042 346.356812,299.076691 347.463928,299.001617 
	C356.789978,298.369324 364.549927,295.155121 367.990570,285.664795 
	C369.123260,282.540527 369.910187,279.091156 369.917297,275.788422 
	C370.033813,221.635712 370.124268,167.482178 369.911469,113.330048 
	C369.859131,100.014931 363.911133,92.104256 349.768768,89.970848 
	C347.895660,89.688293 346.076904,89.045532 344.128296,88.542572 
	C344.128296,87.196907 344.128296,85.887466 344.128296,84.288658 
	C370.159210,84.288658 396.247192,84.288658 422.744751,84.288658 
	C422.844574,85.514214 422.957581,86.901649 423.094849,88.587204 
	C420.799408,89.076714 418.626526,89.504517 416.470703,90.005936 
	C406.683655,92.282303 398.842987,96.323723 398.003662,109.046783 
	C397.635803,114.622749 397.052490,120.202553 397.043854,125.781357 
	C396.968079,174.768845 396.970276,223.756561 397.059204,272.743988 
	C397.065948,276.462708 397.405670,280.332764 398.467896,283.868927 
	C401.583008,294.239288 407.792084,297.051300 418.052246,298.963165 
	C419.480835,299.229370 421.001831,298.999939 422.815002,298.999939 
	C422.889343,300.633392 422.953827,302.050171 423.042603,304.000000 
	C404.723053,304.000000 386.611511,304.000000 368.000000,304.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M191.981445,228.093292 
	C183.976654,203.985947 176.126160,180.241913 167.959610,156.607086 
	C161.841934,138.901947 155.651581,121.206177 148.807129,103.775780 
	C146.118179,96.927979 140.967026,91.472847 133.108093,89.597595 
	C133.108093,87.862968 133.108093,86.210907 133.108093,84.278229 
	C156.174332,84.278229 179.265503,84.278229 202.779968,84.278229 
	C202.863754,85.881859 202.954346,87.615868 203.029907,89.062332 
	C198.169418,90.278854 193.266144,90.829430 188.973343,92.746780 
	C183.228424,95.312691 180.549866,102.613068 182.448883,108.906219 
	C186.243042,121.479698 190.074234,134.047409 194.210205,146.511292 
	C204.710709,178.154831 215.380325,209.742279 225.999985,241.346237 
	C228.397934,248.482498 230.885193,255.588730 233.286835,262.723755 
	C233.642365,263.780060 234.206818,265.066895 233.905518,265.998291 
	C229.271606,280.322937 224.510147,294.606323 219.300827,310.341553 
	C209.913788,282.202576 200.956894,255.353043 191.981445,228.093292 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M73.015373,106.666306 
	C85.351128,144.545166 97.679451,181.977036 109.993042,219.413727 
	C114.698982,233.721069 118.898460,248.221176 124.294952,262.262115 
	C126.409431,267.763702 124.518143,271.874451 123.128136,276.451874 
	C119.730232,287.641449 116.130600,298.769775 112.243065,311.092743 
	C111.200912,309.222778 110.399811,308.192444 110.008568,307.024780 
	C92.651100,255.220856 75.353569,203.396835 57.987968,151.595627 
	C53.411247,137.943390 49.347649,124.054436 43.714901,110.842720 
	C39.680904,101.380905 33.804890,92.474197 22.207047,89.625008 
	C22.207047,88.074448 22.207047,86.290665 22.207047,84.252289 
	C44.234104,84.252289 66.319359,84.252289 88.796547,84.252289 
	C88.873512,85.922386 88.953400,87.655746 89.033287,89.389099 
	C88.750816,89.028915 88.468346,88.668732 88.185875,88.308548 
	C83.817055,90.683594 78.695908,92.315399 75.350594,95.701599 
	C73.210793,97.867561 73.694511,102.625374 73.015373,106.666306 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M571.234741,126.568283 
	C574.984863,139.075714 577.864685,151.414688 581.821350,163.402039 
	C582.350403,163.269547 582.879456,163.137054 583.408508,163.004562 
	C586.347107,150.071823 589.285706,137.139084 592.241455,124.130692 
	C595.091003,124.130692 597.729614,124.130692 600.716431,124.130692 
	C596.979614,139.454636 593.266235,154.673920 589.559021,169.894714 
	C589.025940,172.083374 588.212219,174.260345 588.076904,176.476196 
	C587.863525,179.970352 586.427124,181.096939 582.874573,181.132767 
	C579.070251,181.171143 578.640076,179.338531 577.860291,176.254349 
	C575.188293,165.686172 572.054321,155.231796 568.898987,144.793716 
	C568.599365,143.802460 566.917358,143.229065 565.780823,142.390686 
	C561.854492,155.770737 558.206787,168.201157 554.499817,180.833389 
	C552.047363,180.833389 549.411621,180.833389 546.581970,180.833389 
	C542.057068,162.132324 537.500671,143.300934 532.938843,124.447273 
	C541.381958,123.330399 541.338989,123.340019 543.009094,130.801956 
	C545.392151,141.449478 547.845703,152.081238 550.937561,162.908340 
	C556.852722,150.627197 559.068665,137.131927 563.557068,124.005936 
	C565.779785,125.400909 569.525208,121.147194 571.234741,126.568283 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M588.741333,244.317307 
	C591.886414,249.137558 594.807007,253.656967 597.727661,258.176392 
	C598.055054,258.092865 598.382446,258.009308 598.709839,257.925781 
	C598.709839,244.784378 598.709839,231.642975 598.709839,218.254868 
	C602.011597,218.254868 604.748901,218.254868 607.746155,218.254868 
	C607.746155,237.002975 607.746155,255.743240 607.746155,274.593140 
	C602.045837,276.121094 598.103394,274.632416 594.892151,269.199463 
	C588.838562,258.957733 582.102661,249.117172 575.563782,239.166779 
	C574.654053,237.782379 573.252747,236.721024 572.079041,235.510117 
	C571.723206,235.742447 571.367432,235.974777 571.011597,236.207092 
	C571.011597,248.980438 571.011597,261.753784 571.011597,274.760986 
	C568.294006,274.760986 565.889526,274.760986 563.239502,274.760986 
	C563.239502,255.973389 563.239502,237.231216 563.239502,218.491348 
	C568.618469,216.761246 572.176208,218.422607 575.055664,223.379623 
	C579.175415,230.471909 583.995667,237.157318 588.741333,244.317307 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M662.000000,133.038849 
	C662.000000,129.897125 662.000000,127.250198 662.000000,123.611382 
	C671.016296,124.051941 679.853821,124.153313 688.616455,125.015236 
	C695.778259,125.719711 699.295593,130.781250 699.899109,137.534180 
	C700.404663,143.191208 698.301331,147.995667 693.650818,150.268326 
	C696.195618,153.809845 699.755615,156.851639 700.700439,160.559738 
	C701.900085,165.268387 702.057190,171.034836 697.897827,174.798660 
	C694.957214,177.459610 691.124512,180.326248 687.433899,180.723923 
	C679.228516,181.608017 670.862244,180.999649 662.000000,180.999649 
	C662.000000,165.029022 662.000000,149.281342 662.000000,133.038849 
M674.603027,174.000000 
	C677.932861,173.999863 681.268188,174.120697 684.591187,173.970047 
	C689.111328,173.765106 692.748962,169.724258 692.999146,164.876709 
	C693.222717,160.544083 689.837769,155.429825 685.837097,155.115158 
	C680.618652,154.704697 675.343689,155.014694 670.000061,155.014694 
	C670.000061,160.661163 670.196838,165.658249 669.927307,170.630081 
	C669.761536,173.688004 671.018311,174.399216 674.603027,174.000000 
M687.086548,132.007080 
	C681.463318,131.542297 675.840088,131.077515 670.302063,130.619766 
	C670.302063,137.026276 670.302063,142.426666 670.302063,147.999573 
	C674.245300,147.999573 677.888123,148.034500 681.530029,147.990326 
	C685.647217,147.940384 689.231873,145.867432 690.622437,142.455612 
	C691.997620,139.081192 691.796936,134.874557 687.086548,132.007080 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M672.000000,249.000000 
	C672.000000,238.530014 672.000000,228.560043 672.000000,218.023972 
	C680.777588,218.023972 689.417114,217.442139 697.938049,218.197708 
	C705.523438,218.870331 710.856812,224.602936 711.899414,232.121750 
	C712.979004,239.906876 709.680054,245.904373 702.981018,250.015427 
	C702.610901,250.242599 702.278076,250.530716 701.612854,251.024109 
	C705.853821,258.811737 710.046814,266.511292 714.307922,274.335999 
	C705.550049,275.942108 705.613037,275.909760 701.737305,268.364960 
	C699.313721,263.647156 696.780151,258.975555 694.037537,254.439438 
	C693.357544,253.314896 691.737183,252.261932 690.439148,252.108932 
	C687.507690,251.763397 684.507568,252.000336 681.145264,252.000336 
	C681.145264,259.636047 681.145264,267.044617 681.145264,274.724182 
	C678.030457,274.724182 675.293396,274.724182 672.000000,274.724182 
	C672.000000,266.361603 672.000000,257.930786 672.000000,249.000000 
M693.140869,245.000000 
	C698.786743,244.720398 702.826477,241.449875 703.000000,237.018311 
	C703.268616,230.156052 700.261902,225.384399 695.065002,225.061310 
	C690.493713,224.777145 685.890747,225.001923 681.206299,225.001923 
	C681.206299,231.859467 681.206299,238.241150 681.206299,245.000000 
	C685.007690,245.000000 688.614258,245.000000 693.140869,245.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M1194.058594,226.252762 
	C1198.451904,236.147217 1196.346069,246.004623 1185.864380,250.755722 
	C1190.231934,258.725433 1194.594727,266.686646 1199.099731,274.907104 
	C1195.463257,274.907104 1192.476562,274.907104 1189.595215,274.907104 
	C1185.541382,267.284576 1181.549561,259.778687 1177.469360,252.106781 
	C1173.684448,252.106781 1169.698730,252.106781 1165.151123,252.106781 
	C1165.151123,259.573273 1165.151123,267.114044 1165.151123,274.802673 
	C1162.186646,274.802673 1159.784546,274.802673 1157.191895,274.802673 
	C1157.191895,255.880554 1157.191895,237.155563 1157.191895,218.003372 
	C1165.629150,218.003372 1173.915405,217.723465 1182.171143,218.105423 
	C1187.353271,218.345169 1191.070679,221.710815 1194.058594,226.252762 
M1173.420654,225.000000 
	C1170.671265,225.000000 1167.921875,225.000000 1165.262207,225.000000 
	C1165.262207,231.980148 1165.262207,238.359528 1165.262207,244.999329 
	C1169.966919,244.999329 1174.427368,245.231949 1178.854248,244.946198 
	C1185.934937,244.489120 1189.947021,237.239120 1187.008911,230.823044 
	C1184.426880,225.184525 1179.572021,224.790756 1173.420654,225.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M905.244507,252.097534 
	C901.029541,252.011703 897.239136,252.011703 893.071960,252.011703 
	C893.071960,259.720886 893.071960,267.127686 893.071960,274.762390 
	C890.294983,274.762390 887.893188,274.762390 885.242126,274.762390 
	C885.242126,256.003418 885.242126,237.284561 885.242126,218.028503 
	C893.800659,218.028503 902.421814,217.529160 910.953308,218.189346 
	C917.562561,218.700806 923.178894,224.563721 923.999084,230.031586 
	C925.578125,240.557983 923.033264,245.821182 913.984253,250.643005 
	C918.379089,258.577240 922.775024,266.513367 927.261902,274.613708 
	C918.283630,275.545959 918.272339,275.552094 913.893311,267.483765 
	C911.131287,262.394836 908.409119,257.284241 905.244507,252.097534 
M912.961060,242.559738 
	C918.615906,237.679932 916.816528,226.916336 909.181519,225.292740 
	C904.026367,224.196487 898.449402,225.083710 893.320435,225.083710 
	C893.320435,232.094482 893.320435,238.480911 893.320435,245.930023 
	C899.967468,244.940842 906.160889,244.019165 912.961060,242.559738 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M802.805664,276.000000 
	C796.061340,276.235870 790.168823,275.442352 784.826660,271.493744 
	C780.270386,268.125916 778.596130,263.679688 777.836792,258.206787 
	C780.932434,258.206787 783.715332,258.206787 786.498901,258.206787 
	C787.211548,269.415222 799.993652,271.312256 808.058105,267.234528 
	C810.560852,265.969055 813.418518,263.270264 811.836914,260.009888 
	C810.394531,257.036469 808.059326,253.864105 805.265991,252.329193 
	C801.281067,250.139496 796.355957,249.740463 792.122437,247.907059 
	C782.918152,243.921066 777.356323,237.028076 781.024353,227.358398 
	C783.307556,221.339554 788.254028,218.378204 794.192871,217.382797 
	C804.028198,215.734314 813.635376,217.512863 818.840271,227.731552 
	C819.827026,229.668884 819.920349,232.061340 820.526855,234.679535 
	C817.531616,234.679535 815.119324,234.679535 812.626953,234.679535 
	C809.292236,225.799774 806.792847,223.870697 798.618042,224.000015 
	C793.025391,224.088470 789.447876,226.910156 788.660461,231.853806 
	C788.169739,234.934875 791.705872,239.444107 795.803284,240.958969 
	C801.344116,243.007507 807.010071,244.775131 812.394348,247.174805 
	C818.657471,249.966217 821.010071,255.743301 819.997253,264.144897 
	C819.416382,268.963776 814.491333,273.828339 808.955017,274.991028 
	C807.071411,275.386627 805.163574,275.666992 802.805664,276.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M645.808960,130.999893 
	C637.365845,131.000000 629.404724,131.000000 621.224304,131.000000 
	C621.224304,136.747025 621.224304,142.151733 621.224304,147.957855 
	C629.087341,147.957855 636.838074,147.957855 644.788330,147.957855 
	C644.788330,150.422440 644.788330,152.491730 644.788330,154.959564 
	C636.931702,154.959564 629.179565,154.959564 621.219116,154.959564 
	C621.219116,161.424759 621.219116,167.496078 621.219116,173.967712 
	C630.432617,173.967712 639.515503,173.967712 648.793884,173.967712 
	C648.793884,176.431625 648.793884,178.500671 648.793884,180.786606 
	C636.583313,180.786606 624.506531,180.786606 612.214600,180.786606 
	C612.214600,161.899246 612.214600,143.162384 612.214600,124.212555 
	C624.442932,124.212555 636.518005,124.212555 649.263916,124.212555 
	C647.672119,126.527374 651.794617,130.435577 645.808960,130.999893 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M1119.196777,268.999969 
	C1127.426880,270.001251 1133.695923,264.186676 1133.191650,255.773880 
	C1132.448608,243.374435 1133.000000,230.897400 1133.000000,218.228195 
	C1136.061157,218.228195 1138.795166,218.228195 1141.009521,218.228195 
	C1141.009521,232.635956 1141.463257,246.974518 1140.833618,261.265350 
	C1140.475220,269.396973 1134.188965,273.858368 1126.877930,275.438019 
	C1118.522095,277.243378 1110.048218,276.713562 1104.027100,269.328674 
	C1102.098755,266.963593 1100.260132,263.698303 1100.178955,260.794586 
	C1099.784546,246.694809 1100.000732,232.577988 1100.000732,218.234756 
	C1102.711792,218.234756 1105.114136,218.234756 1108.000000,218.234756 
	C1108.000000,231.042480 1107.990723,243.803360 1108.003540,256.564209 
	C1108.010864,263.951294 1111.220093,267.641449 1119.196777,268.999969 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M763.000000,253.998383 
	C765.643127,261.113434 768.247986,267.826996 770.879517,274.609161 
	C762.050110,275.573120 761.816162,275.641144 759.099609,266.297791 
	C757.887878,262.130127 756.006226,260.508789 751.639160,260.909576 
	C747.185303,261.318329 742.644653,261.260895 738.179504,260.918732 
	C734.505432,260.637207 732.686340,262.075104 731.980652,265.490448 
	C731.788330,266.421112 731.312622,267.289703 731.018188,268.203247 
	C728.564026,275.816833 728.567627,275.817993 720.208191,274.511383 
	C726.829773,256.882812 733.438110,239.243881 740.093689,221.622818 
	C741.015747,219.181610 742.427063,217.812546 745.568604,217.841644 
	C748.996338,217.873367 750.029907,219.558411 751.024048,222.228867 
	C754.927185,232.713211 758.970520,243.145370 763.000000,253.998383 
M747.415649,254.000000 
	C749.552795,254.000000 751.689941,254.000000 754.624451,254.000000 
	C751.542847,245.809723 748.688660,238.223740 745.834412,230.637756 
	C745.412964,230.762009 744.991455,230.886261 744.570007,231.010513 
	C741.821899,238.622025 739.073853,246.233536 736.269897,254.000000 
	C739.803345,254.000000 743.131409,254.000000 747.415649,254.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M1241.877441,275.000000 
	C1231.091431,275.000000 1220.792725,275.000000 1210.248291,275.000000 
	C1210.248291,255.969482 1210.248291,237.234039 1210.248291,218.248779 
	C1222.371460,218.248779 1234.449829,218.248779 1246.524048,218.248779 
	C1247.429932,224.994293 1247.429932,224.996811 1240.874390,224.999329 
	C1233.409546,225.002213 1225.944702,225.000000 1218.241943,225.000000 
	C1218.241943,230.709335 1218.241943,236.114044 1218.241943,241.921768 
	C1226.381592,241.921768 1234.463013,241.921768 1242.767334,241.921768 
	C1242.767334,244.383240 1242.767334,246.453217 1242.767334,248.920685 
	C1234.641235,248.920685 1226.556274,248.920685 1218.239990,248.920685 
	C1218.239990,255.380615 1218.239990,261.451660 1218.239990,267.925171 
	C1227.719849,267.925171 1237.133179,267.925171 1246.769043,267.925171 
	C1246.769043,270.387970 1246.769043,272.454803 1246.769043,275.000000 
	C1245.265259,275.000000 1243.814941,275.000000 1241.877441,275.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M945.000000,244.985947 
	C945.315002,249.881454 945.547485,254.287582 945.962036,258.676544 
	C946.861389,268.199677 953.032959,269.579468 960.914001,268.972473 
	C967.110352,268.495239 970.895569,263.567505 970.970825,255.746643 
	C971.083069,244.082718 971.000122,232.416931 971.000244,220.751938 
	C971.000244,219.957199 971.000244,219.162460 971.000244,218.192108 
	C973.799438,218.192108 976.203369,218.192108 978.995239,218.192108 
	C978.995239,232.337219 979.682617,246.339676 978.753601,260.234070 
	C978.134338,269.495880 971.233337,275.262909 961.376038,275.922272 
	C955.725647,276.300171 950.222351,276.215912 944.927307,272.723877 
	C939.504395,269.147369 937.341492,264.135345 937.147522,258.505188 
	C936.689392,245.210403 937.000488,231.889114 937.000488,218.289780 
	C939.610718,218.289780 942.018127,218.289780 945.000000,218.289780 
	C945.000000,226.945587 945.000000,235.717178 945.000000,244.985947 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M1026.489746,219.166779 
	C1033.444702,223.194412 1035.845825,229.494675 1037.054443,236.797180 
	C1034.082886,236.797180 1031.295166,236.797180 1028.510498,236.797180 
	C1026.165771,224.950623 1017.128235,220.113678 1007.908203,226.158813 
	C1005.395447,227.806320 1002.596130,231.037918 1002.357178,233.756058 
	C1001.619446,242.148300 1000.720642,250.915710 1002.375549,259.029266 
	C1004.388062,268.896027 1014.301392,272.293610 1022.648987,266.865082 
	C1025.529541,264.991821 1027.074951,261.065338 1029.230347,258.076752 
	C1028.897583,257.777527 1028.564941,257.478271 1028.232300,257.179047 
	C1031.025879,257.179047 1033.819336,257.179047 1036.620972,257.179047 
	C1034.817749,277.067139 1014.860229,279.578156 1003.264160,273.039124 
	C995.695190,268.770935 992.829529,261.415161 992.201660,253.561157 
	C991.532104,245.185257 991.470520,236.787231 995.567383,228.646301 
	C1000.841919,218.165314 1011.606750,215.891464 1020.695923,217.309357 
	C1022.549133,217.598450 1024.317871,218.428970 1026.489746,219.166779 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M282.000000,84.000000 
	C290.147247,84.000000 297.794495,84.000000 305.719543,84.000000 
	C305.719543,85.960846 305.719543,87.698029 305.719543,89.614952 
	C292.585388,91.580788 287.618103,101.917931 283.050201,112.532608 
	C281.663513,115.754967 280.891235,119.238403 279.777405,122.582245 
	C277.917938,128.164780 277.897583,128.158005 270.899506,127.756638 
	C272.656219,118.577499 275.711334,108.979675 270.562897,100.363991 
	C265.324707,91.598129 256.277893,89.432762 246.277145,90.054787 
	C246.277145,88.041359 246.277145,86.294998 246.277145,84.000000 
	C257.942535,84.000000 269.721283,84.000000 282.000000,84.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M632.403442,242.606689 
	C640.374146,242.943863 647.999451,242.943863 655.809570,242.943863 
	C655.809570,245.461685 655.809570,247.530609 655.809570,249.997345 
	C647.889282,249.997345 640.137329,249.997345 632.000305,249.997345 
	C632.000305,258.466614 632.000305,266.550385 632.000305,274.806335 
	C629.197754,274.806335 626.793762,274.806335 624.202454,274.806335 
	C624.202454,255.871216 624.202454,237.129852 624.202454,218.202698 
	C636.148071,218.202698 647.891113,218.202698 659.806519,218.202698 
	C659.806519,220.474762 659.806519,222.543701 659.806519,225.010208 
	C650.540588,225.010208 641.456848,225.010208 632.058044,225.010208 
	C632.058044,231.023605 632.058044,236.646561 632.403442,242.606689 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M1063.000000,251.000000 
	C1063.000000,242.190048 1063.000000,233.880096 1063.000000,225.166779 
	C1056.715698,225.166779 1050.636719,225.166779 1044.282104,225.166779 
	C1044.282104,222.700500 1044.282104,220.630371 1044.282104,218.277496 
	C1058.931641,218.277496 1073.679199,218.277496 1088.714233,218.277496 
	C1088.714233,220.293182 1088.714233,222.363297 1088.714233,224.828033 
	C1083.077759,224.828033 1077.323608,224.828033 1071.173218,224.828033 
	C1071.173218,241.616196 1071.173218,258.024384 1071.173218,274.714417 
	C1068.376831,274.714417 1065.974976,274.714417 1063.000000,274.714417 
	C1063.000000,267.045807 1063.000000,259.272888 1063.000000,251.000000 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M872.999878,221.026428 
	C873.166138,223.656631 873.142273,225.283508 870.010071,225.080948 
	C865.226074,224.771576 860.407288,224.999832 855.238220,224.999832 
	C855.238220,241.558029 855.238220,257.936707 855.238220,274.656769 
	C852.490662,274.656769 850.084900,274.656769 847.149658,274.656769 
	C847.149658,258.432800 847.149658,241.921875 847.149658,225.033356 
	C840.899963,225.033356 835.157288,225.033356 829.214539,225.033356 
	C829.214539,222.586761 829.214539,220.520401 829.214539,218.002625 
	C843.226135,218.002625 857.145081,217.968063 871.062256,218.110855 
	C871.716370,218.117569 872.354248,219.702866 872.999878,221.026428 
z"/>
    <path fill="#000" opacity="1.000000" stroke="none"
      d="
M538.026733,219.262360 
	C540.536316,218.438568 543.022644,218.092957 545.782227,217.709366 
	C545.782227,237.030731 545.782227,255.724930 545.782227,274.709961 
	C543.396179,274.709961 540.997009,274.709961 538.003357,274.709961 
	C538.003357,256.474121 538.003357,238.107330 538.026733,219.262360 
z"/>
    <path fill="#000000" opacity="1.000000" stroke="none"
      d="
M674.120728,174.000000 
	C671.018311,174.399216 669.761536,173.688004 669.927307,170.630081 
	C670.196838,165.658249 670.000061,160.661163 670.000061,155.014694 
	C675.343689,155.014694 680.618652,154.704697 685.837097,155.115158 
	C689.837769,155.429825 693.222717,160.544083 692.999146,164.876709 
	C692.748962,169.724258 689.111328,173.765106 684.591187,173.970047 
	C681.268188,174.120697 677.932861,173.999863 674.120728,174.000000 
z"/>
    <path fill="#000000" opacity="1.000000" stroke="none"
      d="
M687.472107,132.168457 
	C691.796936,134.874557 691.997620,139.081192 690.622437,142.455612 
	C689.231873,145.867432 685.647217,147.940384 681.530029,147.990326 
	C677.888123,148.034500 674.245300,147.999573 670.302063,147.999573 
	C670.302063,142.426666 670.302063,137.026276 670.302063,130.619766 
	C675.840088,131.077515 681.463318,131.542297 687.472107,132.168457 
z"/>
    <path fill="#000000" opacity="1.000000" stroke="none"
      d="
M692.680847,245.000000 
	C688.614258,245.000000 685.007690,245.000000 681.206299,245.000000 
	C681.206299,238.241150 681.206299,231.859467 681.206299,225.001923 
	C685.890747,225.001923 690.493713,224.777145 695.065002,225.061310 
	C700.261902,225.384399 703.268616,230.156052 703.000000,237.018311 
	C702.826477,241.449875 698.786743,244.720398 692.680847,245.000000 
z"/>
    <path fill="#000000" opacity="1.000000" stroke="none"
      d="
M1173.895874,225.000000 
	C1179.572021,224.790756 1184.426880,225.184525 1187.008911,230.823044 
	C1189.947021,237.239120 1185.934937,244.489120 1178.854248,244.946198 
	C1174.427368,245.231949 1169.966919,244.999329 1165.262207,244.999329 
	C1165.262207,238.359528 1165.262207,231.980148 1165.262207,225.000000 
	C1167.921875,225.000000 1170.671265,225.000000 1173.895874,225.000000 
z"/>
    <path fill="#000000" opacity="1.000000" stroke="none"
      d="
M912.657715,242.828613 
	C906.160889,244.019165 899.967468,244.940842 893.320435,245.930023 
	C893.320435,238.480911 893.320435,232.094482 893.320435,225.083710 
	C898.449402,225.083710 904.026367,224.196487 909.181519,225.292740 
	C916.816528,226.916336 918.615906,237.679932 912.657715,242.828613 
z"/>
    <path fill="#000000" opacity="1.000000" stroke="none"
      d="
M746.937561,254.000000 
	C743.131409,254.000000 739.803345,254.000000 736.269897,254.000000 
	C739.073853,246.233536 741.821899,238.622025 744.570007,231.010513 
	C744.991455,230.886261 745.412964,230.762009 745.834412,230.637756 
	C748.688660,238.223740 751.542847,245.809723 754.624451,254.000000 
	C751.689941,254.000000 749.552795,254.000000 746.937561,254.000000 
z"/>
  </svg>
  if (props.href) {
    return <a {...props} className={props.className}>
      {svgElement}
    </a>
  }

  return <div {...props}>{svgElement}</div>;
}

export default memo(LogoUI);
