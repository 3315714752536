import React, {memo} from "react";

const EyeActiveUI = (props) => {
  return <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M22 8C22 8 17.875 0.4375 11 0.4375C4.125 0.4375 0 8 0 8C0 8 4.125 15.5625 11 15.5625C17.875 15.5625 22 8 22 8ZM1.61287 8C2.27772 9.01045 3.04239 9.95155 3.89538 10.8091C5.665 12.5815 8.085 14.1875 11 14.1875C13.915 14.1875 16.3336 12.5815 18.106 10.8091C18.959 9.95155 19.7237 9.01045 20.3885 8C19.7237 6.98954 18.959 6.04844 18.106 5.19087C16.3336 3.4185 13.9136 1.8125 11 1.8125C8.085 1.8125 5.66638 3.4185 3.894 5.19087C3.04101 6.04844 2.27633 6.98954 1.6115 8H1.61287Z" fill="#ABADB4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11 4.5625C10.0883 4.5625 9.21398 4.92466 8.56932 5.56932C7.92466 6.21398 7.5625 7.08832 7.5625 8C7.5625 8.91168 7.92466 9.78602 8.56932 10.4307C9.21398 11.0753 10.0883 11.4375 11 11.4375C11.9117 11.4375 12.786 11.0753 13.4307 10.4307C14.0753 9.78602 14.4375 8.91168 14.4375 8C14.4375 7.08832 14.0753 6.21398 13.4307 5.56932C12.786 4.92466 11.9117 4.5625 11 4.5625ZM6.1875 8C6.1875 6.72365 6.69453 5.49957 7.59705 4.59705C8.49957 3.69453 9.72365 3.1875 11 3.1875C12.2764 3.1875 13.5004 3.69453 14.403 4.59705C15.3055 5.49957 15.8125 6.72365 15.8125 8C15.8125 9.27635 15.3055 10.5004 14.403 11.403C13.5004 12.3055 12.2764 12.8125 11 12.8125C9.72365 12.8125 8.49957 12.3055 7.59705 11.403C6.69453 10.5004 6.1875 9.27635 6.1875 8Z" fill="#ABADB4"/>
    </svg>
  </div>
}

export default memo(EyeActiveUI);
