import React, {memo} from "react";

import {Link} from "react-router-dom";

import SuccessIconContainer from "../../icons/SuccessIcon/functional/SuccessIconContainer";

const SuccessWindowUI = ({
                           classes, title, description, style,
                           redirectMessage, redirectHref, CustomComponent = null
                         }) => {
  return <div className={classes['Success']} style={style}>
    <SuccessIconContainer className={classes['Success-Icon']}/>
    <span className={classes['Success-Title']}>{title}</span>
    {CustomComponent ? <CustomComponent/>
      : <>
        {description && <span className={classes['Success-Description']}>
      {description}
      </span>}
        <Link to={redirectHref} className={classes['Success-Link']}>{redirectMessage}</Link></>}
  </div>;
}

export default memo(SuccessWindowUI);
