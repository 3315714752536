import React, {memo} from "react";

const CopyIconUI = ({strokeColor = '#4E5566', ...rest}) => {
  return <div {...rest}>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.87318 11.8973C9.8399 11.8643 9.79496 11.8458 9.74812 11.8458C9.70128 11.8458 9.65634 11.8643 9.62306 11.8973L7.05101 14.4695C5.86016 15.6605 3.85033 15.7866 2.53553 14.4695C1.21852 13.1524 1.34468 11.1446 2.53553 9.95368L5.10758 7.38141C5.1762 7.31279 5.1762 7.1999 5.10758 7.13127L4.22662 6.25024C4.19334 6.21728 4.1484 6.19879 4.10156 6.19879C4.05472 6.19879 4.00978 6.21728 3.9765 6.25024L1.40445 8.8225C-0.468149 10.6952 -0.468149 13.7257 1.40445 15.5963C3.27704 17.4668 6.30728 17.469 8.17766 15.5963L10.7497 13.024C10.8183 12.9554 10.8183 12.8425 10.7497 12.7739L9.87318 11.8973ZM15.5972 1.40456C13.7246 -0.468187 10.6944 -0.468187 8.824 1.40456L6.24973 3.97682C6.21678 4.01011 6.19829 4.05505 6.19829 4.10189C6.19829 4.14873 6.21678 4.19368 6.24973 4.22696L7.12848 5.10578C7.1971 5.17441 7.30998 5.17441 7.3786 5.10578L9.95065 2.53352C11.1415 1.34258 13.1513 1.2164 14.4661 2.53352C15.7831 3.85064 15.657 5.85842 14.4661 7.04937L11.8941 9.62163C11.8611 9.65491 11.8426 9.69986 11.8426 9.7467C11.8426 9.79354 11.8611 9.83849 11.8941 9.87177L12.775 10.7528C12.8437 10.8214 12.9565 10.8214 13.0252 10.7528L15.5972 8.18054C17.4676 6.3078 17.4676 3.27731 15.5972 1.40456ZM10.6722 5.40905C10.639 5.3761 10.594 5.35761 10.5472 5.35761C10.5003 5.35761 10.4554 5.3761 10.4221 5.40905L5.40861 10.4208C5.37566 10.454 5.35717 10.499 5.35717 10.5458C5.35717 10.5927 5.37566 10.6376 5.40861 10.6709L6.28515 11.5475C6.35377 11.6161 6.46665 11.6161 6.53527 11.5475L11.5466 6.5358C11.6152 6.46718 11.6152 6.35428 11.5466 6.28566L10.6722 5.40905Z" fill={strokeColor}/>
    </svg>
  </div>
}

export default memo(CopyIconUI);
