import React, { memo } from "react";
import ButtonContainer from "../../../../../../../../common/Button/functional/ButtonContainer";
import InputModalContainer from "../../../../../../../../InputModal/functional/InputModalContainer";
import InfoModalContainer from "../../../../../../../../InfoModal/functional/InfoModalContainer";
import { modalDescription } from "../../../../../../../../InfoModal/constans/modalDescription";


const WithdrawProfitUI = ({ classes, refProfit, handleValueInput, percent, startValueSubmission,
  handleValueInputClose, amount, disabled, handleAmountChange, handleSubmit, startSubmission,
  handleClose, send, title
}) => {

  return <div className={classes['GeneralInfoAboutPartnerProgram-Section']}>
    <span className={classes['Section-Left']}>
      {title}
    </span>
    <div className={classes['Section-Right_different']}>
      <span className={classes['Section-Right']}>{refProfit} WI</span>
      {percent && <span className={classes['Section-Right-small']}>2% в ДЕНЬ</span>}
    </div>
    <div className={classes['Take-Money']}>
      <ButtonContainer onClick={handleValueInput} text="Вывести" className={classes['Take-Money-Submit']} />
    </div>

    {startValueSubmission && <InputModalContainer handleClose={handleValueInputClose}
      handleSubmit={handleSubmit}
      disabled={disabled}
      handleAmountChange={handleAmountChange}
      title={'Вывод прибыли с рефералов'}
      amount={amount}
    />}

    {startSubmission && <InfoModalContainer handleClose={handleClose}
      selected={amount}
      title='Рефералы и стейкинг'
      btnText='Снять прибыль'
      descFirst={modalDescription.referalOutput}
      currency='WI'
      handleSubmit={send}
    />}
  </div>

}

export default memo(WithdrawProfitUI);
