import React, {useCallback, useMemo, useState} from "react";
 
import {useDispatch, useSelector} from "react-redux";
 
import AddBalanceUI from "../ui/AddBalanceUI";
 
import withToFixed from "../../../../../../../helpers/hoc/withToFixed";
import {addBalance} from "../../../../../../../redux/user/userActions";
import {getIcoPriceEthSelector} from "../../../../../../../redux/user/userSelectors";
 
import styles from "./AddBalance.module.css";
 
const AddBalanceContainer = ({toFixed}) => {
  const [amount, setAmount] = useState('');
 
  const [disabled, setDisabled] = useState(true);
  const [startSubmission, setStartSubmission] = useState(false);
 
  const ethPrice = useSelector(getIcoPriceEthSelector);
 
  const dispatch = useDispatch();
 
  const handleAmountChange = useCallback((e) => {
    const {value} = e.target;
    const last = value[value.length - 1];
    if (last === '.') {
      if (value.indexOf('.') === value.length - 1) {
        setAmount(value);
        return null;
      } else {
        return null;
      }
    }
    value === '' ? setDisabled(true) : setDisabled(false)
    const numberRegexp = new RegExp(`^\\d*\\.?\\d*$`);
    if (numberRegexp.test(value) || value === '') {
      setAmount(value);
    }
  }, []);
 
  const calculateWdx = useMemo(() => {
    const result = amount / ethPrice;
 
    if(isNaN(result))
      return 0;
 
    return toFixed(amount / ethPrice);
  }, [amount, ethPrice, toFixed]);
 
  // const handleSubmit = useCallback(() => {
  //   setDisabled(true);
  //   if (amount <= 0 || amount === '') {
  //     return null;
  //   } else {
  //     dispatch(addBalance(amount))
  //       .then(() => setDisabled(false), () => setDisabled(false));
  //   }
  // }, [amount, dispatch]);

  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);

  const handleSent= useCallback((e) => {
    e.preventDefault();
    if (amount <= 0 || amount === '') {
      return null;
    } else {
      dispatch(addBalance(amount, handleClose, handleClose))
    }
  }, [amount, handleClose, dispatch]);
 
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setStartSubmission(true);
  }, []);
 
  return <AddBalanceUI
    amount={amount}

    handleSubmit={handleSubmit}
    handleAmountChange={handleAmountChange}
    calculateWdx={calculateWdx}
    classes={styles} 
    handleSent={handleSent}
    startSubmission={startSubmission}
    handleClose={handleClose}
    disabled={disabled}
    />;
}
 
export default withToFixed(AddBalanceContainer)(6);