import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import HeaderUI from "../ui/HeaderUI";
import LogoContainer from "../../common/Logo/functional/LogoContainer";
import BalanceInformationContainer from "./BalanceInformation/functional/BalanceInformationContainer";
import RenderEmptyContainer from "../../common/RenderEmpty/functional/RenderEmptyContainer";
import AccountContainer from "./Account/functional/AccountContainer";

import { getHeaderViewUserSelector, getTokenExchangeSelector } from "../../../redux/user/userSelectors";

import styles from './Header.module.css';

const HeaderContainer = () => {
  const [headerOptions, setHeaderOptions] = useState(null);

  const user = useSelector(getHeaderViewUserSelector);
  const tokenExchange = useSelector(getTokenExchangeSelector);

  useEffect(() => {
    const options = [
      {
        id: 0, component: LogoContainer, props: {
          className: styles.Logo,
          target: '_blank',
          href: 'http://invest.webinfra.ru'
        }
      },
      { id: 1, component: RenderEmptyContainer, props: {} },
      {
        id: 2, component: BalanceInformationContainer, props: {
          balance_wdx: user ? user.balance_wdx : 0,
          wdxPrice: user ? user.wdxPrice : 0,
          usdtPrice: user ? user.usdtPrice : 0
        }
      },
      {
        id: 4, component: AccountContainer, props: {
          username: user ? user.username : 'Подключить кошелек',
          tokenExchange: tokenExchange ? tokenExchange : ''
        }
      },
    ];
    setHeaderOptions(options);
  }, [user, tokenExchange]);



  return <HeaderUI headerOptions={headerOptions} classes={styles} />;
}

export default HeaderContainer;
