import React, {memo} from "react";

import ButtonContainer from "../../../../../../../common/Button/functional/ButtonContainer";

const ModelRowUI = ({classes, actionLeftTitle, actionLeftValue, buttonName, submitFn = null}) => {
  return <div className={classes['Model-Action']}>
            <div className={classes['Action-Left']}>
                {actionLeftTitle && <span className={classes['Action-Left-Title']}>{actionLeftTitle}</span>}
                {actionLeftValue && <span className={classes['Action-Left-Value']}>{actionLeftValue}</span>}
            </div>
            <div className={classes['Action-Right']}>
            <ButtonContainer text={buttonName} onClick={submitFn}/>
            </div>
        </div>
}

export default memo(ModelRowUI);
