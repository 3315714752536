import React, {memo} from "react";

import RenderLineContainer from "../../../../../../common/RenderLine/functional/RenderLineContainer";
// import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";
// import InfoModalContainer from "../../../../../../InfoModal/functional/InfoModalContainer";
// import {modalDescription} from "../../../../../../InfoModal/constans/modalDescription";
// import InputModalContainer from "../../../../../../InputModal/functional/InputModalContainer";
import WithdrawProfit from "../functional/WithdrawProfit/functional/WithdrawProfitContainer";

const GeneralInfoAboutPartnerProgramUI = ({
                                            classes, refCount, refStakingAmount, refProfit, handleSentProfit, refAutoProfit,
                                            handleSentAutoProfit
                                          }) => {
  return <div className={classes['GeneralInfoAboutPartnerProgram']}>
    <span className={classes['GeneralInfoAboutPartnerProgram-Title']}>
      Прибыли с рефералов
    </span>
    <RenderLineContainer/>
    <div className={classes['GeneralInfoAboutPartnerProgram-Section']}>
      <span className={classes['Section-Left']}>
        Общее число рефералов
      </span>
      <span className={classes['Section-Right']}>
          {refCount}
      </span>
    </div>
    <RenderLineContainer/>
    <div className={classes['GeneralInfoAboutPartnerProgram-Section']}>
      <span className={classes['Section-Left']}>
          Общая сумма стейкинга
      </span>
      <span className={classes['Section-Right']}>
          {refStakingAmount} WI
      </span>
    </div>
    <RenderLineContainer/>
    <WithdrawProfit
      classes={classes}
      refProfit={refProfit}
      handleSent={handleSentProfit}
      percent={true}
      title={'Прибыль с рефералов'}
    />

  </div>
}

export default memo(GeneralInfoAboutPartnerProgramUI);
