import React, {useCallback, useRef, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import BuyStakingUI from "../ui/BuyStakingUI";

import {sendToStaking} from "../../../../../../../redux/user/userActions";
import {getStakingPriceSelector} from "../../../../../../../redux/user/userSelectors";

import styles from "./BuyStaking.module.css";

const BuyStakingContainer = () => {
  const inputRef = useRef(null);
  const [startSubmission, setStartSubmission] = useState(false);
  const stakingPrice = useSelector(getStakingPriceSelector);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);
  const handleSent = useCallback((e) => {
    e.preventDefault();
    dispatch(sendToStaking(inputRef.current.textContent, handleClose));
  }, [dispatch, handleClose]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setStartSubmission(true);
  }, []);
  
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.textContent = stakingPrice;
    }
  }, [stakingPrice]);

  return <BuyStakingUI classes={styles}
                       inputRef={inputRef}
                       startSubmission={startSubmission}
                       handleSubmit={handleSubmit}
                       handleClose={handleClose}
                       handleSent={handleSent}
  />;
};

export default BuyStakingContainer;
