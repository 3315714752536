import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import OperationListUI from "../ui/OperationListUI";

import {
  //getOperationList,
  getOperationListByPage
} from "../../../../../redux/user/userActions";
import {
  getAddressUserSelector,
  getOperationListPageInfoSelector,
  getOperationListViewSelector
} from "../../../../../redux/user/userSelectors";
import withToFixed from "../../../../../helpers/hoc/withToFixed";

import styles from "./OperationList.module.css";

const OperationListContainer = ({ toFixed, ...rest }) => {
  const [tableHeads, setTableHeads] = useState(null);

  const tableData = useSelector(getOperationListViewSelector);
  const address = useSelector(getAddressUserSelector);
  const { currentPage, inLastPage } = useSelector(getOperationListPageInfoSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getOperationList());

    setTableHeads([
      { id: 0, value: 'Дата:' },
      { id: 1, value: 'Тип:' },
      { id: 3, value: 'Валюта:' },
      { id: 4, value: 'Сумма:' },
    ]);

  }, [dispatch]);

  const getPage = useCallback((page) => {
    return dispatch(getOperationListByPage(page));
  }, [dispatch]);

  const getNormalDate = useCallback((seconds) => {
    const date = new Date(0);
    date.setUTCSeconds(+seconds);

    let day = date.getDate();
    let month = date.getMonth();
    let minutes = date.getMinutes();
    let hours = date.getHours();

    if (day < 10) {
      day = `0${day}`
    }
    if (month + 1 < 10) {
      month = `0${month + 1}`;
    } else {
      month += 1;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (hours === 0) {
      hours = `0${hours}`;
    }

    return `${day}.${month}.${date.getFullYear()} ${hours}:${minutes}`;
  }, []);

  return <OperationListUI classes={styles}
    tableData={tableData}
    tableHeads={tableHeads}
    address={address}
    toFixed={toFixed}
    getPage={getPage}
    currentPage={currentPage}
    getNormalDate={getNormalDate}
    inLastPage={inLastPage}
    {...rest} />;
}

export default withToFixed(OperationListContainer)(4);
