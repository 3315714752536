import {createSelector} from "reselect";

export const getGlobalSettingsSelector = state => state.globalSettings;

export const getMaxPrepaymentAmountSelector = createSelector(getGlobalSettingsSelector, settings => {
  if (isNaN(+settings.prepayment_amount)) {
    return 0;
  }

  return settings.prepayment_amount;
});
