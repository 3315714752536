import React, {memo} from "react";

import termsOfUse from "../../../assets/pdf/terms-of-use.pdf";

const FooterUI = ({classes}) => {
  return <footer className={classes.Footer}>
    <span className={classes['Footer-Text']}>© 2021, Webinfra. Все права защищены</span>
    <span/>
    <a href={termsOfUse}
       download
       className={`${classes['Footer-Text']} ${classes['Footer-Text_underlined']}`}>Пользовательское соглашение</a>
  </footer>;
}

export default memo(FooterUI);
