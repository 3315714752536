import React from "react";

import CloseIconContainer from "../../../../CloseIcon/CloseIconContainer";

const CurrentRateInfoUI = ({classes, handleClose}) => {
  return <div className={classes['CurrentRateInfo']}>
    <CloseIconContainer className={classes['Close-Icon']} onClick={handleClose}/>
    <span className={`${classes['CurrentRateInfo-Bold']} 
    ${classes['CurrentRateInfo-Bold-1']}`}>Чем больше WI на стейкинге, тем больше %</span>
    <div className={classes['CurrentRateInfo-Offers']}>
      <span className={classes['CurrentRateInfo-Offer']}>от 100 000 WI + 0,1%</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 200 000 WI + 0,2 %</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 1 000 000 WI + 0,3%</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 2 000 000 WI + 0,4%</span>
    </div>
    <span className={`${classes['CurrentRateInfo-Bold']} ${classes['CurrentRateInfo-Bold-2']}`}>% зависит от срока стейкинга: чем больше срок, тем больше %</span>
    <div className={classes['CurrentRateInfo-Offers']}>
      <span className={classes['CurrentRateInfo-Offer']}>от 1 месяца + 0,1%</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 3 месяцев + 0,2%</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 6 месяцев + 0,3%</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 1 года + 0,4%</span>
      <span className={classes['CurrentRateInfo-Offer']}>от 1,5 года + 0,5%</span>
    </div>
    <span className={`${classes['CurrentRateInfo-Bold']} ${classes['CurrentRateInfo-Bold-3']}`}>Реферальные со стейкинга идут от ежедневного дохода рефералов: 2% с
      1-ой по 10-ю линии (глубина линий зависит от статуса). Лимитов на выводы нет.</span>
  </div>;
}

export default CurrentRateInfoUI;
