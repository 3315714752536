import React from "react";

import {Link} from "react-router-dom";
import BigErrorIconContainer from "../../icons/BigErrorIcon/functional/BigErrorIconContainer";


const FailureWindowUI = ({classes, title, description, redirectMessage, redirectHref}) => {
  return <div className={classes['Failure']}>
    <BigErrorIconContainer className={classes['Failure-Icon']}/>
    <span className={classes['Failure-Title']}>{title}</span>
    <span className={classes['Failure-Description']}>
      {description}
      <Link to={redirectHref} className={classes['Failure-Link']}>{redirectMessage}</Link>
    </span>
  </div>
}

export default FailureWindowUI;
