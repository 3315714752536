import {createSelector} from "reselect";

export const getReferralSelector = (state) => state.referral;

export const getReferralLinkSelector = createSelector(getReferralSelector, referral => {
  return referral.ref_url || '';
});

export const getReferrerSelector = createSelector(getReferralSelector, referral => {
  return referral.referrer || null;
})

export const getReferrerForInfoViewSelector = createSelector(getReferrerSelector, referrer => {
  if (!referrer)
    return referrer;
  return {
    id: referrer.id,
    email: referrer.email,
    telegram: referrer.telegram_id
  }
})

export const getReferralsSelector = createSelector(getReferralSelector, referral => {
  return referral.referrals;
})

export const getReferralsByLevelSelector = (level) => createSelector(getReferralsSelector, referrals => {
  if (!referrals)
    return [];
  return referrals[level - 1] || [];
})

export const getReferralAndStakingViewSelector = createSelector(getReferralSelector, referral => {
  return {
    refCount: referral.ref_count || 0,
    refProfit: referral.ref_profit || 0,
    ref_auto_profit: referral.ref_auto_profit || 0,
    refStakingAmount: referral.ref_staking_amount || 0,
  }
})
