import React, {useCallback, useEffect, useMemo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import StatusUI from "../ui/StatusUI";

import {getStakingStatuses} from "../../../../../../../redux/user/userActions";
import {getStakingStatusesSelector} from "../../../../../../../redux/user/userSelectors";
import {getCurrentStakingStatusSelector} from "../../../../../../../redux/user/userSelectors"
import {buyStakingStatus} from "../../../../../../../redux/user/userActions";
import styles from "./Status.module.css";

const StatusContainer = (props) => {
  const dispatch = useDispatch();

  const statuses = useSelector(getStakingStatusesSelector);
  const currentStatus = useSelector(getCurrentStakingStatusSelector);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [startSubmission, setStartSubmission] = useState(false);

  const isLoading = useMemo(() => statuses.length === 0, [statuses]);
  const options = useMemo(() => {
    if (currentStatus === -1) {
      return [];
    }
    return statuses.map((status, index) => ({
      label: status.name,
      value: index,
      isDisabled: currentStatus > index,
    }))
  }, [statuses, currentStatus]);
  const style = useMemo(() => ({
    container: {
      width: '100%',
      height: 'calc(35px + 1.146vw)',
    },
    control: {
      boxShadow: '0 0 4px #D3D3D3',
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: 15,
    },
    dropdownIndicator: {
      '& > svg': {
        display: 'none',
      },
      '&:before': {
        content: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxOSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNSAxMEwxOC41OTMzIDAuMjVIMC40MDY3MzRMOS41IDEwWiIgZmlsbD0iI0M0QzRDNCIvPgo8L3N2Zz4K)',
      }
    },
    menu: {
      top: 'calc(100% + 65px)',
      boxShadow: '0 0 4px #D3D3D3',
      marginTop: -6,
    },
    menuList: {
      padding: 0,
      borderRadius: 0,
      background: '#F2F2F2',
      boxShadow: '0 0 4px #D3D3D3',
      maxHeight: 120,
    },
    option: (provided, state) => {
      return {
        backgroundColor: 'transparent !important',
        lineHeight: '150%',
        letterSpacing: '-0.01em',
        textTransform: 'uppercase',
        color: '#4E5566',
        borderBottom: '1px dashed #E9E9E9',
        height: 40,
        fontSize: 'calc(8px + 0.313vw)',
        '&:after': state.isSelected ? {} : {
          position: 'absolute',
          right: 15,
          content: `"$${statuses.find(status => status.name === state.label)?.price}"`,
        }
      };
    },
    singleValue: {
      lineHeight: '150%',
      letterSpacing: '-0.01em',
      textTransform: 'uppercase',
      color: '#4E5566',
      fontSize: 'calc(8px + 0.313vw)',
      fontWeight: 'normal',
      fontFamily: `'Roboto', sans-serif`,
    },
    placeholder: {
      fontSize: 'calc(10px + 0.417vw)',
    }
  }), [statuses]);
  
  const handleClose = useCallback(() => {
    setStartSubmission(false);
  }, []);
  const handleSent = useCallback(() => {
    dispatch(buyStakingStatus(selectedStatus.value, handleClose));
  }, [dispatch, selectedStatus, handleClose]);

  const handleChange = useCallback((status) => {
    setSelectedStatus(status);
  }, []);
  const handleSubmit = useCallback(() => {
    setStartSubmission(true);
  }, []);

  useEffect(() => {
    dispatch(getStakingStatuses());
  }, [dispatch]);
  useEffect(() => {
    if (currentStatus !== -1 && selectedStatus === null && statuses.length) {
      const option = options[currentStatus];
      if (option) {
        handleChange(option);
      } else {
        handleChange(options[0]);
      }
    }
  }, [options, selectedStatus, statuses.length, handleChange, currentStatus]);

  return <StatusUI classes={styles}
                   style={style}
                   options={options}
                   isLoading={isLoading}
                   handleChange={handleChange}
                   selectedStatus={selectedStatus}
                   startSubmission={startSubmission}
                   handleSubmit={handleSubmit}
                   handleClose={handleClose}
                   handleSent={handleSent}
                   {...props}/>;
}

export default StatusContainer;
