import userConstants from "./userConstants";

const initialState = {
  isAuth: null,
  operationList: {
    limit: 10,
    pageNumber: 1,
    view: [],
    list: {},
    inLastPage: false,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.SET_USER:
      return {
        ...state,
        ...action.payload.user,
      }
    case userConstants.SET_WI_PRICE:
      return {
        ...state,
        wdxPrice: action.payload.wdxPrice,
      }
    case userConstants.SET_ICO_PRICE_ETH:
      return {
        ...state,
        icoPriceEth: action.payload.icoPriceEth,
      }
    case userConstants.USER_SET_CAR_ORDER:
      return {
        ...state,
        carOrder: {
          ...action.payload.carOrder,
        }
      }
    case userConstants.USER_SET_AUTH:
      return {
        ...state,
        isAuth: action.payload.auth,
      }
    case userConstants.SET_ETH_PRICE:
      return {
        ...state,
        ethPrice: action.payload.ethPrice,
      }
    case userConstants.USER_ADD_OPERATION_LIST:
      const { pageNumber, operationList } = action.payload;
      return {
        ...state,
        operationList: {
          ...state.operationList,
          list: {...state.operationList.list, [pageNumber]: operationList},
        }
      }
    case userConstants.USER_VIEW_OPERATION_LIST:
      return {
        ...state,
        operationList: {
          ...state.operationList,
          view: action.payload.operationList,
        }
      }
    case userConstants.USER_SET_OPERATION_LIST_PAGE_NUMBER:
      return {
        ...state,
        operationList: {
          ...state.operationList,
          pageNumber: action.payload.page,
        }
      }
    case userConstants.SET_WI_PRICE_USDT:
      return {
        ...state,
        usdtPrice: action.payload.usdtPrice
      }
    case userConstants.USER_SET_IN_LAST_PAGE:
      return {
        ...state,
        operationList: {
          ...state.operationList,
          inLastPage: action.payload.inLastPage,
        }
      }
    case userConstants.USER_SET_2FA:
      return {
        ...state,
        ...action.payload,
      }
    case userConstants.USER_SET_2FA_STATE:
      return {
        ...state,
        '2fa': action.payload['2fa']
      }
    case userConstants.USER_SET_STAKING:
      return {
        ...state,
        ...action.payload,
      }
    case userConstants.USER_SET_STAKING_STATUSES:
      return {
        ...state,
        stakingStatuses: action.payload.statuses
      }
    case userConstants.USER_TOKEN_EXCHANGE:
      return {
        ...state,
        tokenExchange: action.payload.tokenExchange
      }
    default:
      return state;
  }
}

export default userReducer;
