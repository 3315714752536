import React, {useCallback, useEffect, useMemo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import EnableFAUI from "../ui/EnableFAUI";


import {disable2FA, enable2FA, get2FA} from "../../../../../../../../../redux/user/userActions";
import {getFASelector} from "../../../../../../../../../redux/user/userSelectors";
import {useMediaQuery} from "../../../../../../../../common/hooks/useMediaQuery";

import styles from "./EnableFA.module.css";

const EnableFAContainer = ({handleEnable, activated}) => {
  const [authCode, setAuthCode] = useState('');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const is400 = useMediaQuery('(max-width: 400px)');

  const size = useMemo(() => is400 ? 80 : 128, [is400]);

  const fa = useSelector(getFASelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activated) {
      dispatch(get2FA());
    }
  }, [activated, dispatch]);

  const handleCodeChange = useCallback((e) => {
    setAuthCode(e.target.value);
    setDisabled(false);
    setError(null);
  }, []);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (disabled) {
      return null;
    }
    setDisabled(true);
    dispatch(activated ? disable2FA(authCode, setError) : enable2FA(fa['2fa_code'], authCode, setError))
      .then((message) => {
        if (message === 'success') {
          handleEnable();
        }
        setDisabled(false)
      });
  }, [fa, dispatch, disabled, authCode, activated, handleEnable]);

  if (!activated && !fa) {
    return null;
  }

  return <EnableFAUI classes={styles}
                     fa={fa}
                     size={size}
                     authCode={authCode}
                     error={error}
                     activated={activated}
                     handleSubmit={handleSubmit}
                     handleCodeChange={handleCodeChange}/>;
}

export default EnableFAContainer;
