import React, {memo} from "react";

const TransactionCheckUI = ({classes, link, txHash}) => {

  return <div className={classes.TransactionCheck}>
    <a target="_blank" href={`${link}${txHash}`}
       rel="noopener noreferrer"
       className={classes['TransactionCheck-ID']}>
      {link}{txHash}
    </a>
  </div>
}

export default memo(TransactionCheckUI);
