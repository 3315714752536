import React, {useEffect, useMemo, useState} from "react";

import {useDispatch} from "react-redux";

import PartnersUI from "../ui/PartnersUI";

import {getReferralsInfo} from "../../../../../redux/referral/referralActions";

import styles from "./Partners.module.css";

const PartnersContainer = () => {
  const [currentLevel, setCurrentLevel] = useState(1);
  const [currentListType, setCurrentListType] = useState('Cписок партнеров');

  const dispatch = useDispatch();

  const levels = useMemo(() => new Array(10).fill(0).map((v, i) => ({id: i, value: i + 1})), []);
  const listTypes = useMemo(() => [
    { id: 0, value: 'Cписок партнеров' },
    { id: 1, value: 'Cписок операций' },
  ], []);

  useEffect(() => {
    dispatch(getReferralsInfo());
  }, [dispatch]);

  return <PartnersUI classes={styles}
                     listTypes={listTypes}
                     currentListType={currentListType}
                     setCurrentListType={setCurrentListType}
                     currentLevel={currentLevel}
                     levels={levels}
                     setCurrentLevel={setCurrentLevel}/>;
};

export default PartnersContainer;
