import React from "react";

const RightArrowIconUI = ({className = '', fillColor = '#F8DD82', ...rest}) => {
  return <div className={className} {...rest}>
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3704 9.4012C16.3978 10.2019 16.3978 11.7554 15.3704 12.5561L3.93365 21.4702C2.62023 22.4939 0.704146 21.558 0.704146 19.8927L0.704147 2.06456C0.704147 0.399315 2.62023 -0.536595 3.93365 0.487112L15.3704 9.4012Z"
            fill={fillColor}/>
    </svg>
  </div>;
}

export default RightArrowIconUI;
