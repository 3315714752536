import React, {memo} from "react";

const ActionTypeSelectUI = ({classes, handleAddBalance, actionType, handleWithdrawBalance}) => {
  const active = classes['ActionTypes-Item_active'];
  return <div className={classes.ActionTypes}>
    <span
      onClick={handleAddBalance}
      className={`${classes['ActionTypes-Item']} 
      ${actionType === 'ADD_BALANCE' ? active  : ''}`}>Пополнить</span>
    <span
      onClick={handleWithdrawBalance}
      className={`${classes['ActionTypes-Item']}
      ${actionType === 'WITHDRAW_BALANCE' ? active : ''}`}>Вывод</span>
    {/*<span*/}
    {/*  onClick={handleTransferBalance}*/}
    {/*  className={`${classes['ActionTypes-Item']}*/}
    {/*  ${actionType === 'TRANSFER_BALANCE' ? active : ''}`}>перевод</span>*/}
  </div>;
}

export default memo(ActionTypeSelectUI);
