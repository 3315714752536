import React, { memo } from "react";


const AutoDocumentsUI = ({classes}) => {
  return <iframe src="https://www.wordlex.one/documents-auto"
      className={classes.Iframe}
      width="100%"
      frameBorder="0"
      scrolling="no"
      title="Документы из автосалона"
      height="1350" 
      loading="lazy" />
}

export default memo(AutoDocumentsUI);
