import React, {memo} from "react";

import RenderInputContainer from "../../RenderInput/functional/RenderInputContainer";
import RenderSelectContainer from "../../RenderSelect/functional/RenderSelectContainer";

const InputWithDropDownUI = ({
                               classes, customStyle, name, options, inputClassName,
                               selectOnChange = null, selectWidth,
                               styles, focused, handleFocus, handleBlur, ...rest
                             }) => {
  return <div className={classes.Wrapper} onFocus={handleFocus} onBlur={handleBlur}>
    <RenderInputContainer name={name}
                          className={`${inputClassName} ${focused ? classes['Input-Focused'] : ''}`}
                          onFocus={handleFocus}
                          {...rest}/>
    <RenderSelectContainer options={options} style={styles} onFocus={handleFocus} onChange={selectOnChange}/>
  </div>
}

export default memo(InputWithDropDownUI);
