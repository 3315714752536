import React, {useEffect, useMemo, useState} from "react";

import {useDispatch} from "react-redux";

import CarPaymentUI from "../ui/CarPaymentUI";
import CarPriceFormContainer from "./CarPriceForm/functional/CarPriceFormContainer";
import PaymentOfInsuranceContainer from "./PaymentOfInsurance/functional/PaymentOfInsuranceContainer";
import AllDoneContainer from "./AllDone/functional/AllDoneContainer";
import AdvancePaymentContainer from "./AdvancePayment/functional/AdvancePaymentContainer";

import {getCarOrder} from "../../../../../redux/user/userActions";

import styles from "./CarPayment.module.css";

const CarPaymentContainer = () => {

  const [currentStep, setCurrentStep] = useState(0);

  const dispatch = useDispatch();

  const renderComponent = useMemo(() => {
    switch (currentStep) {
      case 1:
        return AdvancePaymentContainer;
      case 2:
      case 3:
        return CarPriceFormContainer;
      case 4:
        return PaymentOfInsuranceContainer;
      case 5:
        return AllDoneContainer;
      default:
        return () => null;
    }
  }, [currentStep]);

  useEffect(() => {
    dispatch(getCarOrder(setCurrentStep));
  }, [dispatch, setCurrentStep]);

  return <CarPaymentUI
    currentStep={currentStep}
    setCurrentStep={setCurrentStep}
    RenderComponent={renderComponent}
    classes={styles}
  />;
}

export default CarPaymentContainer;
