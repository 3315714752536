import React, {memo} from "react";

import ModelRowContainer from "../../Model/ModelRow/functional/ModelRowContainer";

const TwoFAUI = ({handleTwoFAEnable, activated}) => {
  return <ModelRowContainer
  actionLeftTitle="Google аутентификация"
  actionLeftValue="Используется для улучшения безопасности"
  buttonName={activated ? 'Отключить' : 'Включить'}
  submitFn={handleTwoFAEnable}
  />
}

export default memo(TwoFAUI);
