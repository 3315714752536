const userConstants = {
  SET_USER: 'SET_USER',
  SET_WI_PRICE: 'SET_WI_PRICE',
  SET_ETH_PRICE: 'SET_ETH_PRICE',
  SET_WI_PRICE_USDT: 'SET_WI_PRICE_USDT',
  SET_ICO_PRICE_ETH: 'SET_ICO_PRICE_ETH',
  USER_SET_CAR_ORDER: 'USER_SET_CAR_ORDER',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_SET_AUTH: 'USER_SET_AUTH',
  USER_ADD_OPERATION_LIST: 'USER_ADD_OPERATION_LIST',
  USER_VIEW_OPERATION_LIST: 'USER_VIEW_OPERATION_LIST',
  USER_SET_OPERATION_LIST_PAGE_NUMBER: 'USER_SET_OPERATION_LIST_PAGE_NUMBER',
  USER_SET_IN_LAST_PAGE: 'USER_SET_IN_LAST_PAGE',
  USER_SET_2FA: 'USER_SET_2FA',
  USER_SET_2FA_STATE: 'USER_SET_2FA_STATE',
  USER_SET_STAKING: 'USER_SET_STAKING',
  USER_SET_STAKING_STATUSES: 'USER_SET_STAKING_STATUSES',
  USER_TOKEN_EXCHANGE: 'USER_TOKEN_EXCHANGE',
}

export default userConstants;
