import React, {memo} from "react";

import RenderLineUI from "../ui/RenderLineUI";

import styles from "./RenderLine.module.css";

const RenderLineContainer = (props) => {
  return <RenderLineUI classes={styles} {...props}/>;
}

export default memo(RenderLineContainer);
