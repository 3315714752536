import React, { memo } from "react";

import ButtonContainer from "../../common/Button/functional/ButtonContainer";
import RenderInputContainer from "../../common/RenderInput/functional/RenderInputContainer";
import RenderLineContainer from "../../common/RenderLine/functional/RenderLineContainer";

const InputModalUI = ({ classes, disabled, handleAmountChange, handleSubmit, title, amount, }) => {
  return <div className={classes['Status-Modal--Container']}>
    <div className={classes['Status-Modal']}>
      <span className={classes['Status-Title']}>{title}</span>

      <RenderLineContainer/>

      <form className={classes['Take-Money']}
          // onSubmit={handleSubmit}
      >
        <RenderInputContainer className={classes['Take-Money-Input']}
                              value={amount}
                              onChange={handleAmountChange}/>
        <ButtonContainer disabled={disabled} onClick={handleSubmit} text="Снять прибыль" className={classes['Take-Money-Submit']}/>
      </form>
    </div>
  </div>
}

export default memo(InputModalUI);