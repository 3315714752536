import React, {memo} from "react";

// import ButtonContainer from "../../../../../../common/Button/functional/ButtonContainer";

const AllDoneUI = ({classes, handleSubmit}) => {
  return <div className={classes['All-Done']}>
    <span className={classes['All-Done-Title']}>Ваш автомобиль оплачен!</span>
    <span className={classes['All-Done-Description']}>Операция успешно выполнена</span>
    {/*<ButtonContainer text="Готово" className={classes['All-Done-Button_submit']} onClick={handleSubmit}/>*/}
  </div>
}

export default memo(AllDoneUI);
