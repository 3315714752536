import React, {memo} from "react";

import RenderLineContainer from "../../../../../../common/RenderLine/functional/RenderLineContainer";

const PartnerInfoUI = ({classes, tableHeads, partnerInfo}) => {

  return <div className={classes['PartnersInfo']}>
    <span className={classes['PartnersInfo-Title']}>Партнер</span>
    <RenderLineContainer className={classes['PartnersInfo-Line']}/>
    {tableHeads.length && <div className={classes['Table']}>
        <div className={classes['THead']}>
          <div className={classes['Table-Row']}>
            {tableHeads.map(head => <span key={head} className={classes['Table-Head']}>{head}</span>)}
          </div>
        </div>
        <div className={classes['TBody']}>
          <div className={classes['Table-Row']}>
            <span className={classes['Table-Data']}>{partnerInfo.id}</span>
            <span className={classes['Table-Data']}>{partnerInfo.email}</span>
            <span className={classes['Table-Data']}>{partnerInfo.telegram}</span>
          </div>
        </div>
    </div>}
  </div>
}

export default memo(PartnerInfoUI);
