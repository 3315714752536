import React, {memo} from "react";

import RenderTextsSpaceBetweenUI from "../ui/RenderTextsSpaceBetweenUI";

import styles from "./RenderTextsSpaceBetween.module.css";

const RenderTextsSpaceBetweenContainer = (props) => {
  return <RenderTextsSpaceBetweenUI classes={styles} {...props}/>;
}

export default memo(RenderTextsSpaceBetweenContainer);
