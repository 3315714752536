import React, {memo, useCallback} from "react";

import { useHistory } from "react-router-dom";

import ChangePasswordUI from "../ui/ChangePasswordUI";

const ChangePasswordContainer = () => {

  const history = useHistory();

  const handleSubmit = useCallback(() => {
    history.push('/settings/change-password');
  }, [history])

  return <ChangePasswordUI handleSubmit={handleSubmit}/>;
}

export default memo(ChangePasswordContainer);
