import React from "react";

//{ useCallback, useMemo, useState }

//import { useHistory } from "react-router-dom";

import AccountUI from "../ui/AccountUI";
//import RenderSelectContainer from "../../../../common/RenderSelect/functional/RenderSelectContainer";

import styles from "./Account.module.css";

const AccountContainer = ({ username, tokenExchange }) => {
  // const history = useHistory();
  // const [value, setValue] = useState({ value: '', label: '' });

  // const style = useMemo(() => ({
  //   container: {
  //     width: 40,
  //     position: 'static',
  //   },
  //   control: {
  //     backgroundColor: 'transparent',
  //     border: 'none',
  //   },
  //   valueContainer: {
  //     width: 0,
  //     height: 0,
  //     padding: 0,
  //   },
  //   dropdownIndicator: {
  //     paddingBottom: 0,
  //   },
  //   menu: {
  //     left: 0,
  //     top: 150,
  //   },
  //   menuList: {
  //     padding: 0,
  //   },
  //   option: {
  //     '&:nth-of-type(2)': {
  //       borderTopLeftRadius: 15,
  //       borderTopRightRadius: 15,
  //     }
  //   }
  // }), []);

  // const options = useMemo(() => [
  //   { value: '', label: '' },
  //   { value: 'выход из системы', label: 'выход из системы' },
  //   { value: 'вернуться на сайт', label: 'вернуться на сайт' },
  //   { value: 'перейти на биржу', label: 'перейти на биржу' }], []);

  // const handleChange = useCallback((e) => {
  //   setValue({ value: '', label: '' });
  //   if (e.value === 'выход из системы') {
  //     localStorage.removeItem('token');
  //     history.push('/login');
  //   } else if (e.value === 'вернуться на сайт') {
  //     window.location.href = 'https://invest.webinfra.ru';
  //   } else if (e.value === 'перейти на биржу') {
  //     window.open(`http://exchange.webinfra.ru/login_wordlex/?token=${tokenExchange}`);
  //   }
  // }, [history, tokenExchange]);

  // const select = useMemo(() => <RenderSelectContainer
  //   onChange={handleChange}
  //   value={value}
  //   options={options} style={style} />, [value, handleChange, options, style]);

  return <AccountUI
    classes={styles}
    fullName={username}
  // select={select}
  />;
}

export default AccountContainer;
