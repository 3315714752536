import React, {memo} from "react";

import ActionTypeSelectContainer from "../functional/ActionTypeSelect/functional/ActionTypeSelectContainer";
import OperationListContainer from "../../OperationList/functional/OperationListContainer";

const WalletUI = ({classes, actionType, RenderComponent, setActionType}) => {
  return <>
    <ActionTypeSelectContainer setActionType={setActionType} actionType={actionType}/>
    <div className={classes.ContentWrapper}>
      {RenderComponent && <RenderComponent />}
      <OperationListContainer />
    </div>
  </>
}

export default memo(WalletUI);
