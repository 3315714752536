import React from "react";

import { useLocation } from "react-router-dom";

import StaticCryptoUI from "../ui/StaticCryptoUI";

import styles from "./StaticCrypto.module.css";

const StaticCryptoContainer = () => {

    const {pathname} = useLocation();

    if (pathname === '/' || pathname === '/car-documents' || pathname === '/main'
      || pathname === '/partners' || pathname.startsWith('/settings') || pathname === '/staking') {
        return null;
    }

    return <StaticCryptoUI classes={styles}/>;
}

export default StaticCryptoContainer;
