import React, {useCallback, useMemo, useState} from "react";

import InputWithDropDownUI from "../ui/InputWithDropDownUI";

import styles from "./InputWithDropDown.module.css";

const InputWithDropDownContainer = (props) => {
  const {selectWidth, customStyle, selectOnChange} = props;

  const [focused, setFocused] = useState(false);

  const selectStyles = useMemo(() => {
    const {container = {}, menu = {}, menuList = {}} = customStyle;
    return {
      container: {
        width: selectWidth,
        ...container,
      },
      valueContainer: {
        width: 'fit-content !important',
        padding: 0,
        justifyContent: 'flex-end',
      },
      singleValue: {
        fontSize: 'calc(8px + 0.313vw)',
        letterSpacing: '-0.01em',
        color: '#4E5566',
        fontFamily: `'Roboto', sans-serif`,
        fontWeight: 400,
        lineHeight: 'calc(10px + 0.469vw)',
      },
      control: {
        borderLeft: focused ? '0 !important' : 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        width: 85,
        backgroundColor: '#F2F2F2',
        borderColor: focused ? '#F8DD82' : '#000',
        border: `1px solid ${focused ? '#F8DD82 !important' : '#D9D9D9'}`,
        '&:hover': {
          border: `1px solid ${focused ? '#F8DD82 !important' : '#D9D9D9'}`,
          borderLeft: 'none !important',
        }
      },
      indicatorsContainer: {
        width: 25,
        display: 'flex',
        justifyContent: 'center',
        marginRight: 15,
      },
      menu: {
        width: '96px',
        left: '-0.458vw',
        ...menu,
      },
      menuList: {
        ...menuList,
      },
      dropdownIndicator: {
        marginRight: 0,
        padding: 0,
      },
      option: {
        justifyContent: 'center',
      }
    };
  }, [selectWidth, focused, customStyle]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return <InputWithDropDownUI
    focused={focused}
    handleFocus={handleFocus}
    classes={styles}
    styles={selectStyles}
    selectOnChange={selectOnChange}
    handleBlur={handleBlur}
    {...props}/>;
}

export default InputWithDropDownContainer;
