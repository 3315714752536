import React, {memo} from "react";

import RenderInputContainer from "../../common/RenderInput/functional/RenderInputContainer";
import ButtonContainer from "../../common/Button/functional/ButtonContainer";
import RenderErrorMessageContainer from "../../common/RenderErrorMessage/functional/RenderErrorMessageContainer";

const PasswordRecoveryUI = ({classes, email, handleEmailChange, handleSubmit, disabled, error}) => {
  return <div className={classes['Password-Recovery']}>
    <form className={classes['Form']} onSubmit={handleSubmit}>
      <span className={classes['Form-Title']}>Восстановления пароля</span>
      <label className={classes['Form-Label']}>
        <span className={classes['Label-Text']}>Введите email</span>
        <RenderInputContainer
          name="email"
          value={email}
          onChange={handleEmailChange}
          hasError={error}
          className={classes['Form-Input']}
        />
      </label>
      {error && <RenderErrorMessageContainer message={error}/>}
      <ButtonContainer text="Восстановить"
                       type="submit"
                       disabled={disabled}
                       className={classes['Form-Submit']}/>
    </form>
  </div>;
}

export default memo(PasswordRecoveryUI);
