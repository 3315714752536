import {registerFormConstants} from "./registerFormConstants";

const initialState = {
  errors: {},
  fields: {
    password: {
      value: '',
      focused: false,
      seePassword: false,
    },
    email: {
      value: '',
      focused: false,
    },
    ref_user_id: {
      value: '',
      focused: false,
    },
    security: {
      value: false,
    },
    repeat_password: {
      value: '',
      focused: false,
      seePassword: false,
    },
  },
  currentStep: 0,
  registerMethod: null,
  button: {
    disabled: false,
  },
  referral_id: null,
};

const registerFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case registerFormConstants.REGISTER_FORM_SET_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload.error,
        }
      };
    }
    case registerFormConstants.REGISTER_FORM_RESET_ERROR: {
      const filteredErrors = {...state.errors};
      delete filteredErrors[action.payload.field];
      return {
        ...state,
        errors: {
          ...filteredErrors
        },
      };
    }
    case registerFormConstants.REGISTER_FORM_SET_FOCUS: {
      const {name} = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...state.fields[name],
            focused: true,
          }
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_SET_BLUR: {
      const {name} = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...state.fields[name],
            focused: false,
          }
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_SET_PASSWORD: {
      const { value } = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          password: {
            ...state.fields.password,
            value ,
          }
        }
      };
    }
    case registerFormConstants.REGISTER_FORM_VIEW_PASSWORD: {
      const canSee = !state.fields.password.seePassword;
      return {
        ...state,
        fields: {
          ...state.fields,
          password: {
            ...state.fields.password,
            seePassword: canSee,
          }
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_SET_EMAIL: {
      const { value } = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          email: {
            ...state.fields.email,
            value,
          }
        }
      };
    }
    case registerFormConstants.REGISTER_FORM_SET_REPEAT_PASSWORD: {
      const { value } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          repeat_password: {
            ...state.fields.repeat_password,
            value ,
          }
        }
      };
    }
    case registerFormConstants.REGISTER_FORM_SET_REFERRAL_ID: {
      const { value } = action.payload;

      return {
        ...state,
        fields: {
          ...state.fields,
          ref_user_id: {
            ...state.fields.ref_user_id,
            value,
          }
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_VIEW_REPEAT_PASSWORD: {
      const canSee = !state.fields.repeat_password.seePassword;
      return {
        ...state,
        fields: {
          ...state.fields,
          repeat_password: {
            ...state.fields.repeat_password,
            seePassword: canSee,
          }
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_SET_SECURITY: {
      const { value } = action.payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          security: {
            value,
          }
        }
      };
    }
    case registerFormConstants.REGISTER_FORM_OPEN_DEFAULT_REGISTER: {
      return {
        ...state,
        registerMethod: 'DEFAULT',
      };
    }
    case registerFormConstants.REGISTER_FORM_SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      }
    }
    case registerFormConstants.REGISTER_FORM_DISABLE_SUBMIT: {
      return {
        ...state,
        button: {
          ...state.button,
          disabled: true,
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_ENABLE_SUBMIT: {
      return {
        ...state,
        button: {
          ...state.button,
          disabled: false,
        }
      }
    }
    case registerFormConstants.REGISTER_FORM_SET_ADDRESS: {
      return {
        ...state,
        address: action.payload.ethAddress
      }
    }
    case registerFormConstants.REGISTER_FORM_SET_REFERRAL_ID_FROM_QUERY: {
      return {
        ...state,
        referral_id: action.payload.referral_id,
      }
    }
    default:
      return state;
  }
}

export default registerFormReducer;
