import React, { memo } from "react";

import { Switch, Route, } from "react-router-dom";

import ContentContainer from "../../Content/functional/ContentContainer";
import HeaderContainer from "../../Header/functional/HeaderContainer";
import NavigationContainer from '../../Navigation/functional/NavigationContainer';
import FooterContainer from "../../Footer/functional/FooterContainer";
import AuthenticationRouter from "../../Content/functional/AuthenticationRouter/AuthenticationRouter";
import ModalContainer from "../../Modal/functional/ModalContainer";


// const RedirectToLogin = () => {
//   return <Redirect to={'/login'} />
// }

const AppUI = ({ isAuth, token }) => {
  return <>
    <ModalContainer />
    <Switch>
      <Route path={['/register', '/login',
        '/password-recovery', '/user/confirm/:token', '/login-exchange/:token']}
        component={AuthenticationRouter} />
      {
        <Route path={'/'} render={() => <>
          <HeaderContainer />
          <div className="Wrapper">
            <NavigationContainer />
            <ContentContainer />
          </div>
          <FooterContainer />
        </>} />
        // <RedirectToLogin />
      }
    </Switch>
  </>
}

export default memo(AppUI);
