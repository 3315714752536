import React, {useCallback, useMemo} from "react";
 
import {useDispatch, useSelector} from "react-redux";
 
import GeneralInfoAboutPartnerProgramUI from "../ui/GeneralInfoAboutPartnerProgramUI";
 
import {getReferralAndStakingViewSelector} from "../../../../../../../redux/referral/referralSelectors";
import withToFixed from "../../../../../../../helpers/hoc/withToFixed";
import {withdrawProfit} from "../../../../../../../redux/user/userActions";
 
import styles from "./GeneralInfoAboutPartnerProgram.module.css";
 
const GeneralInfoAboutPartnerProgramContainer = ({toFixed}) => {

  const {refCount, refProfit, ref_auto_profit, refStakingAmount} = useSelector(getReferralAndStakingViewSelector);

  const dispatch = useDispatch();
 
  const fixedProfit = useMemo(() => toFixed(refProfit), [toFixed, refProfit]);
  const fixedAutoProfit = useMemo(() => toFixed(ref_auto_profit), [toFixed, ref_auto_profit]);
  const fixedRefStakingAmount = useMemo(() => toFixed(refStakingAmount), [toFixed, refStakingAmount]);
 

  const handleSentProfit = useCallback((e, amount, handleClose) => {
    console.log(e, amount, handleClose)
    e.preventDefault();
    dispatch(withdrawProfit(amount, handleClose));
  }, [dispatch]);

  const handleSentAutoProfit = useCallback((e, amount, handleClose) => {
    console.log(e, amount, handleClose)
    e.preventDefault();
    dispatch(withdrawProfit(amount, handleClose, true));
  }, [dispatch]);



  return <GeneralInfoAboutPartnerProgramUI classes={styles}
                                           refStakingAmount={fixedRefStakingAmount}
                                           refProfit={fixedProfit}
                                           refAutoProfit={fixedAutoProfit}
                                           refCount={refCount}
                                           handleSentProfit={handleSentProfit}
                                           handleSentAutoProfit={handleSentAutoProfit}

 
  />;
}
 
export default withToFixed(GeneralInfoAboutPartnerProgramContainer)(4);