import React, {memo} from 'react';

const NavigationUI = ({ classes, navigationElement }) => {
    return <nav className={classes.Navbar}>
        <ul className={classes.NavigationList}>
            {navigationElement}
        </ul>
    </nav>
}


export default memo(NavigationUI);
