import React, {memo} from "react";

const SelectViewUI = ({classes,
                        data,
                        active,
                        height = 30,
                        className = '',
                        width = 40,
                        handleActiveChange,
                        selectTitle = null}) => {
  return <div className={`${classes['Select']} ${className}`}>
    {selectTitle && <span className={classes['Select-Title']}>{selectTitle}</span>}
    {data.map(d => <span key={d.id}
                         style={{width, height}}
                         onClick={() => handleActiveChange(d.value)}
                         className={`${classes['Select-Value']} 
                                   ${d.value === active ? classes['Select-Value_active'] : ''}`}>
                                    {d.value}</span>)}
  </div>;
}

export default memo(SelectViewUI);
