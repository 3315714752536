import React, {useCallback, useState} from "react";

import TwoFAVerificationUI from "../ui/TwoFAVerificactionUI";

import styles from "./TwoFAVerification.module.css";
import {useDispatch} from "react-redux";
import {changePassword} from "../../../../../../../../../../../redux/user/userActions";

const TwoFAVerificationContainer = ({fields, setFinished}) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState({});

  const dispatch = useDispatch();

  const handleCodeChange = useCallback((e) => {
    setError({});
    setCode(e.target.value);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    dispatch(changePassword({
      ...fields,
      '2fa_code': code,
    }, setError, setFinished));
  }, [code, fields, dispatch, setFinished]);

  return <TwoFAVerificationUI classes={styles}
                              code={code}
                              error={error}
                              handleSubmit={handleSubmit}
                              handleCodeChange={handleCodeChange}/>;
}

export default TwoFAVerificationContainer;
