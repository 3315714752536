import React from "react";

import HeaderUI from "../ui/HeaderUI";

import styles from "./Header.module.css";

const HeaderContainer = () => {

  return <HeaderUI classes={styles}/>;
}

export default HeaderContainer;
