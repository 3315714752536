import React from "react";

import RenderPasswordInputUI from "../ui/RenderPasswordInputUI";

import styles from "./RenderPasswordInput.module.css";

const RenderPasswordInputContainer = (props) => {
  return <RenderPasswordInputUI {...props} classes={styles}/>;
}

export default RenderPasswordInputContainer;
