import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom"

import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import SuccessWindowContainer from "../../common/SuccessWindow/functional/SuccessWindowContainer";

import {confirmEmail} from "../../../redux/user/userActions";

import styles from "./ConfirmEmail.module.css";
import FailureWindowContainer from "../../common/FailureWindow/functional/FailureWindowContainer";


const ConfirmEmailContainer = () => {

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const {token} = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(confirmEmail(token))
      .then(message => {
        if (message === 'success') {
          setOpen(true);
        } else if (message === 'error') {
          setOpen(true);
          setError(true);
        }
      });
  }, [token, dispatch]);

  if (!open) {
    return null;
  }

  if (error) {
    return <FailureWindowContainer
      title="Ошибка подтверждения"
      description="Ссылка на подтверждения Email адреса не верна."
      redirectMessage="Войти в личный кабинет"
      redirectHref="/login"
    />
  }

  return <SuccessWindowContainer
    title="Подтверждение E-mail"
    CustomComponent={() => <div className={styles['Wrapper']}>
      <span className={styles['Success-Description']}>Ваш E-mail успешно подтвержден!</span>
      <Link to="/main" className={styles['Success-Link']}>Вернуться в личный кабинет</Link>
    </div>}
  />
}

export default ConfirmEmailContainer;
