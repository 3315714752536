import React, {memo} from "react";

import RenderLineContainer from "../../../../../../common/RenderLine/functional/RenderLineContainer";
import ChangePasswordContainer from "../../ChangePassword/functional/ChangePasswordContainer";
import TwoFAContainer from "../../TwoFA/functional/TwoFAContainer";

const ModelUI = ({classes, title, handleEnable, activated}) => {
  return <div className={classes['Model']}>
    <span className={classes['Model-Title']}>{title}</span>
    <RenderLineContainer className={classes['Model-Line']}/>
    <ChangePasswordContainer />
    <RenderLineContainer className={classes['Model-Line-Second']}/>
    <TwoFAContainer handleEnable={handleEnable} activated={activated}/>
  </div>;
}

export default memo(ModelUI);
