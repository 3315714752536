import React from "react";

const BigErrorIconUI = ({className = '', ...rest}) => {
  return <div className={className} {...rest}>
    <svg width="calc(60px + 3.333vw)" height="calc(60px + 3.333vw)"
         viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="62" cy="62" r="62" fill="#F85A45"/>
      <rect x="34.96" y="34.458" width="81" height="9" rx="4.5" transform="rotate(41.4695 34.96 34.458)" fill="white"/>
      <rect width="81" height="9" rx="4.5" transform="matrix(-0.738011 0.674789 0.674789 0.738011 89.1799 34)"
            fill="white"/>
    </svg>
  </div>
}

export default BigErrorIconUI;
