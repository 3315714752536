import React, {memo} from "react";

import RenderPasswordInputContainer from "../../RenderPasswordInput/functional/RenderPasswordInputContainer";
import EyeIconContainer from "../../icons/EyeIcon/functional/EyeIconContainer";
import EyeActiveContainer from "../../icons/EyeActive/functional/EyeActiveContainer";

const PasswordInputWithEyeIconUI = ({classes, eyeClassName = '', handleEyeClick, active, ...rest}) => {
  const eyeProps = {className: `${classes['Eye-Icon']} ${eyeClassName}`, onClick: handleEyeClick};
  return <div className={classes['Wrapper']}>
    <RenderPasswordInputContainer canSee={active} {...rest}/>
    {
      active ? <EyeActiveContainer {...eyeProps}/> : <EyeIconContainer {...eyeProps}/>
    }
  </div>
}

export default memo(PasswordInputWithEyeIconUI);
