import React from "react";

import ModelUI from "../ui/ModelUI";

import styles from "./Model.module.css";

const ModelContainer = (props) => {
  return <ModelUI classes={styles} {...props}/>;
}

export default ModelContainer;
